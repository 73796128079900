import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Image,
  Button,
  Pagination,
} from "react-bootstrap";
import "./admin-dashboard.css";
import Table from "react-bootstrap/Table";
import { natSurveyUser } from "../../services/adminService";
import { userdelete } from "../../services/userServices";
import avatar from "../../assets/images/user.svg";
import DeleteModal from "./DeleteModal";
import { useNavigate } from "react-router-dom";

function NatListTable(props) {
  const navigate = useNavigate();
  const [natUser, setNatUser] = useState([]);
  const tableHeadings = [
    "User Name",
    "Joined date",
    "Total # of Surveys",
    "Action",
  ];
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteUserId, setDeleteUserId] = useState(null);

  const openModal = (usreid) => {
    setShowModal(true);
    setDeleteUserId(usreid);
    // console.log("userid",usreid);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = natUser.filter((item) => {
    const { first_name } = item;
    const lowerCaseQuery = searchQuery.toLowerCase();
    return first_name.toLowerCase().includes(lowerCaseQuery);
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    natdata();
  }, []);

  const natdata = () => {
    natSurveyUser()
      .then((response) => {
        const allData = response.data;
        setNatUser(allData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteuser = () => {
    const id = deleteUserId;
    userdelete(id)
      .then((res) => {
        console.log("deleted", res);
        natdata();
        closeModal();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const navigateToEdit = (userId, userData) => {
    navigate("/natedit", {
      state: { userId, userData }
    });
  };

  const viewuser = (userId, userData) => {
    navigate("/viewnatuser", {
      state: { userId, userData },
    });
  };

  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <section className="admin-dashboard mt-5">
      <Container>
        <Row>
          <Col xs="12">
            <div className="mt-50">
              <div className="table-header">
                <p className="ms-ft-18 ms-fw-700 ms-ft-fadedblack mb-1">
                  NAT User
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="light-text ms-ft-12 mb-0">All NAT User</p>
                  <div className="d-flex align-items-center gap-3">
                    <InputGroup className="search-bar">
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={
                            require("../../assets/images/search-normal.svg")
                              .default
                          }
                          alt="search"
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Search User"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </InputGroup>
                    {/* <div>
                        <Button className="filter-btn">
                          <div className="d-flex align-items-center gap-1">
                            <Image src={require('../../assets/images/filter-search.svg').default} alt="search" />
                            Filter
                          </div>
                        </Button>
                      </div> */}
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                <Table responsive borderless className="userlist-table">
                  <thead>
                    <tr>
                      {tableHeadings.map((title, index) => (
                        <th key={index}>{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.length === 0 ? (
                        <tr style={{ backgroundColor: "#fff" }}>
                            <td colSpan={tableHeadings.length} className='text-center'>
                                <Image src={require('../../assets/images/No data-cuate.svg').default} alt="no reports" />
                                <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700'>No NAT Users found</p>
                                <p className='ms-ft-22 ms-ft-mediumgrey'>NAT Users were not found in the database.</p>
                            </td>
                        </tr>
                      ) : (currentData.map((item, index) => {
                        const timeZone = "America/Los_Angeles"; // PST
                      const createdAt = new Date(item.createdAt);
                      const time = createdAt.toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                        timeZone,
                      });
                      const day = createdAt.toLocaleString("en-US", {
                        weekday: "long",
                        timeZone,
                      });
                      const date = createdAt.toLocaleString("en-US", {
                        day: "numeric",
                        timeZone
                      });
                      const month = createdAt.toLocaleString("en-US", {
                        month: "long",
                      });
                      const year = createdAt.getFullYear();
                      return (
                        <tr key={index}>
                          <td>
                            <div className="user-avatar">
                              {/* <div className={`avatar-image ${item.userType === 'NAT USER' ? 'bordered' : ''}`}>
                                            <Image src={require('../../assets/images/avatar1.svg').default} alt={`Avatar ${item.id}`} />
                                        </div> */}
                              <div
                                className="avatar me-1"
                                style={{ backgroundImage: `url(${avatar})` }}
                              ></div>
                              <span>{`${item.first_name} ${item.last_name}`}</span>
                            </div>
                          </td>
                          <td>{`${time} ${day}, ${date} ${month} ${year}`}</td>
                          <td>
                            <div className="count">{item.num_nat_survey}</div>
                          </td>
                          <td className="d-flex gap-2 align-items-center">
                            <Button variant="link">
                              <Image
                                src={
                                  require("../../assets/images/delete-icon.svg")
                                    .default
                                }
                                alt="Delete"
                                onClick={() => openModal(item.user_id)}
                              />
                            </Button>
                            {showModal && (
                              <DeleteModal
                                showModal={showModal}
                                closeModal={closeModal}
                                deleteuser={deleteuser}
                              />
                            )}
                            <Button variant="link">
                              <Image
                                src={
                                  require("../../assets/images/edit-grey.svg")
                                    .default
                                }
                                alt="Edit"
                                onClick={() =>
                                  navigateToEdit(item.user_id, item)
                                }
                                // onClick={()=>{navigate("/natedit")}}
                              />
                            </Button>
                            <Button variant="link">
                              <Image
                              className='pwd-icon'
                              src={require('../../assets/images/view.svg').default} alt="eye"
                                onClick={() =>
                                  viewuser(item.user_id, item)
                                }
                              />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="pagination-wrapper">
                <Pagination className="mb-0">
                  <Pagination.Prev
                    className="page-prev"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {"Prev Page"}
                  </Pagination.Prev>

                  {Array.from({
                    length: Math.ceil(filteredData.length / itemsPerPage),
                  }).map((item, index) => (
                    <Pagination.Item
                      key={index}
                      active={currentPage === index + 1}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="page-next"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(filteredData.length / itemsPerPage)
                    }
                  >
                    {"Next Page"}
                  </Pagination.Next>
                </Pagination>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default NatListTable;
