import React, { useState } from 'react';
import { Row, Col, Container, Form, Button, Image, InputGroup } from 'react-bootstrap';
import './forgot.css';
import { pick } from "lodash";
import { userForgotPassword } from "../../services/userServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotComponent = () => {

    const [forgot, setForgot] = useState({});
    const [validationErrors, setValidationErrors] = useState({
        email: false
    });
    const initialStateFields = {
        email: ''
    };

    const onInputChange = (event) => {
        let value = '';
        const name = event.target.name;
        let lclFields = { ...forgot };
        value = event.target.value;
        setForgot({ ...lclFields, [name]: value });
        if ((name === 'email')) {
            if (value.trim() === '') {
              setValidationErrors({ ...validationErrors, [name]: true });
            } else {
              setValidationErrors({ ...validationErrors, [name]: false });
            }
        }
    };

    const handleForgot = (event) => {
        try {
            event.preventDefault();
            let saveForgot = pick({ ...forgot }, Object.keys(initialStateFields));
            if (!saveForgot.email) {
                toast.warn("Please complete all required fields.");
            } else if (
                !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(saveForgot.email)
            ) {
                toast.warn("Enter valid email.");
            } else {
                var data = {
                    email: forgot.email
                }
                userForgotPassword(data)
                    .then((response) => {
                        console.log("forgot response from back-end====>", response);
                        toast.success("Please check your email.");
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Email Not Found.");
                    });
            }
        } catch (err) {
            console.log("error at handleForgot");
        }
    }

    return (
        <>
        <section className='forgot-wrapper'>
            <Container fluid>
                <Row>
                    <Col xs={12} md={7} className='d-none d-md-block p-0'>
                        <div className='forgot-bg-img'>
                            <div className='img-wrapper'>
                                <Image src={require('../../assets/images/MSABC--logo.svg').default} alt="logo" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className='forgot-form-wrapper'>
                        <div className='container'>
                            <div className='d-block d-md-none mb-3'>
                                <Image src={require('../../assets/images/msabc-logo-black.svg').default} alt="logo" />
                            </div>
                            <h2 className='ms-ft-30 ms-fw-600 mb-0'>Forgot Password</h2>
                            <Form className='mt-50'>
                                <Form.Group controlId='formEmail' className='position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Email'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/message.svg').default} alt="email" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='email'
                                            name="email"
                                            placeholder='Enter your recovery email address'
                                            value={forgot.email}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {validationErrors.email && <small className="error-message">{"Email is required."}</small>}
                                </Form.Group>
                                <Form.Group className='d-flex align-items-center justify-content-end mt-3'>
                                    <a href='/login' className='ms-ft-12 text-decoration-none ms-ft-black'>Login here !</a>
                                </Form.Group>
                                <Button
                                    variant='primary'
                                    type='submit'
                                    className='w-100 mt-5 forgot-btn'
                                    onClick={handleForgot}
                                >
                                    Forgot Password
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <ToastContainer
            position="top-center"
            hideProgressBar
        />
        </>
    );
};

export default ForgotComponent;

