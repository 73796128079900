import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const NatUnSaveModal = ({ showModal, closeModal, proceed, save }) => {
  return (
    <div>
      <Modal show={showModal} onHide={closeModal} className='save-job-modal'>
        <Modal.Header className='border-0 pb-1' closeButton></Modal.Header>
        <Modal.Body className='pt-0'>
          <p className='ms-ft-24 ms-ft-medblack ms-fw-700 mb-0'>Options chosen will be lost if you proceed ?
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button variant="light" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="secondary" onClick={proceed}>
            Proceed
          </Button>
          <Button variant="primary" onClick={save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NatUnSaveModal;