import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Form, Button, Image, InputGroup } from 'react-bootstrap';
import './signup.css';
import { pick } from "lodash";
import { userSignup } from "../../services/userServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const validationFields = ['firstName', 'lastName', 'employerName', 'email', 'position', 'jobType', 'password', 'confirmPassword'];

const SignupComponent = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const navigate = useNavigate();
    const leftColumnRef = useRef(null);
    const [validationErrors, setValidationErrors] = useState({
        firstName: false,
        lastName: false,
        employerName: false,
        email: false,
        // phone: false,
        position: false,
        jobType: false,
        password: false,
        confirmPassword: false
    });

    useEffect(() => {
        const handleScroll = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 767) {
                const topOffset = 0;
                const isScrolledPast = window.scrollY > leftColumnRef?.current?.offsetTop - topOffset;
                if (isScrolledPast) {
                    setIsSticky(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const initialStateFields = {
        firstName: '',
        lastName: '',
        employerName: '',
        email: '',
        phone: '',
        position: '',
        jobType: '',
        password: '',
        confirmPassword: ''
    };

    const [signup, setSignup] = useState({});

    const onInputChange = (event) => {
        let value = '';
        const name = event.target.name;
        let lclFields = { ...signup };
        if (event.target.type === "radio") {
            value = event.target.value;
        } else {
            value = event.target.value;
        }
        setSignup({ ...lclFields, [name]: value });
        if (validationFields.includes(name)) {
            if (value.trim() === '') {
              setValidationErrors({ ...validationErrors, [name]: true });
            } else {
              setValidationErrors({ ...validationErrors, [name]: false });
            }
        }
    };

    const handleSignup = (event) => {
        try {
            event.preventDefault();
            const saveSignup = pick({ ...signup }, Object.keys(initialStateFields));
            const isValid = validationFields.every((field) => saveSignup[field]);
            validationFields.forEach((field) => {
                const isEmpty = [undefined, null, ''].includes(saveSignup[field]);
                setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: isEmpty }));
            });
            if (!isValid) {
                toast.warn("Please complete all required fields.");
            } else if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(saveSignup.email)) {
                toast.warn("Enter valid email.");
            } else if (saveSignup.phone !== undefined && !/^\d*$/.test(saveSignup.phone)) {
                toast.warn("Enter valid phone.");
            } else if (saveSignup.password.length < 6) {
                toast.warn("Password must be of atleast 6 characters long.");
            } else if (saveSignup.password !== saveSignup.confirmPassword) {
                toast.warn("Password and Confirm password must be same.");
            } else {
                var data = {
                    first_name: signup.firstName,
                    last_name: signup.lastName,
                    employer_name: signup.employerName,
                    email: signup.email,
                    phone: signup.phone,
                    position: signup.position,
                    job_type: signup.jobType,
                    password: signup.password
                }
                setIsButtonClicked(true)
                userSignup(data)
                    .then((response) => {
                        console.log("signup response from back-end====>", response);
                        setIsButtonClicked(false)
                        setIsSticky(false)
                        toast.success("Register successfull");
                        setTimeout(() => {
                            setIsSticky(false)
                            navigate("/login");
                        }, 1000);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message);
                        setIsButtonClicked(false)
                    });
            }
        } catch (err) {
            console.log("error at handleSignup");
            setIsButtonClicked(false)
        }
    }

    const handleClick = () => {
        navigate("/login");
    }

    return (
        <>
        <section className='signup-wrapper'>
            <Container fluid>
                <Row>
                    <Col xs={12} md={7} ref={leftColumnRef} className={`d-none d-md-block p-0 left-side-img-container ${isSticky ? 'sticky-section' : ''}`}>
                        <div className='signup-bg-img'>
                            <div className='img-wrapper'>
                                <Image src={require('../../assets/images/MSABC--logo.svg').default} alt="logo" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className='signup-form-wrapper'>
                        <div className='container'>
                            <div className='d-block d-md-none mb-3'>
                                <Image src={require('../../assets/images/msabc-logo-black.svg').default} alt="logo" />
                            </div>
                            <h2 className='ms-ft-30 ms-fw-600 mb-0'>Register</h2>
                            <p className='ms-ft-16 mb-2 mt-30'>If you have an account login</p>
                            <p className='ms-ft-16 mb-0'>You can <span className='ms-ft-primary ms-fw-600' onClick={handleClick} style={{ cursor: 'pointer', textDecoration: "none" }}>Login here !</span></p>
                            <Form className='mt-50'>
                                <Form.Group controlId='formFirstname' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' First name'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/user-green.svg').default} alt="firstname" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter your firstname'
                                            name='firstName'
                                            value={signup.firstName}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {validationErrors.firstName && <small className="error-message">{"First name is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formLasname' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Last name'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/user-green.svg').default} alt="lastname" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter your lastname'
                                            name='lastName'
                                            value={signup.lastName}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {validationErrors.lastName && <small className="error-message">{"Last name is required."}</small>}
                                </Form.Group>

                                <Form.Group controlId='formEmployer' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Employer'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/user-green.svg').default} alt="employer" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter your employer'
                                            name='employerName'
                                            value={signup.employerName}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {validationErrors.employerName && <small className="error-message">{"Employer is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formEmail' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Email'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/message.svg').default} alt="email" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='email'
                                            name='email'
                                            placeholder='Enter your email address'
                                            value={signup.email}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {validationErrors.email && <small className="error-message">{"Email is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formPhone' className='mt-45 position-relative'>
                                    <Form.Label>
                                        {/* <span className="text-danger"
                                            title="required">{'*'}
                                        </span> */}
                                        {' Phone'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/phone.svg').default} alt="phone" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='tel'
                                            placeholder='Enter your phone number'
                                            name='phone'
                                            value={signup.phone}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {/* {validationErrors.phone && <small className="error-message">{"Phone is required."}</small>} */}
                                </Form.Group>
                                <Form.Group controlId='formPosition' className='mt-45 custom-select position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Position'}
                                    </Form.Label>
                                    <Form.Control as='select' name='position' value={signup.position} onChange={onInputChange}>
                                        <option value={''}>Select position</option>
                                        <option value={"OHS Professional or Practitioner"}>OHS Professional or Practitioner</option>
                                        <option value={"Business Owner"}>Business Owner</option>
                                        <option value={"Manager or Hiring Manager"}>Manager or Hiring Manager</option>
                                        <option value={"Student"}>Student</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Control>
                                    {validationErrors.position && <small className="error-message">{"Position is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formJobType' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Job type'}
                                    </Form.Label>
                                    <div className='d-flex radio-btn'>
                                        <Form.Check
                                            rounded="true"
                                            type='radio'
                                            id='professional'
                                            name='jobType'
                                            label='Professional'
                                            value="Professional"
                                            className='ms-ft-12'
                                            onChange={onInputChange}
                                            style={{ marginRight: '25px' }}
                                        />
                                        <Form.Check
                                            rounded="true"
                                            type='radio'
                                            id='employer'
                                            name='jobType'
                                            label='Employer'
                                            value="Employer"
                                            onChange={onInputChange}
                                            className='ms-ft-12'
                                        />
                                    </div>
                                    {validationErrors.jobType && <small className="error-message">{"Job type is required."}</small>}
                                </Form.Group>

                                <Form.Group controlId='formPassword' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Password'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/padlock.svg').default} alt="password" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter your password'
                                            name='password'
                                            value={signup.password}
                                            onChange={onInputChange}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant='link'
                                                onClick={togglePasswordVisibility}
                                                className='password-toggle-btn'
                                            >
                                                {!showPassword ? (
                                                    <Image className='pwd-icon' src={require('../../assets/images/invisible.svg').default} alt="eye-slash" />
                                                ) : (
                                                    <Image className='pwd-icon' src={require('../../assets/images/view.svg').default} alt="eye" />
                                                )}
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {validationErrors.password && <small className="error-message">{"Password is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formConfirmPassword' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Confirm password'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/padlock.svg').default} alt="confirm password" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Confirm your password'
                                            name='confirmPassword'
                                            value={signup.confirmPassword}
                                            onChange={onInputChange}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant='link'
                                                onClick={toggleConfirmPasswordVisibility}
                                                className='password-toggle-btn'
                                            >
                                                {!showConfirmPassword ? (
                                                    <Image className='pwd-icon' src={require('../../assets/images/invisible.svg').default} alt="eye-slash" />
                                                ) : (
                                                    <Image className='pwd-icon' src={require('../../assets/images/view.svg').default} alt="eye" />
                                                )}
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {validationErrors.confirmPassword && <small className="error-message">{"Confirm password is required."}</small>}
                                </Form.Group>

                                <Button
                                    variant='primary'
                                    type='submit'
                                    className='w-100 mt-5 signup-btn'
                                    onClick={handleSignup}
                                >
                                    Register
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <ToastContainer
            position="top-center"
            hideProgressBar
        />
        </>
    );
};

export default SignupComponent;
