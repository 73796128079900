const natPageData = [
  {
    title: "Area 1: Hazard identification and Risk Control",
    description: "An OHS professional's expertise in hazard identification and risk control is essential for preventing harm, managing occupational health and safety, and promoting continuous improvement. They understand legal requirements, ensure compliance with control measures, and develop strategies to manage hazards associated with specific tasks or processes. This knowledge is essential for effective safety management.",
    subTitle: "9 units",
    question: '1',
    image: require("../../assets/images/hazardidentification.svg").default,
    qa: [0, 9],
  },
  {
    title: "Area 2: Risk Assessment",
    description: "Risk assessment is a crucial process for OHS professionals to identify and evaluate potential hazards and associated risks in the workplace. It requires a deep understanding of the relationship between hazards and risk, the ability to assess likelihood and severity of hazardous events, and the ability to prioritize risks based on severity. Accurate risk assessments help OHS professionals make informed decisions about risk management strategies.",
    subTitle: "9 units",
    question: '2',
    image: require("../../assets/images/riskassessment.svg").default,
    qa: [9, 18],
  },
  {
    title: "Area 3: Incident Investigation",
    description: "A well-executed incident investigation identifies incident causes and corrective measures. OHS professionals must learn from incidents and ensure that effective incident management procedures are followed. Effective scene management, data analysis, and reporting practices enable rigorous data collection, concise reporting, and confidence in findings.",
    subTitle: "7 units",
    question: '3',
    image: require("../../assets/images/incidentinvestigation.svg").default,
    qa: [18, 25],
  },
  {
    title: "Area 4: Physical and Psychological Health",
    description: "OHS professionals must understand and promote workers' physical and psychological health to create a safe, healthy, and productive organization. They should be knowledgeable about workplace attitudes, power dynamics, cultural and individual differences, and other factors that impact worker wellness. By proactively addressing these aspects, they can create a positive work environment and foster a positive organizational culture.",
    subTitle: "7 units",
    question: '4',
    image: require("../../assets/images/physicalhealth.svg").default,
    qa: [25, 32],
  },
  {
    title: "Area 5: Occupational Health and Safety Management Systems",
    description: "Understanding occupational health and safety management systems (OHSMS) is crucial for OHS professionals. An effective OHSMS integrates health and safety concerns and processes into operations, evaluates performance, and promotes continuous improvement. The OHS professional must implement and monitor policies, objectives, and procedures in a systematic manner while justifying these actions to stakeholders.",
    subTitle: "8 units",
    question: '5',
    image: require("../../assets/images/occupationalhealth.svg").default,
    qa: [32, 40],
  },
  {
    title: "Area 6: Commercial Awareness",
    description: "Commercial awareness involves understanding how an organization operates within its sector and the larger economic climate. It is essential for an OHS professional to navigate an organization's hierarchy, identify decision-makers, and promote engagement with OHS policies. It involves understanding industry news, organizational structures, accounting processes, and operational processes. Staying updated with these aspects helps the professional communicate how OHS solutions can add value and make business sense.",
    subTitle: "8 units",
    question: '6',
    image: require("../../assets/images/commercialawareness.svg").default,
    qa: [40, 48],
  },
  {
    title: "Area 7: Professional Role and Responsibility",
    description: "OHS professionals must balance business goals with worker safety, continuously develop to strengthen their identity and integrity, and strive for continuous improvement in their organization's OHS environment. They must be lifelong learners, sensitive to ethical commitments, and skilled in problem-solving to effectively fulfill their roles and responsibilities.",
    subTitle: "8 units",
    question: '7',
    image: require("../../assets/images/professionalrole.svg").default,
    qa: [48, 56],
  },
  {
    title: "Area 8: Sector-Specific Competencies – Manufacturing",
    description: "An OHS professional in the manufacturing sector must possess specialized knowledge of industry-specific hazard and risk areas, beyond the profession’s core competencies. While no OHS professional is expected to act as a subject matter expert in each of these competency units, they must have adequate knowledge to recognize these hazard and risk areas in their workplace and identify when outside expertise is needed.",
    subTitle: "20 units",
    question: '8',
    image: require("../../assets/images/sectorspecific.svg").default,
    qa: [56, 76],
  },
];

export default natPageData;