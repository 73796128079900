import axios from 'axios';
const API_URL = process.env.REACT_APP_API;
///---- user -------------////

export function userLogin(data) {
    return axios.post(`${API_URL}/api/login`, data);
}

export function userSignup(data) {
    return axios.post(`${API_URL}/api/signup`, data);
}

export function userForgotPassword(data) {
    return axios.post(`${API_URL}/api/forgotpassword`, data);
}

export function userResetPassword(data) {
    return axios.post(`${API_URL}/api/resetpassword`, data);
}

export function adminLogin(data) {
    return axios.post(`${API_URL}/api/adminlogin`, data);
}

export function userdelete(id) {
    return axios.post(`${API_URL}/api/userdelete/${id}`);
}

/// ---- cat survey ------///////////

export function catSurvey(data) {
    return axios.post(`${API_URL}/api/cat`, data);
}

export function getCatSurvey() {
    return axios.get(`${API_URL}/api/getcat/${localStorage.getItem('user_id')}`);
}

export function updateCatSurvey(data, id) {
    return axios.post(`${API_URL}/api/updatecat/${id}`, data);
}

export function deleteCatSurvey(id) {
    return axios.post(`${API_URL}/api/deletecat/${id}`);
}


/// ------- feed Back -----///////
export function userfeedback(data) {
    return axios.post(`${API_URL}/api/feedback`,data);
}


//-------nat survey -----///
export function natSurvey(data) {
    return axios.post(`${API_URL}/api/nat`, data);
}

export function getNatSurvey() {
    return axios.get(`${API_URL}/api/getnat`);
}

export function deleteNatSurvey(id) {
    return axios.post(`${API_URL}/api/deletenat/${id}`);
}

export function updateNatSurvey(data, id) {
    return axios.post(`${API_URL}/api/updatenat/${id}`, data);
}