import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

const RestartSurveyModal = ({ showModal, closeModal, handleRestart, isLoading }) => {
  return (
    <div>
      <Modal show={showModal} onHide={closeModal} className='save-job-modal'>
        <Modal.Header className='border-0 pb-1' closeButton></Modal.Header>
        <Modal.Body className='pt-0'>
          <p className='ms-ft-24 ms-ft-medblack ms-fw-700 mb-0'>Are you sure you want to restart survey ?</p>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button variant="light" onClick={closeModal}>
            No
          </Button>
          <Button variant="primary" onClick={handleRestart} >
            Yes
            {isLoading && (
                <Spinner className="spinner-border-popup spinner-border-sm ms-1" role="status" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestartSurveyModal;