import title from '../dashboard/title.json';

export const flattenArray = (arr) => {
  return arr.reduce((result, level) => {
    return result.concat(level);
  }, []);
};

export const getFormattedTime = (rawDate, timeZone = 'America/Los_Angeles') => {
  const formattedDate = new Date(rawDate);
  const time = formattedDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone,
  });
  const day = formattedDate.toLocaleString('en-US', { weekday: 'long', timeZone });
  const date = formattedDate.getDate();
  const month = formattedDate.toLocaleString('en-US', { month: 'long' });
  const year = formattedDate.getFullYear();
  return `${time} ${day} ${date}, ${month} ${year}`;
}

export const getFormattedDataWithBullets = (job) => {
  const formattedAnswerText = (answerText) => Object.keys(answerText).map((level) => {
    const filteredQuestions = answerText[level].filter((question) => question.value);
    if (filteredQuestions.length > 0) {
      return filteredQuestions.map((question) => question.question).map(text => `\t• ${text.trim()}`).join('\n');
    }
    return '';
  }).join('\n\n');

  const formattedSections = (unit) => job
    .filter((item) => item.enabled)
    .filter(ele => ele.unit.slice(0,1) === unit).map((item) => {
      const formattedText = formattedAnswerText(item.answerText);
      if (formattedText) {
        return `${item.natDesc.trim()}\n\n\t${formattedText.trim()}`;
      }
      return '';
    }).join('\n\n');

  const formattedData = Object.keys(title).map(key => {
    return `${title[key].trim()}\n\n${formattedSections(key.slice(-1))}`
  }).join('\n\n');

  return formattedData;
};

export const quesNumbers = {
  0: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  1: [10, 11, 12, 13, 14, 15, 16, 17, 18],
  2: [19, 20, 21, 22, 23, 24, 25],
  3: [26, 27, 28, 29, 30, 31, 32],
  4: [33, 34, 35, 36, 37, 38, 39, 40],
  5: [41, 42, 43, 44, 45, 46, 47, 48],
  6: [49, 50, 51, 52, 53, 54, 55, 56],
  7: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76],
};

export const slideColours = {
  title1: 'blue',
  title2: 'lightgrey',
  title3: 'darkgrey',
  title4: 'yellow',
  title5: 'blue',
  title6: 'lightgrey',
  title7: 'darkgrey',
  title8: 'yellow',
};
