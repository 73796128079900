// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test {
    margin-top: 30;
}

.help-tab-wrapper li.active {
    background-color: #00863F;
    color: #fff;
    border-radius: 0;
}

.help-tab-wrapper li {
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.sticky-left-content {
    position: sticky;
    top: 100px;
    height: calc(100vh - 195px);
    overflow-y: auto;
}

@media(max-width:767.5px) {
    .sticky-left-content {
        position: sticky;
        height: auto;
        top: 0;
        background-color: #fff;
        z-index: 10;
    }
    .help-page-wrapper .title-wrapper {
        padding: 0px;
    }
}

.title-bar {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    opacity: 0.54;
}

.title-bar .title {
    text-align: left;
    font: normal normal 200 40px/82px Manrope;
    letter-spacing: 6px;
    color: #494F54;
    text-transform: lowercase;
    opacity: 1;
    margin-bottom: 0;
}

@media(max-width:767.5px){
    .title-bar .title {
        text-align: left;
        font: normal normal 200 40px/62px Manrope;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/dashboard/help.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;QAChB,YAAY;QACZ,MAAM;QACN,sBAAsB;QACtB,WAAW;IACf;IACA;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,+CAA+C;IAC/C,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,yCAAyC;IACzC,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;QAChB,yCAAyC;IAC7C;AACJ","sourcesContent":[".test {\n    margin-top: 30;\n}\n\n.help-tab-wrapper li.active {\n    background-color: #00863F;\n    color: #fff;\n    border-radius: 0;\n}\n\n.help-tab-wrapper li {\n    padding: 10px;\n    margin: 10px;\n    cursor: pointer;\n}\n\n.sticky-left-content {\n    position: sticky;\n    top: 100px;\n    height: calc(100vh - 195px);\n    overflow-y: auto;\n}\n\n@media(max-width:767.5px) {\n    .sticky-left-content {\n        position: sticky;\n        height: auto;\n        top: 0;\n        background-color: #fff;\n        z-index: 10;\n    }\n    .help-page-wrapper .title-wrapper {\n        padding: 0px;\n    }\n}\n\n.title-bar {\n    background: #EEEEEE 0% 0% no-repeat padding-box;\n    opacity: 0.54;\n}\n\n.title-bar .title {\n    text-align: left;\n    font: normal normal 200 40px/82px Manrope;\n    letter-spacing: 6px;\n    color: #494F54;\n    text-transform: lowercase;\n    opacity: 1;\n    margin-bottom: 0;\n}\n\n@media(max-width:767.5px){\n    .title-bar .title {\n        text-align: left;\n        font: normal normal 200 40px/62px Manrope;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
