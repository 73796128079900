import React from 'react';
import './footer.css';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const NATFooter = () => {

    const navigate = useNavigate();

    const handleCATLogin = event => {
        try {
            event.preventDefault();
            localStorage.clear();
            setTimeout(() => {
                navigate("/login");
            }, 1000);
        } catch (err) {
            console.log("error at handleCATLogin in NAT Footer");
        }
    };
    return (
        <section className='cta-wrapper'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <p className='title'>Are you interested in exploring Occupational Health and Safety (OHS) competencies for you or your team?</p>
                            <p className='body-text'>The Competency Assessment Tool (CAT) enables individuals to discover and document their knowledge and skills to help employers build a safer and more resilient workplace. Assess your own competencies or explore the experience your team will have when they use the tool. You’ll need to create a new professional profile with a separate email address to access the CAT.</p>
                            <div>
                                <Button variant='secondary' onClick={handleCATLogin}>
                                    Visit the Competency Assessment Tool
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default NATFooter;
