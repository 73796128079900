import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  InputGroup,
} from "react-bootstrap";
import "./admin-form.css";
import { UserUpdate } from "../../services/adminService";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerHeader  from '../header/CustomerHeader';
import Modal from "./CancleModel";

const validationFields = ['first_name', 'last_name', 'employer_name'];

const UserProfileForm = () => {

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [save, setSave] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  
  const [formData, setFormData] = useState({
    first_name: localStorage.getItem('first_name'),
    email: localStorage.getItem('email'),
    last_name:localStorage.getItem('last_name'),
    employer_name:localStorage.getItem('employer_name'),
    phone:localStorage.getItem('phone'),
    position:localStorage.getItem('position')
  });

  const [validationErrors, setValidationErrors] = useState({
    first_name: false,
    last_name: false,
    employer_name: false,
    // phone: false
  });

  const onSubmit = async (user_id) => {
    if (!formData.first_name || !formData.last_name || !formData.employer_name) {
      toast.warn("Please complete all required fields.");
      return;
    } else if (!/^\d*$/.test(formData.phone)) {
      toast.warn("Enter valid phone.");
      return;
    } 
      user_id =  localStorage.getItem('user_id');
    // UserUpdate({...formData,user_id} );
    //   navigate("/dashBoard");
    try {
      // Make the API request to update the user's data
      const response = await UserUpdate({ ...formData, user_id });
  
      if (response.status === 200) {
        localStorage.setItem('first_name', formData.first_name);
        localStorage.setItem('last_name', formData.last_name);
        localStorage.setItem('employer_name', formData.employer_name);
        localStorage.setItem('phone', formData.phone);
        toast.success("Profile updated successfully.");
        setTimeout(() => {
        navigate("/dashBoard");
      }, 1000);
      } else {
        toast.error("Failed to update profile.");
      }
    } catch (error) {
      toast.error("An error occurred while updating profile.");
      console.error("Error updating profile:", error);
    }
  };

  const openModal = () => {
    if (hasChanges) {
      setShowModal(true);
    } else {
      navigate("/dashBoard");
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const yes = () => {
      navigate("/dashBoard");
  };

  useEffect(() => {
      setFormData({ ...formData });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setSave(true);
    setHasChanges(true);
    if (validationFields.includes(name)) {
      if (value.trim() === '') {
        setValidationErrors({ ...validationErrors, [name]: true });
      } else {
        setValidationErrors({ ...validationErrors, [name]: false });
      }
    }
  };

  return (
    <Fragment>
     <CustomerHeader />
     <section className="nat-cat-user-form">
      <Container>
        <p className="ms-ft-40 ms-fw-700 ms-ft-fadedblack">My Profile</p>
        <Form className="mt-50 form-wrapper">
          <Row className="mb-4">
            <Col md={6} xs={12}>
              <Form.Group controlId="formFirstName" className='position-relative'>
                <Form.Label>
                  <span className="text-danger"
                      title="required">{'*'}
                  </span>
                  {' First Name'}
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <Image
                      src={require("../../assets/images/user-green.svg").default}
                      alt="firstname"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="Enter your First Name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {validationErrors.first_name && <small className="error-message">{"First name is required."}</small>}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group controlId="formLastName" className='position-relative'>
                <Form.Label>
                    <span className="text-danger"
                        title="required">{'*'}
                    </span>
                    {' Last Name'}
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <Image
                      src={require("../../assets/images/user-green.svg").default}
                      alt="lastname"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Enter Last Name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {validationErrors.last_name && <small className="error-message">{"Last name is required."}</small>}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6} xs={12}>
              <Form.Group controlId="formEmployer" className='position-relative'>
                <Form.Label>
                    <span className="text-danger"
                        title="required">{'*'}
                    </span>
                    {' Employer'}
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <Image
                      src={require("../../assets/images/user-green.svg").default}
                      alt="employer"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="employer_name"
                    placeholder="Enter Employer Name"
                    value={formData.employer_name}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {validationErrors.employer_name && <small className="error-message">{"Employer is required."}</small>}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group controlId="formPhone" className='position-relative'>
                <Form.Label>
                    {/* <span className="text-danger"
                        title="required">{'*'}
                    </span> */}
                    {' Phone'}
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <Image
                      src={require("../../assets/images/phone.svg").default}
                      alt="phone"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="tel"
                    name="phone"
                    placeholder="+01"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {/* {validationErrors.phone && <small className="error-message">{"Phone is required."}</small>} */}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6} xs={12}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <Image
                      src={require("../../assets/images/message.svg").default}
                      alt="email"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group controlId="formPosition" className="custom-select">
                <Form.Label>Position</Form.Label>
                <Form.Select
                  name="position"
                  value={formData.position}
                  disabled
                  onChange={handleInputChange}
                >
                  <option value={""}>Select position</option>
                  <option value={"OHS Professional or Practitioner"}>
                    OHS Professional or Practitioner
                  </option>
                  <option value={"Business Owner"}>Business Owner</option>
                  <option value={"Manager or Hiring Manager"}>
                    Manager or Hiring Manager
                  </option>
                  <option value={"Student"}>Student</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div>
              <Button
                variant="secondary"
                className="w-100 mt-5 cancel-btn"
                onClick={() => openModal()}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="primary"
                className="w-100 mt-5 login-btn"
                onClick={() => onSubmit()}
                disabled={!save}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </section>
    <ToastContainer
      position="top-center"
      hideProgressBar
    />
    {showModal && (
      <Modal
        showModal={showModal}
        closeModal={closeModal}
        yes={yes}
      />
    )}
    </Fragment>
  );
};

export default UserProfileForm;
