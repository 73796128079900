import React, { useState } from 'react';
import { Row, Col, Container, Form, Button, Image, InputGroup } from 'react-bootstrap';
import './login.css';
import { pick } from "lodash";
import { userLogin } from "../../services/userServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const LoginComponent = () => {

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const initialStateFields = {
        email: '',
        password: ''
    };

    const [login, setLogin] = useState({});
    const [validationErrors, setValidationErrors] = useState({ 
        email: false,
        password: false
    });


    const onInputChange = (event) => {
        let value = '';
        const name = event.target.name;
        let lclFields = { ...login };
        value = event.target.value;
        setLogin({ ...lclFields, [name]: value });
        if ((name === 'email') || (name === 'password')) {
            if (value.trim() === '') {
              setValidationErrors({ ...validationErrors, [name]: true });
            } else {
              setValidationErrors({ ...validationErrors, [name]: false });
            }
        }
    };

    const handleLogin = (event) => {
        try {
            event.preventDefault();
            let saveLogin = pick({ ...login }, Object.keys(initialStateFields));
            if ((!saveLogin.email) || (!saveLogin.password)) {
                toast.warn("Please complete all required fields.");
            } else if (
                !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(saveLogin.email)
            ) {
                toast.warn("Enter valid email.");
            } else if (saveLogin.password.length < 6) {
                toast.warn("Password must be of atleast 6 characters long.");
            } else {
                var data = {
                    email: login.email,
                    password: login.password
                }
                userLogin(data)
                    .then((response) => {
                        console.log("login response from back-end====>", response);
                        toast.success("Login successfull");
                        localStorage.setItem('job_type', response.data.data.job_type);
                        localStorage.setItem('user_id', response.data.data.user_id);
                        localStorage.setItem('email', response.data.data.email);
                        localStorage.setItem('first_name', response.data.data.first_name);
                        localStorage.setItem('last_name', response.data.data.last_name);
                        localStorage.setItem('employer_name', response.data.data.employer_name);
                        localStorage.setItem('phone', response.data.data.phone);
                        localStorage.setItem('position', response.data.data.position);
                        localStorage.setItem('token', response.data.data.token);
                        setTimeout(() => {
                            navigate("/home");
                        }, 1000);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Login failed.");
                    });
            }
        } catch (err) {
            console.log("error at handleLogin");
        }
    }

    const handleClick = () => {
        navigate("/signup");
    }

    return (
        <>
        <section className='login-wrapper'>
            <Container fluid>
                <Row>
                    <Col xs={12} md={7} className='d-none d-md-block p-0'>
                        <div className='login-bg-img'>
                            <div className='img-wrapper'>
                                <Image src={require('../../assets/images/MSABC--logo.svg').default} alt="logo" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className='login-form-wrapper'>
                        <div className='container'>
                            <div className='d-block d-md-none mb-3'>
                                <Image src={require('../../assets/images/msabc-logo-black.svg').default} alt="logo" />
                            </div>
                            <h2 className='ms-ft-30 ms-fw-600 mb-0'>Login</h2>
                            <p className='ms-ft-16 mb-2 mt-30'>If you don’t have an account register</p>
                            <p className='ms-ft-16 mb-0'>You can <span className='ms-ft-primary ms-fw-600' onClick={handleClick} style={{ cursor: 'pointer', textDecoration: "none" }}>Register here !</span></p>
                            <Form className='mt-50'>
                                <Form.Group controlId='formEmail' className='position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Email'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/message.svg').default} alt="email" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type='email'
                                            name="email"
                                            placeholder='Enter your email address'
                                            value={login.email}
                                            onChange={onInputChange}
                                        />
                                    </InputGroup>
                                    {validationErrors.email && <small className="error-message">{"Email is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formPassword' className='mt-50 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Password'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/padlock.svg').default} alt="password" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter your Password'
                                            name='password'
                                            value={login.password}
                                            onChange={onInputChange}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant='link'
                                                onClick={togglePasswordVisibility}
                                                className='password-toggle-btn'
                                            >
                                                {!showPassword ? (
                                                    <Image className='pwd-icon' src={require('../../assets/images/invisible.svg').default} alt="eye-slash" />
                                                ) : (
                                                    <Image className='pwd-icon' src={require('../../assets/images/view.svg').default} alt="eye" />
                                                )}
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {validationErrors.password && <small className="error-message">{"Password is required."}</small>}
                                </Form.Group>

                                <Form.Group controlId='formRememberMe' className='d-flex align-items-center justify-content-end mt-3'>
                                    {/* <Form.Check type='checkbox' label='Remember me' className='ms-ft-12' /> */}
                                    <a href='/forgotpassword' className='ms-ft-12 text-decoration-none ms-ft-black'>Forgot Password ?</a>
                                </Form.Group>

                                <Button
                                    variant='primary'
                                    type='submit'
                                    className='w-100 mt-5 login-btn'
                                    onClick={handleLogin}
                                >
                                    Login
                                </Button>
                                {/* <div className='social-media-wrapper'>
                                    <p className='ms-ft-grey ms-fw-600 mb-0'>or continue with</p>
                                    <div className='d-flex align-items-center justify-content-center mt-40'>
                                        <div>
                                            <a href="/" className='text-decoration-none'>
                                                <Image src={require('../../assets/images/Facebook.svg').default} alt="Facebook Icon" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="/" className='text-decoration-none'>
                                                <Image src={require('../../assets/images/apple.svg').default} alt="Apple Icon" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="/" className='text-decoration-none'>
                                                <Image src={require('../../assets/images/google.svg').default} alt="Google Icon" />
                                            </a>
                                        </div>
                                    </div>
                                </div> */}

                            </Form>
                        </div>


                    </Col>
                </Row>
            </Container>
        </section>
        <ToastContainer
            // style={{ width: '400px', /* Other styles go here */ }}
            position="top-center"
            hideProgressBar
        />
        </>
    );
};

export default LoginComponent
