const data = [   
    {
        questionHeader: "101: Principles of Hazard Identification",
        questionText: "OHS professionals must identify and control hazards using hazard-specific legislation, standards, guidelines, and safety analysis methods.",
        natDesc: "Can identify and control hazards using hazard-specific legislation, standards, guidelines, and safety analysis methods.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can follow up with other OHS professionals and experts about identified hazards and their harms. ",
                "Can identify the primary types of hazards, including physical, ergonomic, chemical, biological, and psychological. ",
                "Can recognize relevant legal requirements and other requirements that pertain to workplace hazards. ",
                "Can review hazard analysis methods, such as failure modes and effects analysis, hazard and operability study, and fault tree analysis."
            ],
            levelB :[
                "Can communicate the importance of job safety analysis and hazard analysis to team members by engaging with stakeholders. ",
                "Can research hazard-specific legislation, standards, and guidelines to find the best practices for maintaining a safe work environment."
            ],
            levelC :[ 
                "Can analyze the quality and completeness of a job safety analysis. ",
                "Can innovate upon existing hazard analysis processes. ",
                "Can teach the principles of hazard identification to other OHS professionals."
            ]
        }],
    },
    {
        questionHeader: "102: Hazard Identification and Analysis",
        questionText: "OHS professionals must continuously find and respond to hazards quickly as they emerge.",
        natDesc: "Can continuously find and respond to hazards quickly as they emerge.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about the hazards identified by a job safety analysis. ",
                "Can explain how to interpret the results of a job safety analysis. ",
                "Can identify the purpose of hazard identification and analysis. ",
                "Can recognize the legal requirements and other requirements that pertain to the identification of hazards in the workplace."
            ],
            levelB :[
                "Can assess organizational processes by conducting a job safety analysis and identifying potential hazards. ",
                "Can develop a job safety analysis to identify potential hazards and their impacts on organizational processes. ",
                "Can implement and monitor hazard identification equipment within the workplace. ",
                "Can review the results of a job safety analysis with stakeholders to communicate any identified hazards and their potential harms."
            ],
            levelC :[ 
               "Can innovate upon current job safety analysis processes. ",
               "Can design a comprehensive job safety analysis evaluation system to ensure quality. ",
               "Can innovate hazard assessment and profiling procedures to adapt to changing worksite contexts. ",
               "Can resolve issues and concerns related to job safety analysis implementation through effective communication and collaboration. ",
               "Can teach OHS professionals about the purpose and processes of a job safety analysis."
            ]
        }]
    },
    {
        questionHeader: "103: Identifying New and Emerging Hazards",
        questionText: "OHS professionals must proactively identify new and emerging hazards and provide recommendations on how to address them.",
        natDesc: "Can proactively identify new and emerging hazards and provide recommendations on how to address them.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts to review new and emerging hazards that may produce unknown harms. ",
                "Can identify risks involving conscious opponents who deliberately seek to avoid being compliant with legal requirements and other requirements. ",
                "Can communicate with other OHS professionals and experts about them slow-acting hazards that may take many years to manifest negative effects."
            ],
            levelB :[
                "Can assess new and emerging hazards for their potential to produce harm. ",
                "Can communicate effectively with stakeholders regarding the levels of hazards and the importance of reporting new and emerging hazards. ",
                "Can research best practices in identifying and controlling for new and emerging hazards."
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of organizational preparedness programs. ",
                "Can design a program for identifying new and emerging hazards. ",
                "Can innovate upon a standardized method, like  SWOT analysis for evaluating and analyzing new and emerging hazards. ",
                "Can resolve issues related to identifying new and emerging hazards in the workplace through effective communication with stakeholders. ",
                "Can teach OHS professionals how to identify new and emerging hazards."
            ]
        }]
    },
    {
        questionHeader: "104: Causation Models",
        questionText:  "OHS professionals must understand and apply causation models to identify root causes of incidents and prevent future harm.",
        natDesc: "Can understand and apply causation models to identify root causes of incidents and prevent future harm.",
        suggested: "B",
        answerText: [{
            levelA :[
               "Can recognize different models of causation. ",
               "Can review resources and processes used to determine the causal relationship between hazards and harm."
            ],
            levelB :[
                "Can assess a hazard by analyzing it with a cause model. ",
                "Can communicate the principles of causation to stakeholders in a report and in conversation. ",
                "Can develop and apply the appropriate models of causation for hazard identification."
            ],
            levelC :[ 
              "Can innovate policies, objectives, and procedures by evaluating their effectiveness against models of causation. ",
              "Can teach OHS professionals about the principles and application of models of causation to improve workplace safety."
            ]
        }]
    },
    {
        questionHeader: "105: Principles of Risk Control",
        questionText: "OHS professionals must identify and implement effective control measures for workplace hazards.",
        natDesc: "Can identify and implement effective control measures for workplace hazards.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can explain the hierarchy of controls. ",
                "Can explain the time sequence of pre-event, event, and post-event control and intervention points. ",
                "Can follow criteria for critical controls and principles of critical control management. ",
                "Can identify implementations of different risk controls. ",
                "Can recognize how control systems should be evaluated on an ongoing basis. ",
                "Can recognize inherent safety and engineered safe design principles."
            ],
            levelB :[
               "Can communicate risk control policies and procedures to stakeholders. ",
               "Can implement and monitor risk controls and processes."
            ],
            levelC :[ 
               "Can analyze an organization's adherence to risk controls and identify areas for improvement. ",
               "Can analyze the effectiveness of risk controls and recommend modifications as necessary. ",
               "Can design and develop innovative risk controls to address identified workplace hazards. ",
               "Can teach OHS professionals about the principles of risk controls and how to implement them effectively."
            ]
        }]
    },
    {
        questionHeader: "106: Hazard Elimination and Substitution",
        questionText: "OHS professionals must design or redesign the workplace to eliminate hazards whenever possible.",
        natDesc: "Can design or redesign the workplace to eliminate hazards whenever possible.",
        suggested: "C",
        answerText: [{
            levelA :[
               "Can identify appropriate replacement materials for hazardous ones. ",
               "Can recognize that elimination is the most effective way to control risks. ",
               "Can identify workplace hazards that can be eliminated. ",
               "Can review and communicate with other OHS professionals and experts about hazards that can be eliminated or substituted."
            ],
            levelB :[
               "Can assess risks to determine if elimination or substitution is a viable control method. ",
               "Can communicate the purpose of elimination or substitution to stakeholders. ",
               "Can implement and monitor the elimination of a hazard from the work place. ",
               "Can research and recommend alternative equipment or processes that substitute or eliminate the hazard."
            ],
            levelC :[ 
               "Can analyze the effectiveness of substitution controls to reduce exposure to hazards. ",
               "Can teach OHS professionals about the importance of elimination and substitution controls. ",
               "Can innovate new elimination or substitution control methods to further reduce risk in the workplace. ",
               "Can resolve challenges related to implementing elimination or substitution control methods in a way that maximizes their effectiveness."
            ]
        }]
    },
    {
        questionHeader: "107: Engineering Controls",
        questionText: "OHS professionals must implement systems that reduce worker contact with a hazard.",
        natDesc: "Can implement systems that reduce worker contact with a hazard.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about the effectiveness of engineering controls. ",
                "Can explain the purpose and implementation of engineering controls. ",
                "Can recognize the legal reqiurements and other requirements regarding the implementation and maintenance of engineering controls. ",
                "Can identify any deficiencies or issues with engineering controls. ",
                "Can recognize potential hazards and risks that require engineering controls. ",
                "Can review the implementation of different types of engineering controls."
            ],
            levelB :[
                "Can communicate with stakeholders about the importance of engineering controls in preventing harm. ",
                "Can implement and monitor engineering controls. ",
                "Can communicate with stakeholders about how to properly adhere to engineering controls."
            ],
            levelC :[ 
               "Can analyze existing engineering controls to determine their effectiveness. ",
               "Can analyze procedural compliance to identify areas for improvement. ",
               "Can design engineering control programs to mitigate risk. ",
               "Can resolve issues related to the implementation and maintenance of engineering controls. ",
               "Can teach OHS professionals about the benefits and limitations of different kinds of engineering controls."
            ]
        }]
    },
    {
        questionHeader: "108: Administrative Controls",
        questionText: "OHS professionals must maintain and ensure the effectiveness of administrative controls.",
        natDesc: "Can maintain and ensure the effectiveness of administrative controls.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about any deficiencies or issues with administrative controls. ",
                "Can explain the purpose and implementation of administrative controls. ",
                "Can identify potential hazards that require administrative controls. ",
                "Can review the implementation of different types of administrative controls, including training programs and procedures."
            ],
            levelB :[
               "Can communicate to stakeholders how to properly adhere to administrative controls and the importance of such controls in preventing harm. ",
               "Can implement and monitor administrative controls."
            ],
            levelC :[ 
               "Can analyze an organization's adherence to administrative controls and identify areas for improvement. ",
               "Can innovate upon existing administrative controls to enhance workplace safety. ",
               "Can design effective administrative control programs. ",
               "Can resolve issues related to the implementation and effectiveness of administrative controls through collaboration with stakeholders."
            ]
        }]
    },
    {
        questionHeader: "109: Personal Protective Equipment",
        questionText: "OHS professionals must select, use, and maintain appropriate PPE for workers in hazardous environments.",
        natDesc: "Can select, use, and maintain appropriate PPE for workers in hazardous environments.",
        suggested: "C",
        answerText: [{
            levelA :[
               "Can communicate with other OHS professionals and experts about any deficiencies or issues with PPE. ",
               "Can explain the purpose and implementation of PPE. ",
               "Can follow established guidelines and procedures for the use of PPE. ",
               "Can identify potential hazards that require PPE. ",
               "Can recognize the importance of properly fitting PPE."
            ],
            levelB :[
               "Can assess and maintain PPE according to a manufacturer's standard. ",
               "Can communicate with stakeholders about the importance of PPE in preventing harm and on its proper use. ",
               "Can implement and monitor a PPE program. ",
               "Can implement proper PPE fitting practices."
            ],
            levelC :[ 
               "Can analyze and evaluate an organization's compliance with PPE procedures to identify areas for improvement. ",
               "Can analyze and evaluate existing PPE to determine their effectiveness in mitigating risk. ",
               "Can design and develop effective PPE programs for organizations. ",
               "Can resolve issues related to PPE implementation and compliance through effective communication and problem-solving skills. ",
               "Can teach OHS professionals about the methods of implementing PPE to ensure worker safety."
            ]
        }]
    },
    {
        questionHeader: "201: Principles of Risk Assessment",
        questionText: "OHS professionals must make decisions based on the risks present in an organization.",
        natDesc: "Can make decisions based on the risks present in an organization.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the difference between hazards and risks. ",
                "Can identify risks as a function of the likelihood of harm and the severity of harm. ",
                "Can recognize risk as a multi-faceted concept. ",
                "Can recognize the qualitative and quantitative aspects of risk."
            ],
            levelB :[
                "Can assess a risk's severity based on its likelihood and potential consequences. ",
                "Can communicate effectively with stakeholders to ensure they are informed about risk assessment activities."
            ],
            levelC :[ 
                "Can analyze the effects of risk assessment activities on an organization's performance. ",
                "Can analyze the interaction between organizational changes and risk. ",
                "Can analyze workers' and senior management's understanding of risk in the workplace. ",
                "Can resolve complex problems related to changing levels of risk."
            ]
        }]
    },    
    {
        questionHeader: "202: Risk Analysis",
        questionText: "OHS professionals must assess how likely and how severe a given risk is.",
        natDesc: "Can assess how likely and how severe a given risk is.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about a risk and its associated likelihood and severity . ",
                "Can explain the purpose of risk analysis. ",
                "Can identify the appropriate control measures to mitigate identified risks. ",
                "Can recognize the legal requirements and other requirements that pertain to risk analysis."
            ],
            levelB :[
                "Can assess a risk to estimate its likelihood and severity of produced harm. ",
                "Can communicate with stakeholders about the impact of identified risks and their severity on an organization's policies, objectives, and procedures. ",
                "Can develop risk prioritization practices using established resources and processes, such as risk matrices."
            ],
            levelC :[ 
                "Can analyze an existing risk identification and profiling plan to identify areas for improvement. ",
                "Can analyze an organization's context and develop appropriate risk analysis procedures. ",
                "Can teach OHS professionals about risk analysis procedures and their importance in promoting workplace safety."
            ]
        }]
    },
    {
        questionHeader: "203: Risk Evaluation",
        questionText: "OHS professionals must develop strategies to manage risk effectively.",
        natDesc: "Can develop strategies to manage risk effectively.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain basic statistical concepts, such as the mean and the standard deviation, to non-technical stakeholders. ",
                "Can explain the processes used in risk evaluation, risk estimation and prioritization . ",
                "Can recognize the implications of statistical concepts on risk evaluation strategies. ",
                "Can recognize the legal requirements and other requirements that pertain to the identification of risks in the workplace."
            ],
            levelB :[
                "Can communicate recommendations for corrective actions based on risk evaluation to stakeholders. ",
                "Can communicate risk likelihoods and risk prioritization metrics to stakeholders. ",
                "Can implement and monitor a system for risk re-evaluation."
            ],
            levelC :[ 
                "Can analyze risk evaluation results to develop effective action plans. ",
                "Can communicate risk evaluation results and recommendations to stakeholders in a clear and concise manner. ",
                "Can design training programs to teach OHS professionals about risk evaluation methods. ",
                "Can innovate strategies to enhance the efficiency and effectiveness of risk evaluation. ",
                "Can resolve risk evaluation results with organizational priorities."
            ]
        }]
    },
    {
        questionHeader: "204: Monitoring Risk",
        questionText: "OHS professionals must monitor and evaluate the ongoing effectiveness of risk controls.",
        natDesc: "Can monitor and evaluate the ongoing effectiveness of risk controls.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can explain how recurring data analysis can help identify trends and patterns in workplace incidents. ",
                "Can explain how risk monitoring helps identify potential hazards in the workplace. ",
                "Can explain the importance of regular risk assessments in maintaining a safe work environment. ",
                "Can explain the steps involved in conducting a thorough analysis of OHS performance data. ",
                "Can recognize how new risks are introduced to the workplace."
            ],
            levelB :[
                "Can assess how changes in risk impacts an organization. ",
                "Can assess how changes in risk impact workers. ",
                "Can communicate effectively with stakeholders about changes in workplace risk. ",
                "Can implement and monitor an existing risk monitoring system."
            ],
            levelC :[ 
               "Can innovate by incorporating new technology into risk-monitoring systems. ",
               "Can innovate risk-monitoring systems to incorporate new technology and enhance monitoring capabilities."
            ]
        }]
    },
    {
        questionHeader: "205: Risk Assessment in Emergencies",
        questionText: "OHS professionals must conduct risk assessments and understand an organization's context and external agencies during emergencies.",
        natDesc: "Can conduct risk assessments and understand an organization's context and external agencies during emergencies.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about the effectiveness of emergency response processes, including risk assessment processes. ",
                "Can explain the different types of emergency events, such as natural disasters, workplace accidents, and medical emergencies. ",
                "Can follow procedures for responding to different emergency events. ",
                "Can recognize the legal requirements and other requirements that pertain to emergency response."
            ],
            levelB :[
                "Can assess risk during an emergencies and extraordinary circumstances. ",
                "Can communicate with stakeholders about emergency response procedures. ",
                "Can implement and monitor emergency response training programs and drills."
            ],
            levelC :[ 
                "Can analyze and evaluate existing emergency response procedures to identify areas for improvement. ",
                "Can communicate effectively with stakeholders to ensure a coordinated and efficient emergency response. ",
                "Can design and innovate emergency response procedures to effectively address potential safety risks. ",
                "Can resolve complex safety and emergency response challenges by considering the unique context and needs of the situation."
            ]
        }]
    },
    {
        questionHeader: "206: Documentation of Risk Assessments",
        questionText: "OHS professionals must express the information gathered from risk assessment in written records.",
        natDesc: "Can express the information gathered from risk assessment in written records.",
        suggested: "B",
        answerText: [{
            levelA :[
               "Can explain the formal structure and methods for report writing. ",
               "Can follow established procedures for risk assessment reporting. ",
               "Can identify the appropriate and necessary information to include in risk assessment reporting. ",
               "Can recognize the legal requirements and other requirements that pertain to documenting risk assessments. ",
               "Can review the appropriate data from internal and external sources."
            ],
            levelB :[
                "Can assess other risk assessment documentation for completeness and conclusions. ",
                "Can conduct ongoing risk assessments in response to changes in the context of the organization. ",
                "Can research relevant data from internal and external sources to inform risk reporting."
            ],
            levelC :[ 
               "Can analyze the quality of other risk assessment documentation. ",
               "Can design effective risk assessment reports for OHS professionals to use. ",
               "Can teach OHS professionals how to design and complete a risk assessment report."
            ]
        }]
    },
    {
        questionHeader: "207: Risk Assessment Action Plans",
        questionText: "OHS professionals must design corrective action plans that address hazards and effectively communicate them to stakeholders.",
        natDesc: "Can design corrective action plans that address hazards and effectively communicate them to stakeholders.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about concerns about the functioning of hazard monitoring equipment. ",
                "Can communicate with other OHS professionals and experts about outstanding risk-related concerns to specialists. ",
                "Can explain basic OHS science and its relevance to different hazards and their controls. ",
                "Can identify the functioning and hazards presented by different types of machinery and processes. ",
                "Can review technical standards to ensure compliance."
            ],
            levelB :[
                "Can communicate OHS performance metrics and action plans to stakeholders. ",
                "Can communicate the relevance of changes in OHS best practices to an organization to that organization's stakeholders. ",
                "Can research newly available technologies in risk assessment and control."
            ],
            levelC :[ 
                "Can communicate the importance of corrective action plans in addressing new or emerging hazards. ",
                "Can resolve conflicts between corrective action plans and an organization's policies, objectives, and procedures. ",
                "Can teach organizations how to develop and implement effective corrective action plans."
            ]
        }]
    },
    {
        questionHeader: "208: Reducing Risk within Organizations",
        questionText: "OHS professionals must address risk through deploying strategies in partnership with stakeholders.",
        natDesc: "Can address risk through deploying strategies in partnership with stakeholders.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can identify different risk-reduction resources and processes. ",
                "Can identify the requirements and objectives of a business continuity plan. ",
                "Can recognize different types of business continuity plans, including succession plans. ",
                "Can review the appropriate risk-reduction strategies to adopt to address different kinds of risk."
            ],
            levelB :[
                "Can assess an action plan for its compatibility with a business continuity plan. ",
                "Can communicate effectively with stakeholders to ensure understanding and compliance with safe work practices. ",
                "Can implement and monitor an OHS-relevant business continuity plan."
            ],
            levelC :[ 
                "Can analyze a business continuity plan for feasibility and effectiveness. ",
                "Can design a new business continuity plan in response to changes to the context of an organization. ",
                "Can innovate upon risk reduction strategies to improve workplace safety. ",
                "Can resolve conflicts and issues related to workplace safety through effective communication and collaboration. ",
                "Can teach OHS professionals about risk reduction resources and processes to improve workplace safety."
            ]
        }]
    },
    {
        questionHeader: "209: Workplace Inspections",
        questionText: "OHS professionals must conduct workplace inspections and recommend actions to control risks.",
        natDesc: "Can conduct workplace inspections and recommend actions to control risks.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about the recommended corrective actions based on an inspection. ",
                "Can identify the different types of workplace inspections. ",
                "Can recognize how workplace inspections help achieve safety objectives. ",
                "Can recognize the legal and other requirements that pertain to workplace inspections."
            ],
            levelB :[
                "Can assess the effectiveness of workplace safety inspections and make recommendations for improvement to stakeholders. ",
                "Can conduct various types of workplace inspections, such as hazard assessments, ergonomic evaluations, and fire safety checks. ",
                "Can review inspection reports and communicate findings to relevant stakeholders."
            ],
            levelC :[ 
                "Can analyze the effectiveness of inspection processes in addressing changes within the organization's context. ",
                "Can analyze the quality of completed inspection reports. ",
                "Can innovate new inspection processes that are more efficient and effective in identifying hazards. ",
                "Can teach OHS professionals how to perform various types of inspections and provide guidance on best practices."
            ]
        }]
    },
    {
        questionHeader: "301: Principles of Incident Investigation",
        questionText: "OHS professionals must understand incident investigation concepts to prevent workplace incidents.",
        natDesc: "Can understand incident investigation concepts to prevent workplace incidents.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can communicate with relevant stakeholders during the investigation process. ",
                "Can explain the legal requirements and other requirements for reporting incidents. ",
                "Can recognize the importance of scene preservation and evidence preservation. ",
                "Can explain the link between incident-related data and the investigation process . ",
                "Can recognize the importance of ensuring scene and evidence preservation. ",
                "Can recognize the importance of determining the root cause of incidents rather than assigning blame. ",
                "Can review and ensure urgent corrective actions are taken post incidents."
            ],
            levelB :[
                "Can conduct ongoing monitoring and evaluation of an incident investigation and prevention plan, including assessing the performance of incident investigation teams. ",
                "Can conduct ongoing monitoring of organizational roles and responsibilities to ensure compliance with legal requirements and other requirements. ",
                "Can implement and monitor incident investigation protocols and procedures to ensure compliance with the organization's incident investigation and prevention program. ",
                "Can communicate appropriate and management-approved information about the incident investigation process with stakeholders. ",
                "Can identify incidents that require either internal or external investigation."
            ],
            levelC :[ 
                "Can design complex investigations utilizing the principles and processes standards. ",
                "Can design adaptable investigative procedures tailored to incident severity and location. ",
                "Can design strategies for continual improvement of the incident investigation and prevention program. ",
                "Can analyze an incident investigation and prevention plan’s performance in engaging senior management and workers. ",
                "Can innovate upon best practices in incident investigation and prevention to conduct better investigations. ",
                "Can train others on the principles and importance of incident investigation. ",
                "Can communicate OHS advice to legal teams as required. ",
                "Can resolve barriers to the investigation process, such as conflicts of interest, confidentiality concerns, labour concerns, or trade secrets."
            ]
        }]
    },
    {
        questionHeader: "302: Incident Theory",
        questionText: "OHS professionals must use incident theory to understand contributing factors and guide investigations.",
        natDesc: "Can use incident theory to understand contributing factors and guide investigations.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can explain the concepts of incident proneness, near misses, risk transfer, risk retention, and risk responsibility. ",
                "Can identify the various incident theories, including domino, energy release, human factors, and multi-causation. ",
                "Can recognize the theories and models that guide investigations."
            ],
            levelB :[
                "Can implement different incident theories during an investigation. ",
                "Can conduct an unbiased investigation that considers multi-factor causation. ",
                "Can communicate different incident theories, including their strengths and weaknesses, to workers and senior management. ",
                "Can conduct an incident investigation by following a systematic process and incorporating the appropriate theories and models."
            ],
            levelC :[ 
                "Can analyze an investigation finding at a high level through applying different incident theories. ",
                "Can design incident prevention strategies using incident theories. ",
                "Can innovate investigative approaches by incorporating different models based on incident trends and organizational legal requirements and other requirements. ",
                "Can train other OHS professionals on how to apply incident theories and models. ",
                "Can communicate with executive level stakeholders about the rationale for applying a particular incident theory to an investigation."
            ]
        }]
    },
    {
        questionHeader: "303: Scene Management",
        questionText: "OHS professionals must effectively control an incident scence, assess risks, and preserve data.",
        natDesc: "Can effectively control an incident scence, assess risks, and preserve data.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can recognize that an investigation prioritizes the safety of people involved in an incident. ",
                "Can follow expert advice that supports the physical and psychological health of witnesses. ",
                "Can identify the duties and responsibilities of stakeholders during investigation initialization. ",
                "Can identify the legal requirements and other requirements related to both scene management and evidence preservation."
            ],
            levelB :[
                "Can communicate the purpose, roles, and responsibilities of senior management, workers, and other stakeholders during an investigation initialization. ",
                "Can conduct a dynamic risk assessment and implement appropriate control strategies. ",
                "Can develop an investigation plan based on the preliminary data and applying organizational protocols. ",
                "Can implement and monitor an incident investigation plan for the preservation of evidence. ",
                "Can support scene management and scene safety protocols, ensuring compliance with legal requirements and other requirements."
            ],
            levelC :[ 
                "Can analyze and manage a complex incident scene through navigating competing organizational interests and ensuring an organization’s compliance with legal requirements and other requirements. ",
                "Can develop and present a written scene management plan to stakeholders, which includes expectations for stakeholder compliance and protocol. ",
                "Can communicate with stakeholders to manage expectations of and promote compliance with an incident investigation. ",
                "Can design policies, objectives, and processes for effective scene management that comply with an organization’s legal requirements and other requirements. ",
                "Can train other OHS professionals on scene management protocols."
            ]
        }]
    },
    {
        questionHeader: "304: Data Collection",
        questionText: "OHS professionals must rigorously gather incident-relevant data through interviewing and evidence collection.",
        natDesc: "Can rigorously gather incident-relevant data through interviewing and evidence collection.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can identify sector-specific factors, such as hazards, that can inform data collection strategies. ",
                "Can recognize the different types of data, including perishable data, interview data, documents, physical and digital evidence. ",
                "Can review data collection and documentation best practices and an organization’s legal requirements and other requirements."
            ],
            levelB :[
                "Can communicate effectively with stakeholders while maintaining the integrity of the investigation. ",
                "Can conduct preliminary interviews. ",
                "Can develop and maintain a strong and consistent digital record-keeping process. ",
                "Can document all data collected at the scene, witness statements, interviews, research, and security camera images. ",
                "Can follow best practice protocols for identifying, recording, handling, storing, documenting, and filing data. ",
                "Can implement priority measures to prevent evidence contamination in situations such as adverse weather conditions."
            ],
            levelC :[ 
                "Can design and implement a consistent methodology for evidence collection, adapting procedures based on incident scenarios. ",
                "Can design written data management protocols. ",
                "Can teach and guide other investigation team members on how to responsibly collect data. ",
                "Can resolve any issues related to data collection to ensure accurate and reliable information is available for decision-making. ",
                "Can design and conduct complex interviews in accordance to best practices."
            ]
        }]
    },
    {
        questionHeader: "305: Data Analysis",
        questionText: "OHS professionals must organize and assess incident data with accuracy and completeness.",
        natDesc: "Can organize and assess incident data with accuracy and completeness.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can communicate effectively with other OHS professionals and experts about data security concerns. ",
                "Can explain potential reasons for information gaps or inaccurate data identified during an investigation. ",
                "Can recognize different analytical tools used to manage and interrogate data. ",
                "Can recognize the importance of adhering to data management storage procedures."
            ],
            levelB :[
                "Can assess and identify emerging themes or trends related to data collected from an incident. ",
                "Can communicate statistical findings to various stakeholders through written reports and oral presentations. ",
                "Can develop and provide progress updates on data analysis to team members during the investigation. ",
                "Can review and ensure that the gathered and collated data is presented in a clear and consistent format, utilizing various software tools as needed."
            ],
            levelC :[ 
                "Can analyze both primary and secondary data using best practices . ",
                "Can analyze complex data and verify the accuracy of any results before incorporating them into a report. ",
                "Can communicate data findings effectively to stakeholders through clear and concise presentations. ",
                "Can design data presentations that adhere to best practice standards. ",
                "Can train other incident investigators and senior management in an analytical approach to exploring data and determining its validity, accuracy, and completeness."
            ]
        }]
    },
    {
        questionHeader: "306: Investigation Reports",
        questionText: "OHS professionals must write thorough and factual investigation reports, linking data to causal factors and proposing prevention activities.",
        natDesc: "Can write thorough and factual investigation reports, linking data to causal factors and proposing prevention activities.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can explain the required elements for a complete investigation report. ",
                "Can explain the differences between a preliminary report and a final report. ",
                "Can recognize the legal requirements and other requirements for investigators regarding preliminary and final reporting. ",
                "Can review existing workplace health and safety procedures and their relevance to a preliminary or final report."
            ],
            levelB :[
                "Can document a chronological account of the events prior, during and post the incident. ",
                "Can communicate with stakeholders about the investigation findings in an unbiased manner. ",
                "Can develop complete incident investigation reports that include the immediate corrective actions taken during the early investigative phase and recommendations for further risk controls. ",
                "Can document data supporting the investigation hypotheses within the investigation report."
            ],
            levelC :[ 
                "Can analyze team members' investigation contributions through peer review. ",
                "Can communicate investigation reports to various stakeholders, including with internal legal professionals as required. ",
                "Can design investigative reports in a clear, concise, and consistent format, following recognized industry standards.. ",
                "Can design tabulated and logically organized preliminary and final reports, ensuring all data is included and supporting metrics are provided as annexes.. ",
                "Can analyze the performance of corrective actions taken during the investigation and include that analysis within the report. ",
                "Can teach and guide others on how to prepare and produce excellent investigation reports. ",
                "Can communicate an investigation report to various external stakeholders such as provincial and federal officers, legal representatives, labour representatives, and media."
            ]
        }]
    },
    {
        questionHeader: "307: Investigation Action Plans",
        questionText: "OHS professionals must develop, monitor, and communicate an action plan based on investigation findings to prioritize corrective actions.",
        natDesc: "Can develop, monitor, and communicate an action plan based on investigation findings to prioritize corrective actions.",
        suggested: "C",
        answerText: [{
            levelA :[
               "Can implement corrective actions based on hazard identification and risk assessment throughout an incident investigation. ",
                "Can communicate the purpose of a corrective action to stakeholders . ",
                "Can identify and document immediate corrective actions taken after an incident. ",
                "Can recognize the legal requirements and other requirements during before-, during-, and post-incident events."
            ],
            levelB :[
               "Can assess the response performance of emergency response procedures to develop or revise response plans. ",
               "Can develop and revise a post-incident action plan. ",
               "Can conduct a peer review of corrective actions already in place. ",
               "Can communicate operational metric data to stakeholders. ",
               "Can implement, monitor, and document post-incident performance management practices, using concepts like balance scorecards and key performance indicators. ",
               "Can monitor and adjust an action plan based on a suitable performance management methodology."
            ],
            levelC :[ 
               "Can analyze key performance indicators for an action plan’s performance and provide appropriate reports to internal stakeholders. ",
                "Can design and communicate action plans to internal stakeholders that describe lagging and leading OHS issues. ",
                "Can analyze and monitor the outcomes of a corrective actions to identify data patterns and target specific mitigation measures. ",
                "Can communicate corrective actions and investigation findings to stakeholders to embed safer practices with daily workplace operations. ",
                "Can resolve issues related to corrective actions and staff training, and communicate the outcomes to relevant stakeholders. ",
                "Can train OHS professionals on different approaches to risk assessment and control during incident investigation and prevention."
            ]
        }]
    },
    {
        questionHeader: "401: Principles of Health",
        questionText: "OHS professionals must develop strategies that promote worker health and wellness and educate workers and senior management.",
        natDesc: "Can develop strategies that promote worker health and wellness and educate workers and senior management.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the importance of maintaining good physical and psychological health. ",
                "Can recognize legal requirements and other requirements that pertain to an organization's commitment to worker health. ",
                "Can recognize the relationship between hazards and harm, including psychological harms such as stress and fatigue."
            ],
            levelB :[
                "Can assess a workplace for risks to physical and psychological health. ",
                "Can communicate the impact of physical and psychological health on organizational performance to stakeholders. ",
                "Can implement policies, objectives, and practices into an OHSMS to reduce risk of physical and psychological harm."
            ],
            levelC :[ 
                "Can analyze and evaluate the level of worker health and well-being within an organization. ",
                "Can teach and design training resources to enhance an organization's understanding of physical and psychological health."
            ]
        }]
    },
    {
        questionHeader: "402: Principles of Psychological Health and Safety",
        questionText: "OHS professionals must promote psychologically healthy and safe work processes.",
        natDesc: "Can promote psychologically healthy and safe work processes.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify the signs and symptoms of burnout in employees. ",
                "Can explain how psychological health and safety is relevant to OHS activities. ",
                "Can identify existing resources, like standards and guidelines, that can be used to promote psychological health within an organization."
            ],
            levelB :[
                "Can assess workplace mental health using existing resources, like surveys. ",
                "Can communicate effectively with stakeholders to provide updates on the current or anticipated state of an organization's psychological health and safety. ",
                "Can implement and monitor strategies to improve psychological health and safety."
            ],
            levelC :[ 
                "Can analyze the effectiveness of existing procedures and programs by comparing them against existing resources. ",
                "Can design a training program to teach an organization about the principles of psychological health and safety. ",
                "Can innovate upon the content of existing resources to address the unique needs of an organization."
            ]
        }]
    },
    {
        questionHeader: "403: Organizational Culture",
        questionText: "OHS professionals must build workplaces that are defined by trust, respect, and civility.",
        natDesc: "Can build workplaces that are defined by trust, respect, and civility.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify the factors involved in the modification of organizational culture, such as the relationship between workers and senior management. ",
                "Can recognize the importance of shared corporate values in establishing organizational culture. ",
                "Can recognize the importance of trust within the workplace for maintaining a strong organizational culture."
            ],
            levelB :[
                "Can assess an organization's culture through existing resources. ",
                "Can communicate advice on how to improve organizational culture to stakeholders. ",
                "Can research industry best practices for improving organizational culture."
            ],
            levelC :[ 
                "Can analyze an organization's culture to identify areas for improvement and develop processes to address them. ",
                "Can design solutions to resolve complex tensions between policies, objectives, and procedures and the need for good organizational culture. ",
                "Can innovate by forecasting future trends within an organization's culture and developing strategies to adapt to them."
            ]
        }]
    },
    {
        questionHeader: "404: Worker Wellness",
        questionText: "OHS professionals must address the full spectrum of a worker's health and understand the multifaceted concept of worker wellness.",
        natDesc: "Can address the full spectrum of a worker's health and understand the multifaceted concept of worker wellness.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate hazardous events to specialists in physical and psychological health. ",
                "Can explain the influence of an organization's context on worker wellness. ",
                "Can recognize the difference between mental health and mental illness."
            ],
            levelB :[
                "Can assess a workplace for chronic and cumulative health impacts. ",
                "Can assess a workplace for levels of worker fatigue and stress. ",
                "Can communicate with stakeholders about the impact of worker wellness on organizational performance. ",
                "Can implement ideas and strategies to improve worker wellness in collaboration with stakeholders."
            ],
            levelC :[ 
                "Can analyze and evaluate existing or proposed policies, objectives, and procedures for their impact on worker wellness. ",
                "Can design policies, objectives, and procedures with considerations for worker well-being. ",
                "Can resolve complex workplace issues that impact worker wellness, such as interpersonal conflict."
            ]
        }]
    },
    {
        questionHeader: "405: Health Promotion",
        questionText: "OHS professionals must develop and implement policies, objectives, and procedures that promote worker health and well-being.",
        natDesc: "Can develop and implement policies, objectives, and procedures that promote worker health and well-being.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about hazardous events that may have caused harm to workers' health. ",
                "Can follow expert recommendations in the use of sensitive language and confidentiality when talking about worker health. ",
                "Can recognize strategies to address and diffuse organizational resistance or stigma to health promotion processes. ",
                "Can recognize the impact of stigmatization on worker mental health."
            ],
            levelB :[
                "Can communicate the importance of stigma reduction to stakeholders. ",
                "Can implement and monitor health promotion processes, including action planning, to improve workers' health and wellness."
            ],
            levelC :[ 
                "Can analyze existing or proposed health promotion policies, objectives, and procedures. ",
                "Can communicate the importance of health promotion to employees and stakeholders. ",
                "Can design policies, objectives, and procedures that align with the principles of health promotion. ",
                "Can resolve conflicts related to health promotion policies, objectives, and procedures. ",
                "Can teach the principles of health promotion to employees and stakeholders."
            ]
        }]
    },
    {
        questionHeader: "406: Return-to-Work Programs",
        questionText: "OHS professionals must assist in the development, implementation, and monitoring of a return-to-work program.",
        natDesc: "Can assist in the development, implementation, and monitoring of a return-to-work program.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can follow a return-to-work plan. ",
                "Can identify the responsibilities of all stakeholders within a return-to-work program. ",
                "Can explain how workplace accommodations are relevant to an OHSMS"
            ],
            levelB :[
                "Can communicate effectively with stakeholders about the benefits of a return-to-work program. ",
                "Can implement a persuasive approach when presenting a return-to-work program to workers and senior management. ",
                "Can implement and monitor an organization's return-to-work program"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of intra-organizational collaboration in supporting workers returning from a disability or prolonged absence. ",
                "Can design effective return-to-work plans"
            ]
        }]
    },
    {
        questionHeader: "407: Workplace Vulnerability",
        questionText: "OHS professionals must understand how vulnerability emerges at the workplace and how to address it.",
        natDesc: "Can understand how vulnerability emerges at the workplace and how to address it.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can explain how interpersonal relationships can create vulnerability in the workplace. ",
                "Can identify interpersonal relationships that can create vulnerability in the workplace. ",
                "Can recognize relevant legal requirements and other requirements that prohibit discrimination. ",
                "Can communicate with other OHS professionals and experts about issues related to diversity and inclusion"
            ],
            levelB :[
                "Can communicate the importance of addressing vulnerability to stakeholders. ",
                "Can implement and monitor risk assessment processes centered on identifying and addressing vulnerability. ",
                "Can assess the relationship between vulnerability and sociodemographic factors, like age, immigration status, and disability"
            ],
            levelC :[ 
                "Can communicate effectively with stakeholders about the importance of addressing vulnerability and the steps being taken to mitigate it. ",
                "Can design and implement policies, objectives, and procedures to address vulnerability based on an evaluation and analysis of existing ones. ",
                "Can resolve issues related to vulnerability by collaborating with stakeholders and experts. ",
                "Can teach OHS professionals about the principles of vulnerability and how to apply them in their work to improve workplace safety"
            ]
        }]
    },
    {
        questionHeader: "501: Principles of OHSMS",
        questionText: "OHS professionals must set policies, objectives, and procedures that take a systematic approach to preventing harm.",
        natDesc: "Can set policies, objectives, and procedures that take a systematic approach to preventing harm.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the elements of an OHSMS, such as the roles of different stakeholder groups and the plan-do-check-act management method. ",
                "Can identify and differentiate between different certificate of recognition programs. ",
                "Can recognize the legal and other requirements for an OHSMS. ",
                "Can review the policies, objectives, and procedures contained within an OHSMS"
            ],
            levelB :[
                "Can develop and conduct training for various stakeholders in adhering to an OHSMS. ",
                "Can implement and monitor an existing OHSMS. ",
                "Can research best practices for implementing an OHSMS within a specific sector. ",
                "Can assess an OHSMS for its relevance and effectiveness during changes to the context of the organization"
            ],
            levelC :[ 
                "Can teach OHS professionals about the principles of developing and maintaining an effective OHSMS. ",
                "Can analyze the effectiveness of an existing OHSMS and identify areas for improvement"
            ]
        }]
    },
    {
        questionHeader: "502: Commitment, Leadership, and Participation",
        questionText: "OHS professionals must work with various stakeholders within an organization to promote and maintain an OHSMS.",
        natDesc: "Can work with various stakeholders within an organization to promote and maintain an OHSMS.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain how an OHSMS integrates with organizational objectives. ",
                "Can identify legal requirements and other requirements that pertain to worker and senior management participation within an OHSMS. ",
                "Can recognize the challenges in maintaining an OHSMS across multiple worksites"
            ],
            levelB :[
                "Can communicate the importance of senior leadership and worker participation within an OHSMS to stakeholders. ",
                "Can implement and maintain an OHSMS across an entire organizations or multiple worksites. ",
                "Can implement and maintain processes for the performance monitoring and measurement of different stakeholder group involvement"
            ],
            levelC :[ 
                "Can design an OHSMS that promotes stakeholder engagement across an organization. ",
                "Can resolve complex issues in aligning an OHSMS with the context of an organization"
            ]
        }]
    },
    {
        questionHeader: "503: Competence and Training",
        questionText: "OHS professionals must determine competency requirements for workers and oversee training activities.",
        natDesc: "Can determine competency requirements for workers and oversee training activities.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can explain the principles of adult learning. ",
                "Can identify different training resources and processes for working with difficult trainees. ",
                "Can recognize the importance of maintaining accurate training records"
            ],
            levelB :[
                "Can assess learners to evaluate the effectiveness of training activities. ",
                "Can communicate with stakeholders about training needs and progress through informal and formal advice. ",
                "Can conduct a training needs assessment. ",
                "Can develop documentation processes for training. ",
                "Can research providers, instructor qualifications, and course learning objectives to inform training strategies. ",
                "Can conduct training activities with different stakeholder groups"
            ],
            levelC :[ 
                "Can design an OHS training program that meets sector best practice standards. ",
                "Can innovate upon OHS training programs to ensure they remain up to date with changes in the context of the organization. ",
                "Can design training activities to meet future training needs. ",
                "Can teach others how to perform OHS training activities with high levels of worker engagement and retention"
            ]
        }]
    },
    {
        questionHeader: "504: OHSMS Documentation",
        questionText: "OHS professionals must navigate file management systems and maintain documentation for compliance with OHSMS requirements.",
        natDesc: "Can navigate file management systems and maintain documentation for compliance with OHSMS requirements.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can explain sources of OHS information both internal and external to the organization. ",
                "Can identify systems for managing OHS information. ",
                "Can recognize relevant legal requirements and other requirements that pertain to OHS documentation"
            ],
            levelB :[
                "Can assess an organization for good OHS document collection and file management processes. ",
                "Can implement and monitor OHS document and information systems. ",
                "Can research best practices to make recommendations for improvement to existing systems"
            ],
            levelC :[ 
                "Can analyze and improve the effectiveness of OHS documentation collection and file management processes. ",
                "Can communicate the importance of proper OHS document collection and file management to all employees in the organization. ",
                "Can design OHS document collection and file management processes that meet an organization's OHS needs. ",
                "Can innovate upon OHS document collection and file management processes to ensure compliance with OHS regulations"
            ]
        }]
    },
    {
        questionHeader: "505: Performance Monitoring and Measurement",
        questionText: "OHS professionals must establish and maintain procedures for evaluating and improving an organization's OHS performance.",
        natDesc: "Can establish and maintain procedures for evaluating and improving an organization's OHS performance.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can explain the different sources or modes of failure within a specific risk control. ",
                "Can follow qualitative or quantitative performance monitoring procedures. ",
                "Can explain different kinds of performance measures. ",
                "Can recognize the role of health surveillance and health assessment in performance monitoring and measurement"
            ],
            levelB :[
                "Can assess the effectiveness of an OHS policy, objective, or procedure through data gathered from performance monitoring. ",
                "Can communicate the results and interpretation of performance measurement to stakeholders. ",
                "Can implement and monitor a regular OHS performance monitoring process"
            ],
            levelC :[ 
                "Can analyze and evaluate current processes for monitoring OHS performance. ",
                "Can design OHS performance measures that are adaptable to changes in the context of an organization. ",
                "Can teach the principles of OHS performance monitoring and measurement to other OHS professionals"
            ]
        }]
    },
    {
        questionHeader: "506: Internal Auditing",
        questionText: "OHS professionals must conduct regular and comprehensive internal audits of policies, objectives, and procedures.",
        natDesc: "Can conduct regular and comprehensive internal audits of policies, objectives, and procedures.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the different types of OHSMS audits. ",
                "Can identify and communicate with other OHS professionals and experts about the findings and scores from a completed OHSMS audit. ",
                "Can recognize the different types of evidence used during OHSMS audits, such as documentation, observation, and interviews. ",
                "Can recognize the purpose of regular OHSMS audits"
            ],
            levelB :[
                "Can assess an organization's compliance with its auditing legal requirements and other requirements. ",
                "Can assess different sources of evidence during an OHSMS audit. ",
                "Can communicate audit findings to stakeholders. ",
                "Can conduct a documentation review. ",
                "Can conduct a gap analysis. ",
                "Can implement and monitor an audit using an audit workplan"
            ],
            levelC :[ 
                "Can design a post-audit action plan that identifies areas for improvement. ",
                "Can communicate audit findings and recommendations to management in a clear and concise manner. ",
                "Can design an audit workplan to ensure all necessary areas are covered during the audit process. ",
                "Can resolve any discrepancies or issues identified during the audit process to ensure compliance with OHS regulations. ",
                "Can teach OHS professionals about how to conduct an OHSMS audit and how to effectively communicate audit findings"
            ]
        }]
    },
    {
        questionHeader: "507: Continual Improvement",
        questionText: "OHS professionals must identify opportunities for improvement and make decisions related to changes in an organization's OHSMS.",
        natDesc: "Can identify opportunities for improvement and make decisions related to changes in an organization's OHSMS.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can follow established procedures for reporting and addressing any discrepancies or concerns about OHS policies, objectives, and procedures. ",
                "Can recognize how OHS policies, objectives, and procedures interact with worker behaviour. ",
                "Can review OHS policies, objectives, and procedures to ensure compliance with relevant legal requirements and other requirements"
            ],
            levelB :[
                "Can communicate with senior management about the importance of continuous improvement and its impact on the organization. ",
                "Can implement and maintain practices for the continuous improvement of an OHSMS"
            ],
            levelC :[ 
                "Can analyze an OHSMS for its compatibility with organizational goals across changes in the context of an organization. ",
                "Can innovate OHS policies, objectives, or procedures to adapt to changes in the context of the organization"
            ]
        }]
    },
    {
        questionHeader: "508: Quality Management",
        questionText: "OHS professionals must understand the fundamental concepts and principles of quality management.",
        natDesc: "Can understand the fundamental concepts and principles of quality management.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify interactions between an OHSMS and other workplace systems. ",
                "Can recognize the value of quality management when managing an OHSMS. ",
                "Can review different quality management resources and activities"
            ],
            levelB :[
                "Can communicate the benefits of quality management to stakeholders, emphasizing its role in preventing worker harm. ",
                "Can implement and monitor resources and activities for the quality management of an OHSMS. ",
                "Can research best practices in integrating quality management within OHS-related policies, objectives, and procedures"
            ],
            levelC :[ 
                "Can analyze the effectiveness of quality management practices for an OHSMS. ",
                "Can innovate upon quality management resources and processes to adapt to changes in the context of the organization"
            ]
        }]
    },
    {
        questionHeader: "601: Principles of Commercial Awareness",
        questionText: "OHS professionals must demonstrate commercial awareness.",
        natDesc: "Can demonstrate commercial awareness.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain concepts related to business organization, project management, and operations management. ",
                "Can explain different organizational structures. ",
                "Can identify different project planning practices. ",
                "Can identify different operations management practices"
            ],
            levelB :[
                "Can conduct the delivery of OHS resources across an organization. ",
                "Can communicate OHS considerations and concerns with stakeholders during project planning. ",
                "Can assess the relationship between a project plan, OHS concerns, and the organization to stakeholders"
            ],
            levelC :[ 
                "Can analyze a strategic plan for OHS concerns to identify areas for improvement. ",
                "Can communicate effectively with senior management about non-OHS organization policies, objectives, and procedures.. ",
                "Can resolve OHS issues by conducting thorough investigations and implementing corrective actions"
            ]
        }]
    },
    {
        questionHeader: "602: Corporate Governance",
        questionText: "OHS professionals must participate in structures and processes that control organizational decision making.",
        natDesc: "Can participate in structures and processes that control organizational decision making.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the rationale and application of different governance procedures. ",
                "Can follow the application of governance procedures including terms of reference and meeting rules. ",
                "Can recognize relevant legal requirements and other requirements that pertain to incorporating OHS within corporate governance"
            ],
            levelB :[
                "Can assess existing corporate governance structures and processes to identify potential hazards. ",
                "Can communicate effectively with stakeholders to address identified gaps in OHS governance policies. ",
                "Can implement and monitor OHS-relevant governance mechanisms"
            ],
            levelC :[ 
                "Can analyze an existing corporate governance structure or process to determine its effectiveness in preventing worker harm. ",
                "Can innovate corporate governance procedures to address changes in the context of an organization"
            ]
        }]
    },
    {
        questionHeader: "603: Organizational Risk",
        questionText: "OHS professionals must understand how organizational risk impacts policies, objectives, and procedures.",
        natDesc: "Can understand how organizational risk impacts policies, objectives, and procedures.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify different sources of organizational risk. ",
                "Can recognize how OHS objectives interact with organizational decision-making processes. ",
                "Can recognize how the context of an organization impacts levels of organizational risk tolerance. ",
                "Can recognize legal requirements and other requirements regarding acceptable levels of organizational risk"
            ],
            levelB :[
                "Can assess OHS decisions based on levels and severity of organizational risk. ",
                "Can communicate effectively with stakeholders regarding OHS policies and their impact on organizational risk"
            ],
            levelC :[ 
                "Can analyze the impact of OHS decisions in producing or mitigating organizational risk. ",
                "Can design effective OHS policies and procedures that reduce organizational risk and promote a safe work environment"
            ]
        }]
    },
    {
        questionHeader: "604: Strategic Plans",
        questionText: "OHS professionals must integrate OHS considerations into the overall business strategy.",
        natDesc: "Can integrate OHS considerations into the overall business strategy.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the key components of a strategic plan. ",
                "Can identify the various resources and practices involved in developing a strategic plan. ",
                "Can recognize how day-to-day operations within an organization can impact the success of a strategic plan. ",
                "Can recognize the importance of strategic planning"
            ],
            levelB :[
                "Can develop and implement specific OHS concepts into overall strategic planning and communicate these effectively with stakeholders. ",
                "Can implement and monitor OHS objectives within day-to-day operations"
            ],
            levelC :[ 
                "Can analyze and evaluate OHS strategies using key performance indicators and by setting realistic objectives. ",
                "Can design new OHS strategies that are adaptable to changes in the organizational context. ",
                "Can resolve complex issues related to integrating OHS strategies within organizational objectives"
            ]
        }]
    },
    {
        questionHeader: "605: Human Resource Management",
        questionText: "OHS professionals must work with an HRM and understand its concepts.",
        natDesc: "Can work with an HRM and understand its concepts.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain how the principles of human resource management are related to OHS. ",
                "Can recognize how worker accommodations  can be integrated within an OHSMS. ",
                "Can recognize the importance of soft skills in the workplace"
            ],
            levelB :[
                "Can communicate effectively with stakeholders about applicants during job interviews or onboarding. ",
                "Can develop and implement soft skills such as active listening and empathy to promote a positive safety culture. ",
                "Can implement and monitor processes for maintaining worker documentation"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of collaboration between OHS and HRM. ",
                "Can innovate upon workforce onboarding or work modification processes to make them more effective"
            ]
        }]
    },
    {
        questionHeader: "606: Worker Representation",
        questionText: "OHS professionals must facilitate discussion and negotiation when resolving worker safety concerns.",
        natDesc: "Can facilitate discussion and negotiation when resolving worker safety concerns.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can communicate the elements of a JHSC meeting. ",
                "Can explain the differences between a JHSC and an HSR. ",
                "Can follow governance procedures at JHSC meetings. ",
                "Can recognize the legal requirements and other requirements that pertain to worker participation within OHS"
            ],
            levelB :[
                "Can assess the state of a JHSC or HSR for conformance to legal requirements and other requirements. ",
                "Can conduct consulting duties for worker representatives. ",
                "Can implement and monitor training programs for worker representatives. ",
                "Can research and evaluate best practices for communicating OHS information to worker representatives"
            ],
            levelC :[ 
                "Can analyze and assess the effectiveness of worker representation within OHS. ",
                "Can design policies and procedures that promote worker representation within OHS . ",
                "Can resolve and prevent conflicts problems related to OHS by leading or chairing a JHSC. ",
                "Can design and teach worker representatives courses about OHS principles and best practices"
            ]
        }]
    },
    {
        questionHeader: "607: Budgeting and OHS",
        questionText: "OHS professionals must estimate costs and work within budgetary constraints to prevent worker harm efficiently.",
        natDesc: "Can estimate costs and work within budgetary constraints to prevent worker harm efficiently.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain how to interpret budgets. ",
                "Can identify different types of financial statements. ",
                "Can recognize resources and activities that are involved in creating and managing budgets. ",
                "Can review important financial metrics"
            ],
            levelB :[
                "Can assess an OHS initiative's performance using its budget. ",
                "Can communicate the return on investment of an OHS initiative to stakeholders. ",
                "Can implement and monitor a budget for an OHS initiative"
            ],
            levelC :[ 
                "Can analyze the context of the organization to develop feasible budgets for OHS initiatives. ",
                "Can analyze the budgetary performance of an OHS initiative to ensure its successful implementation"
            ]
        }]
    },
    {
        questionHeader: "608: Corporate Social Responsibility",
        questionText: "OHS professionals must develop proactive strategies for promoting worker health and corporate social responsibility.",
        natDesc: "Can develop proactive strategies for promoting worker health and corporate social responsibility.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can identify the benefits of implementing sustainable business practices. ",
                "Can identify practices to reduce an organization's environmental footprint. ",
                "Can recognize the legal requirements and other requirements related to an organization's commitments to external stakeholders"
            ],
            levelB :[
                "Can assess the impact of company decisions on different populations and the environment. ",
                "Can communicate corporate social responsibility principles to stakeholders. ",
                "Can implement and monitor the principles of corporate social responsibility within different OHS activities"
            ],
            levelC :[ 
                "Can analyze the effectiveness of existing OHS policies and programs to ensure compliance with corporate social responsibility requirements. ",
                "Can design new strategies and initiatives to promote worker health and corporate social responsibility within an organization"
            ]
        }]
    },
    {
        questionHeader: "701: Legislation",
        questionText: "OHS professionals must interpret and apply legislation related to workplace health and safety.",
        natDesc: "Can interpret and apply legislation related to workplace health and safety.",
        suggested: "C",
        answerText: [{
            levelA :[
                "Can communicate effectively with workers about their rights and responsibilities under OHS legislation. ",
                "Can explain the process for filing a complaint under employment standards legislation. ",
                "Can recognize potential hazards in the workplace and take appropriate action to mitigate them"
            ],
            levelB :[
                "Can communicate with stakeholders about the key elements and relevance of OHS legislation. ",
                "Can implement regular reviews of OHS policies, objectives, and procedures in response to changes in legal requirements. ",
                "Can conduct regular reviews to promote organizational understanding of its legal requirements"
            ],
            levelC :[ 
                "Can analyze existing OHS policies, objectives, and procedures for compliance with legal requirements. ",
                "Can design workplace practices for continually ensuring conformity with legal requirements. ",
                "Can resolve issues with legal compliance by developing and implementing corrective action plans. ",
                "Can teach OHS professionals how to apply legal requirements to their workplace and develop effective compliance strategies"
            ]
        }]
    },
    {
        questionHeader: "702: Ethics and Conduct",
        questionText: "OHS professionals must uphold ethical obligations and promote high standards of professionalism.",
        natDesc: "Can uphold ethical obligations and promote high standards of professionalism.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify the rights, responsibilities, and accountabilities of OHS professionals. ",
                "Can communicate potential conflicts of interest involving oneself to other OHS professionals and experts. ",
                "Can recognize the impact of whistleblowing on an organization. ",
                "Can recognize the legal requirements and other requirements for an OHS professional"
            ],
            levelB :[
                "Can assess the severity of a conflict of interest. ",
                "Can implement and maintain procedures for ensuring confidentiality. ",
                "Can conduct oneself with impartiality and collegiality"
            ],
            levelC :[ 
                "Can design disciplinary processes that are fair and well-founded. ",
                "Can teach ethical conduct to other OHS professionals. ",
                "Can innovate policies, objectives, or procedures to effectively resolve conflicts of interest. ",
                "Can innovate upon professional codes of conduct to better align with changes in the context of an organization. ",
                "Can resolve ethical dilemmas in a professional and ethical manner"
            ]
        }]
    },
    {
        questionHeader: "703: Effective Communication",
        questionText: "OHS professionals must communicate effectively with non-professionals and work collaboratively with others.",
        natDesc: "Can communicate effectively with non-professionals and work collaboratively with others.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify communication barriers between different cultural or language groups. ",
                "Can recognize appropriate forms of communication for different contexts or messages. ",
                "Can recognize non-verbal communication cues and their meanings. ",
                "Can recognize the differences between technical and non-technical conversations. ",
                "Can review strategies for effective oral communication in presentations, meetings, and conversations"
            ],
            levelB :[
                "Can assess current communication strategies to identify areas for improvement. ",
                "Can communicate technical and non-technical issues and ideas to various audiences using clear and concise language. ",
                "Can develop and deliver presentations on OHS policies, objectives, and procedures using plain, audience-appropriate language. ",
                "Can research best practices for effective communication within the context of OHS"
            ],
            levelC :[ 
                "Can design communication processes to adapt to changes in the context of the organization. ",
                "Can evaluate and analyze previous records of communication to identify areas of excellence and areas for improvement. ",
                "Can teach OHS professionals about how to write audience-appropriate documents about OHS concepts"
            ]
        }]
    },
    {
        questionHeader: "704: Decision-Making",
        questionText: "OHS professionals must apply decision-making theories, consider organizational decisions, and communicate their rationale effectively.",
        natDesc: "Can apply decision-making theories, consider organizational decisions, and communicate their rationale effectively.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify relevant sources of data related to OHS decision making. ",
                "Can recognize the importance of incorporating data within decision making"
            ],
            levelB :[
                "Can assess the quality and reliability of evidence and data gathered to support decision-making. ",
                "Can conduct stakeholder consultations to gather input and perspectives for decision-making. ",
                "Can implement and monitor OHS decision-making models. ",
                "Can research and analyze industry trends and best practices in OHS decision-making"
            ],
            levelC :[ 
                "Can analyze raw or academic research data to inform decision-making. ",
                "Can resolve complex research questions to deliver decisions to an organization. ",
                "Can teach decision-making models and theories to other OHS professionals"
            ]
        }]
    },
    {
        questionHeader: "705: Problem Solving",
        questionText: "OHS professionals must utilize resources and activities to promptly resolve new and intricate OHS issues in the workplace.",
        natDesc: "Can utilize resources and activities to promptly resolve new and intricate OHS issues in the workplace.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain potential problems in a workplace that emerge between people, organizational objectives, and the environment. ",
                "Can identify surface-cause and root-cause problems related to a specific workplace issue. ",
                "Can recognize various problem-solving resources and processes"
            ],
            levelB :[
                "Can assess a current OHS problem for similarities with former, solved OHS problems. ",
                "Can implement and monitor problem-solving resources and processes to address non-complex OHS problems. ",
                "Can research additional information and evidence when problem solving"
            ],
            levelC :[ 
                "Can develop policies, objectives, and procedures that address and prevent the recurrence of identified problems. ",
                "Can innovate upon existing resources and practices to address complex OHS problems. ",
                "Can teach others OHS professionals about problem-solving within the context of OHS"
            ]
        }]
    },
    {
        questionHeader: "706: Project Management",
        questionText: "OHS professionals must manage and document processes and resources, monitor for continual improvement, and prioritize tasks.",
        natDesc: "Can manage and document processes and resources, monitor for continual improvement, and prioritize tasks.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can follow project timelines and deadlines. ",
                "Can identify different project management resources and practices. ",
                "Can recognize the key stakeholders involved in a project. ",
                "Can review the components of a project budget"
            ],
            levelB :[
                "Can communicate project updates to stakeholders through regular meetings and progress reports. ",
                "Can implement and monitor a project plan throughout all stages, including implementation and continual improvement"
            ],
            levelC :[ 
                "Can analyze data to optimize project delivery. ",
                "Can innovate upon a project plan to incorporate improvements. ",
                "Can resolve problems related to project scope, budget, and time constraints. ",
                "Can teach effective project management processes to other OHS professionals"
            ]
        }]
    },
    {
        questionHeader: "707: Work Relationship Management",
        questionText: "OHS professionals must build and maintain relationships with stakeholders.",
        natDesc: "Can build and maintain relationships with stakeholders.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate effectively with workers and senior management regarding OHS concerns and incidents. ",
                "Can explain how the various stakeholder groups involved with OHS activities impact OHS objectives and performance. ",
                "Can follow established ethical standards when addressing stakeholder concerns with OHS processes"
            ],
            levelB :[
                "Can assess how OHS activities impact the organizational culture on OHS. ",
                "Can assess the interests and values of the stakeholder groups involved with an OHS activity. ",
                "Can communicate effectively with both internal and external stakeholders about OHS processes and initiatives"
            ],
            levelC :[ 
                "Can analyze stakeholder feedback to inform decision making. ",
                "Can design messaging strategies for different stakeholder groups. ",
                "Can innovate upon current practices to address gaps in stakeholder engagement. ",
                "Can resolve complex problems between stakeholder groups through effective negotiation"
            ]
        }]
    },
    {
        questionHeader: "708: Professional Development",
        questionText: "OHS professionals must identify and address potential sources of worker harm.",
        natDesc: "Can identify and address potential sources of worker harm.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can follow general guidance for OHS professional development. ",
                "Can identify personal and professional objectives. ",
                "Can recognize the value of professional networks. ",
                "Can communicate with other OHS professionals and experts about one's own professional development progress"
            ],
            levelB :[
                "Can assess one's own professional development needs with existing resources and processes. ",
                "Can conduct an independent learning process. ",
                "Can implement and monitor a personal professional development plan"
            ],
            levelC :[ 
                "Can analyze and evaluate the impact of a professional development plan. ",
                "Can design OHS professional development programs for others that align with organizational and personal objectives. ",
                "Can teach other OHS professionals how to develop an effective personal professional development plan for themselves"
            ]
        }]
    },
    {
        questionHeader: "801: Hazardous Energy",
        questionText: "OHS professionals must identify and control the risk presented by hazardous energy.",
        natDesc: "Can identify and control the risk presented by hazardous energy.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify different lockout devices. ",
                "Can recognize legal requirements and other requirements that pertain to hazardous energy control. ",
                "Can recognize machinery, equipment, or processes that produce hazardous energy. ",
                "Can recognize the importance of a hazardous energy control program"
            ],
            levelB :[
                "Can assess a workplace for the risk of hazardous energy release. ",
                "Can develop training materials and conduct training sessions for workers to participate in a hazardous energy control program. ",
                "Can implement and monitor a hazardous energy control program. ",
                "Can implement and monitor a lockout program. ",
                "Can research and stay up to date on the best practices for managing hazardous energy sources"
            ],
            levelC :[ 
                "Can analyze and improve the effectiveness of existing OHS policies and procedures in preventing the hazardous release of energy. ",
                "Can design a comprehensive lockout program to address new or emerging hazardous energy sources. ",
                "Can innovate new hazardous energy control programs in response to changes in the context of an organization. ",
                "Can teach OHS professionals the fundamentals of risk assessment for hazardous energies"
            ]
        }]
    },
    {
        questionHeader: "802: Safeguarding Machinery and Equipment",
        questionText: "OHS professionals must identify potential hazards and implement appropriate safeguards to prevent incidents and injuries.",
        natDesc: "Can identify potential hazards and implement appropriate safeguards to prevent incidents and injuries.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about hazards requiring safeguarding. ",
                "Can identify different types of safeguards, such as adjustable guards, fixed guards, and self-closing guards. ",
                "Can recognize legal requirements and other requirements that pertain to safeguarding equipment and machinery"
            ],
            levelB :[
                "Can assess the effectiveness of current safeguards. ",
                "Can communicate effectively with stakeholders about policies and procedures related to safeguarding. ",
                "Can implement and monitor safeguards in line with the hierarchy of controls. ",
                "Can research and stay up to date on the best practices in safeguarding"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing policies and procedures for safeguarding. ",
                "Can design safeguards that are effective and efficient in preventing workplace accidents and injuries. ",
                "Can innovate policies and procedures in response to changes in context of the organization. ",
                "Can teach others how to develop policies and procedures for safeguarding"
            ]
        }]
    },
    {
        questionHeader: "803: Electrical Systems",
        questionText: "OHS professionals must identify hazards and implement risk controls to avoid harm to workers caused by electricity.",
        natDesc: "Can identify hazards and implement risk controls to avoid harm to workers caused by electricity.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the proper safety practices when using power tools. ",
                "Can identify and discuss electrical safety hazards in the workplace with other OHS professionals and experts. ",
                "Can recognize the different types of harm resulting from electrical currents. ",
                "Can recognize the relevant legal requirements and other requirements that pertain to working with electrical systems"
            ],
            levelB :[
                "Can assess workplaces for improperly controlled electrical hazards and risks. ",
                "Can develop and conduct training programs for OHS policies and procedures related to working with electrical systems. ",
                "Can implement and monitor safety procedures and controls for electrical equipment. ",
                "Can research best practices for electrical safety"
            ],
            levelC :[ 
                "Can analyze data on workplace incidents related to electrical safety and use it to identify areas for improvement in existing policies and procedures. ",
                "Can communicate effectively with stakeholders to ensure that electrical safety policies and procedures are understood and followed. ",
                "Can design and implement training programs in electrical safety that incorporates interactive and hands-on learning methods. ",
                "Can innovate OHS policies and procedures for electrical safety in response to newly introduced or previously identified hazards. ",
                "Can innovate OHS policies and procedures to integrate the latest advancements in electrical safety technology"
            ]
        }]
    },
    {
        questionHeader: "804: Welding and Allied Processes",
        questionText: "OHS professionals must communicate knowledge effectively to people who perform welding and similar activities.",
        natDesc: "Can communicate knowledge effectively to people who perform welding and similar activities.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify appropriate risk controls for work involving welding or an allied process. ",
                "Can recognize chemical hazards associated with welding and allied processes, such as toxic fumes, vapours, gases, and particulate. ",
                "Can recognize the legal requirements and other requirements that pertain to welding and allied processes. ",
                "Can recognize the physical hazards associated with welding and allied processes, such as fire, radiation, and electric shock"
            ],
            levelB :[
                "Can assess the risks presented by welding or an allied process. ",
                "Can develop training programs for safe processes in welding and allied processes. ",
                "Can implement and monitor safety systems for welding and allied processes. ",
                "Can research and stay up to date on the best practices for safety during welding and allied processes"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing policies and procedures in promoting safety during welding and allied processes. ",
                "Can communicate and resolve any issues or concerns related to safety during welding and allied processes. ",
                "Can innovate OHS policies and procedures for welding and allied processes in response to changes in context of the organization. ",
                "Can teach OHS professionals about the fundamentals of safe processes for welding and allied processes"
            ]
        }]
    },
    {
        questionHeader: "805: Chemical Agents and Biological Agents",
        questionText: "OHS professionals must collaborate with specialists in managing chemical and biological agents.",
        natDesc: "Can collaborate with specialists in managing chemical and biological agents.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can explain the key principles about chemical and biological agent hazards, such as systems of classification and methods of exposure. ",
                "Can identify chemical and biological agents in a workplace and their associated controls. ",
                "Can identify sources of safety information on chemical and biological agents. ",
                "Can follow relevant legal requirements and other requirements pertaining to chemical and biological agents in a workplace"
            ],
            levelB :[
                "Can assess the risk associated with working with chemical and biological agents. ",
                "Can implement and monitor safety procedures for working with chemical and biological agents. ",
                "Can research and stay up to date on the best practices for working with chemical and biological agents"
            ],
            levelC :[ 
                "Can analyze the effectiveness of existing policies and procedures in promoting safe work processes with chemical and biological agents. ",
                "Can design a comprehensive training program for safely working with chemical and biological agents. ",
                "Can innovate policies and procedures for working with chemical and biological agents in response to the introduction of new agents. ",
                "Can teach others about the fundamentals of managing chemical and biological agents"
            ]
        }]
    },
    {
        questionHeader: "806: Combustible Dust",
        questionText: "OHS professionals must identify and manage potential hazards associated with combustible dust in their workplace.",
        natDesc: "Can identify and manage potential hazards associated with combustible dust in their workplace.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain effective controls for combustible dust safety in the workplace. ",
                "Can explain workplace events that may produce a dust explosion, such as through the dust explosion pentagon. ",
                "Can recognize the importance of thorough inspections and proper storage for combustible dust safety. ",
                "Can recognize the relevant legal requirements and other requirements that pertain to combustible dusts"
            ],
            levelB :[
                "Can assess workplace hazards related to combustible dust. ",
                "Can communicate the importance of following policies and procedures related to combustible dust hazards to all stakeholders. ",
                "Can implement and monitor risk controls to mitigate the risks associated with combustible dust hazards. ",
                "Can research and stay up to date on the best practices for assessing the risk of combustible dust"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing policies and procedures for combustible dust safety. ",
                "Can design and implement comprehensive training programs on combustible dust safety. ",
                "Can innovate policies and procedures for combustible dust safety in response to identified and emerging hazards. ",
                "Can teach others about the fundamentals of combustible dust safety"
            ]
        }]
    },
    {
        questionHeader: "807: Work in Confined Spaces",
        questionText: "OHS professionals must establish processes for safely working in confined spaces.",
        natDesc: "Can establish processes for safely working in confined spaces.",
        suggested: "B",
        answerText: [{
            levelA :[
               "Can communicate the necessary safety measures and precautions to be taken before entering a confined space. ",
               "Can follow existing safety procedures used in confined spaces. ",
               "Can identify the potential hazards and risks associated with a confined space. ",
               "Can recognize the legal requirements and other requirements related to working in confined spaces. ",
               "Can recognize the roles and responsibilities of senior management, the entry team, and emergency response team"
            ],
            levelB :[
               "Can assess a workplace for risks presented by confined spaces. ",
                "Can conduct atmospheric testing within confined spaces. ",
                "Can develop and implement a communication plan to inform stakeholders about policies and procedures related to confined spaces. ",
                "Can implement and monitor entry and egress procedures. ",
                "Can research and stay up to date on the best practices for working in confined spaces"
            ],
            levelC :[ 
               "Can analyze the effectiveness of existing policies and procedures for confined space safety and recommend improvements. ",
                "Can design a comprehensive training program on confined space safety. ",
                "Can innovate policies and procedures for confined space safety in response to new and emerging hazards. ",
                "Can teach OHS professionals about the fundamentals of managing confined space safety and provide practical examples"
            ]
        }]
    },
    {
        questionHeader: "808: Work at Heights",
        questionText: "OHS professionals must establish safe work processes for work at heights.",
        natDesc: "Can establish safe work processes for work at heights.",
        suggested: "B",
        answerText: [{
            levelA :[
               "Can recognize the relevant legal requirements and other requirements that pertain to working at heights. ",
                "Can identify hazards presented by working at heights. ",
                "Can recognize the importance of the proper selection and use of fall protection systems when working at heights. ",
                "Can review risk controls for working at heights, such as guardrails, control zones, and safety monitors"
            ],
            levelB :[
               "Can assess the risk presented by working at heights. ",
                "Can communicate the importance of safety during work at heights to all stakeholders. ",
                "Can develop and deliver training for policies and procedures in working at heights. ",
                "Can implement and monitor risk controls for working at heights. ",
                "Can research and stay up to date on the best practices for working at heights"
            ],
            levelC :[ 
               "Can analyze and evaluate the effectiveness of existing OHS policies and procedures for work at heights. ",
                "Can design a comprehensive training program for safety during work at heights. ",
                "Can innovate OHS policies and procedures for work at heights processes in response to changes in context of the organization. ",
                "Can teach OHS professionals about the fundamentals of implementing fall protection"
            ]
        }]
    },
    {
        questionHeader:  "809: Mobile Equipment",
        questionText: "OHS professionals must develop safety controls specific to the equipment used in their workplace.",
        natDesc: "Can develop safety controls specific to the equipment used in their workplace.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the operating principles of commonly used mobile equipment. ",
                "Can follow the proper use of mobile equipment attachments and potential hazards associated with these attachments. ",
                "Can identify the different types of mobile equipment in the workplace and their associated hazards. ",
                "Can recognize relevant legal requirements and other requirements that pertain to mobile equipment safety. ",
                "Can review risk controls for mobile equipment"
            ],
            levelB :[
                "Can assess the risk presented by specific pieces of mobile equipment. ",
                "Can develop training materials and programs for the safe use of mobile equipment. ",
                "Can research and stay up to date on the best practices for safely using mobile equipment"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing OHS policies and procedures in preventing hazardous events involving mobile equipment. ",
                "Can design comprehensive training programs in the safe operation of mobile equipment. ",
                "Can innovate OHS policies and procedures in response to the introduction of new mobile equipment. ",
                "Can teach OHS professionals about the fundamentals of mobile equipment safety management"
            ]
        }]
    },
    {
        questionHeader: "810: Transportation of Dangerous Goods",
        questionText: "OHS professionals must advise on the handling, transportation, storage, and disposal of dangerous goods.",
        natDesc: "Can advise on the handling, transportation, storage, and disposal of dangerous goods.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can explain the purpose and structure of WHMIS and GHS. ",
                "Can follow relevant legal requirements and other requirements that pertain to the transportation of dangerous goods. ",
                "Can recognize dangerous goods in use at a workplace. ",
                "Can review pictograms, labels, and safety data sheets for dangerous goods"
            ],
            levelB :[
                "Can assess the level of risk presented by the transport of a specific dangerous good. ",
                "Can develop training materials and conduct training sessions for policies and procedures for the transport of dangerous goods. ",
                "Can implement and monitor OHS policies and procedures for the transport of dangerous goods. ",
                "Can research and stay up to date on the best practices for the transport of dangerous goods"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing policies and procedures for the transport of dangerous goods. ",
                "Can innovate new approaches to comprehensive training programs for safely transporting dangerous goods. ",
                "Can innovate OHS policies and procedures in response to new or emerging dangerous goods hazards. ",
                "Can teach others OHS professionals about the fundamentals of safety management for dangerous goods"
            ]
        }]
    },
    {
        questionHeader: "811: Shipping and Receiving",
        questionText: "OHS professionals must provide guidance on creating and maintaining a safe working environment for shipping and receiving operations.",
        natDesc: "Can provide guidance on creating and maintaining a safe working environment for shipping and receiving operations.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can identify controls for shipping and receiving risks in the workplace. ",
                "Can recognize legal requirements and other requirements that pertain to shipping and receiving safety. ",
                "Can recognize shipping and receiving hazards in a workplace"
            ],
            levelB :[
                "Can assess the risks presented by shipping and receiving. ",
                "Can develop and conduct training programs for safe shipping and receiving. ",
                "Can implement and monitor OHS policy and procedure for shipping and handling. ",
                "Can research and stay up to date on the best practices for addressing shipping and receiving"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of current policies and procedures in promoting safe shipping and receiving processes. ",
                "Can innovate policies and procedures for shipping and receiving safety in response to changes in context of the organization. ",
                "Can teach OHS professionals about the fundamentals of shipping and receiving safety management"
            ]
        }]
    },
    {
        questionHeader: "812: Contractor Health and Safety",
        questionText: "OHS professionals must develop and implement an OHSMS for contractors.",
        natDesc: "Can develop and implement an OHSMS for contractors.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about weaknesses in contractor safety management processes. ",
                "Can explain the resources and practices involved in contractor management. ",
                "Can recognize how contractor safety management integrates with an OHSMS. ",
                "Can recognize the relevant legal requirements and other requirements that pertain to contractor safety"
            ],
            levelB :[
                "Can assess contractor compliance with OHS policies and procedures. ",
                "Can assess the adherence of contractors to workplace safety measures and procedures. ",
                "Can develop onboarding and refreshing programs for contractors on an organization's OHS policies and procedures, and workplace safety measures and procedures. ",
                "Can research and stay up to date on the best practices for contractor management and workplace safety"
            ],
            levelC :[ 
                "Can analyze incident reports and identify trends to improve contractor safety performance. ",
                "Can design interactive and engaging training modules to enhance contractor safety awareness. ",
                "Can innovate policies and procedures processes in response to changes in contractor relationships. ",
                "Can resolve OHS-relevant conflicts between contractors and internal stakeholders, such as workers and senior management, through effective communication and negotiation. ",
                "Can teach others OHS professionals about the fundamentals of contractor management"
            ]
        }]
    },
    {
        questionHeader: "813: Smart Manufacturing",
        questionText: "OHS professionals must manage and control the risks introduced by technology and automation.",
        natDesc: "Can manage and control the risks introduced by technology and automation.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can communicate hazards involving smart manufacturing technologies, including the potential for malicious attacks, to other OHS professionals and experts. ",
                "Can follow OHS-relevant cyber security standards and guidelines. ",
                "Can identify smart manufacturing technologies and associated hazards. ",
                "Can recognize the relevant legal requirements and other requirements that pertain to smart manufacturing"
            ],
            levelB :[
                "Can communicate the significance of cyber security in the smart manufacturing workplace to stakeholders. ",
                "Can implement and monitor expert advice to promote safety in smart manufacturing. ",
                "Can research and stay up to date on the best practices for managing safety during smart manufacturing"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing OHS policies and procedures for smart manufacturing. ",
                "Can design and implement comprehensive training programs for safe work in smart manufacturing. ",
                "Can innovate policies and procedures for cyber security in response to the introduction of new technologies. ",
                "Can resolve any issues related to worker safety in smart manufacturing by implementing new policies and procedures"
            ]
        }]
    },
    {
        questionHeader: "814: Ergonomics",
        questionText: "OHS professionals must identify musculoskeletal injury hazards and prevent worker harm.",
        natDesc: "Can identify musculoskeletal injury hazards and prevent worker harm.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about preventing and treating musculoskeletal injuries in the workplace. ",
                "Can follow advice from ergonomists to reduce the risk of harm. ",
                "Can identify the signs and symptoms of musculoskeletal injuries. ",
                "Can recognize the relevant legal requirements and other requirements that pertain to ergonomics. ",
                "Can recognize workplace scenarios that present musculoskeletal hazards"
            ],
            levelB :[
                "Can assess the risk presented by musculoskeletal injury hazards. ",
                "Can conduct an ergonomic assessment to identify potential hazards and risks. ",
                "Can develop and implement training programs for workers on proper ergonomic work processes. ",
                "Can implement and monitor ergonomic solutions to address identified risks. ",
                "Can research and stay up to date on the best practices for preventing musculoskeletal injuries"
            ],
            levelC :[ 
                "Can analyze the effectiveness of existing policies and procedures in promoting ergonomic safety. ",
                "Can design and develop comprehensive training programs in OHS policies and procedures for ergonomic safety. ",
                "Can innovate policies and procedures for ergonomic safety in response to newly introduced or previously identified hazards. ",
                "Can teach others OHS professionals about the fundamentals of risk assessment for musculoskeletal injury hazards"
            ]
        }]
    },
    {
        questionHeader: "815: Occupational Hygiene",
        questionText: "OHS professionals must prevent occupational diseases by understanding workplace factors.",
        natDesc: "Can prevent occupational diseases by understanding workplace factors.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about instances of occupational disease. ",
                "Can identify sources of physical, chemical, and biological hazards in the workplace. ",
                "Can recognize relevant legal requirements and other requirements that pertain to occupational hygiene"
            ],
            levelB :[
                "Can assess the risk of occupational disease within a workplace. ",
                "Can communicate effectively with stakeholders regarding policies and procedures related to occupational hygiene hazards and the importance of implementing them. ",
                "Can implement and monitor control measures to minimize occupational hygiene hazards. ",
                "Can research best practices in occupational hygiene"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing policies and procedures in promoting occupational hygiene, and innovate new strategies as needed. ",
                "Can design and implement comprehensive training programs to prevent occupational diseases. ",
                "Can innovate occupational hygiene management processes in response to changes in the context of the organization"
            ]
        }]
    },
    {
        questionHeader: "816: Cranes and Hoists",
        questionText: "OHS professionals must properly assess and manage hazards associated with cranes and hoists.",
        natDesc: "Can properly assess and manage hazards associated with cranes and hoists.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can explain capacity ratings and load charts. ",
                "Can identify hazards related to using cranes and hoists. ",
                "Can recognize legal requirements and other requirements that pertain to cranes and hoists"
            ],
            levelB :[
                "Can assess the risks presented by using cranes and hoists. ",
                "Can develop and conduct training programs for the safe use of cranes and hoists. ",
                "Can implement and monitor OHS policies and procedures for cranes and hoists. ",
                "Can research and stay up to date on the best practices for using cranes and hoists"
            ],
            levelC :[ 
                "Can analyze the effectiveness of existing policies and procedures in promoting the safe operation of cranes and hoists. ",
                "Can innovate OHS policies and procedures for cranes and hoists in response to the introduction of new equipment. ",
                "Can teach others OHS professionals about the fundamentals of cranes and hoists safety management"
            ]
        }]
    },
    {
        questionHeader: "817: Ionizing Radiation",
        questionText: "OHS professionals must identify ionizing radiation-emitting devices and develop control measures to ensure a safe work environment.",
        natDesc: "Can identify ionizing radiation-emitting devices and develop control measures to ensure a safe work environment.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can follow procedures for handling sources of ionizing radiation. ",
                "Can identify sources of ionizing radiation in the workplace. ",
                "Can recognize legal requirements and other requirements that pertain to ionizing radiation. ",
                "Can review hazards or hazardous events involving ionizing radiation exposure with other OHS professionals and experts"
            ],
            levelB :[
                "Can assess workplace hazards presented by sources of ionizing radiation. ",
                "Can develop and conduct training in ionizing radiation safety. ",
                "Can evaluate the effectiveness of ionizing radiation safety training. ",
                "Can implement and monitor safety procedures for ionizing radiation. ",
                "Can research and stay up to date on the best practices for addressing ionizing radiation"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of existing policies and procedures in promoting ionizing radiation safety. ",
                "Can design and implement a comprehensive training program in ionizing radiation safety. ",
                "Can innovate policies and procedures for ionizing radiation safety management processes in response to changes in context of the organization"
            ]
        }]
    },
    {
        questionHeader: "818: Non-ionizing Radiation",
        questionText: "OHS professionals must identify instances of worker exposure and implement measures to control hazards of non-ionizing radiation.",
        natDesc: "Can identify instances of worker exposure and implement measures to control hazards of non-ionizing radiation.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can communicate with other OHS professionals and experts about hazards and hazardous events involving non-ionizing radiation exposure. ",
                "Can follow procedures for handling sources of non-ionizing radiation. ",
                "Can identify sources of non-ionizing radiation in the workplace. ",
                "Can recognize legal requirements and other requirements that pertain to non-ionizing radiation"
            ],
            levelB :[
                "Can assess workplace hazards presented by sources of non-ionizing radiation. ",
                "Can communicate the importance of ionizing radiation safety to stakeholders and provide information on policies and procedures. ",
                "Can develop and conduct training sessions on non-ionizing radiation safety. ",
                "Can implement and monitor safety procedures for non-ionizing radiation. ",
                "Can research and stay up to date on the best practices for addressing non-ionizing radiation"
            ],
            levelC :[ 
                "Can analyze the effectiveness of existing policies and procedures in promoting non-ionizing radiation safety. ",
                "Can innovate comprehensive training programs in non-ionizing radiation safety. ",
                "Can innovate policies and procedures for non-ionizing radiation safety management processes in response to changes in context of the organization"
            ]
        }]
    },
    {
        questionHeader: "819: Supply Chains",
        questionText: "OHS professionals must navigate an organization's supply chain to ensure alignment with OHS objectives.",
        natDesc: "Can navigate an organization's supply chain to ensure alignment with OHS objectives.",
        suggested: "A",
        answerText: [{
            levelA :[
                "Can identify effective controls for supply chain safety in the workplace. ",
                "Can recognize legal requirements and other requirements pertaining to supply chain safety. ",
                "Can recognize supply chain safety hazards and risks in a workplace. ",
                "Can review and discuss supply chain safety hazards or issues with other OHS professionals and experts"
            ],
            levelB :[
                "Can communicate policies and procedures related to safety along the supply chain to stakeholders. ",
                "Can implement training in OHS policies and procedures for organizations along the supply chain. ",
                "Can research the developing relationship between OHS and supply chain management"
            ],
            levelC :[ 
                "Can analyze and evaluate an existing supply chain to identify strengths and opportunities to promote safety along the supply chain. ",
                "Can communicate the importance of supply chain safety to stakeholders and team members. ",
                "Can design policies and procedures that promote safety across the supply chain. ",
                "Can teach OHS professionals about the fundamentals of OHS objectives and supply chain management"
            ]
        }]
    },
    {
        questionHeader: "820: Lone Workers",
        questionText: "OHS professionals must support workers who are isolated in their workplace.",
        natDesc: "Can support workers who are isolated in their workplace.",
        suggested: "B",
        answerText: [{
            levelA :[
                "Can communicate effectively with team members during a crisis involving a lone worker . ",
                "Can recognize legal requirements and other requirements that pertain to lone workers. ",
                "Can recognize the hazards associated with working alone. ",
                "Can review safety protocols for working in confined spaces"
            ],
            levelB :[
                "Can assess the risks presented by working alone. ",
                "Can develop and deliver effective training programs for lone workers. ",
                "Can implement and monitor OHS policies and procedures for working alone. ",
                "Can research and stay up to date on the best practices for addressing working alone"
            ],
            levelC :[ 
                "Can analyze and evaluate the effectiveness of current policies and procedures for lone workers and innovate new ones to improve safety. ",
                "Can innovate policies and procedures for lone workers in response to changes in the context of the organization. ",
                "Can resolve any issues or concerns related to the safety of lone workers by communicating with relevant stakeholders and designing solutions. ",
                "Can teach others about managing the safety of lone workers and communicate the importance of implementing effective policies and procedures"
            ]
        }]
    }
];

export default data;