import React, { useRef, useState, useEffect } from 'react';
import './dashboard.css';
import { Container, Row, Col, Card, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Slider from "react-slick";
import CustomerHeader from '../header/CustomerHeader';
import Footer from '../footer/footer';
import { useNavigate, useLocation } from "react-router-dom";
import questions from './questions.json';
import answers from './answers.json';
import header from './header.json';
import title from './title.json';
import titleDesc from './titleDesc.json';
import description from './description.json';
import keyPoints from './keyPoints.json';
import { ToastContainer } from 'react-toastify';
import { getCatSurvey } from "../../services/userServices";
import suggestedLevels from './suggested.json';
import { quesNumbers, slideColours } from '../utils/utils';

const DashboardComponent = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [show, setShow] = useState(false);

    const handleClickReadNow = (value) => {
        navigate("/description", { state: { value } });
    }

    useEffect(() => {
        if (location.state === null) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [location.state]);

    // Reference to the Slider component
    const slideRefs = {
        0: useRef(null),
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null),
        5: useRef(null),
        6: useRef(null),
        7: useRef(null),
    }

    // Previous slide function
    // Call the slickPrev method on the slider reference
    const prevSlides = {
        0: () => slideRefs[0].current.slickPrev(),
        1: () => slideRefs[1].current.slickPrev(),
        2: () => slideRefs[2].current.slickPrev(),
        3: () => slideRefs[3].current.slickPrev(),
        4: () => slideRefs[4].current.slickPrev(),
        5: () => slideRefs[5].current.slickPrev(),
        6: () => slideRefs[6].current.slickPrev(),
        7: () => slideRefs[7].current.slickPrev(),
    };

    // Next slide function
    // Call the slickNext method on the slider reference
    const nextSlides = {
        0: () => slideRefs[0].current.slickNext(),
        1: () => slideRefs[1].current.slickNext(),
        2: () => slideRefs[2].current.slickNext(),
        3: () => slideRefs[3].current.slickNext(),
        4: () => slideRefs[4].current.slickNext(),
        5: () => slideRefs[5].current.slickNext(),
        6: () => slideRefs[6].current.slickNext(),
        7: () => slideRefs[7].current.slickNext(),
    };

    var pageSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    {/* const carouselSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const progressBarWidth = 26;
    const progressBarHeight = 11;
    const progressBarStripes = 5;
    const progressBarActiveColor = '#00863F';
    const progressBarInactiveColor = '#D9D9D9';
    const [progress, setProgress] = useState(0);
    const [qa, setQA] = useState(0);
    const [surveyID, setSurveyID] = useState("");
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(true); */}

    useEffect(() => {
        {/*setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);*/}
        window.scrollTo(0, 0);
    }, []);

    {/*useEffect(() => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        setIsLoading(true);
        getCatSurvey()
            .then((result) => {
                result.data.forEach((index) => {
                    if ((uID === index.user_id) && (index.survey_status === "Pending")) {
                        setQA(index.qa.length);
                        setProgress(index.qa.length);
                        setSurveyID(index.survey_id);
                        setResponse(index.qa);
                        setIsLoading(false);
                    }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const renderProgressBarStripes = () => {
        const progressBarStripesArr = [];
        for (let i = 0; i < progressBarStripes; i++) {
            const isActive = i < Math.ceil((((progress === 76) ? 100 : progress) / 100) * progressBarStripes);
            const stripeColor = isActive ? progressBarActiveColor : progressBarInactiveColor;

            progressBarStripesArr.push(
                <div
                    key={i}
                    className="progress-bar-stripe"
                    style={{
                        width: `${progressBarWidth}px`,
                        height: `${progressBarHeight}px`,
                        backgroundColor: stripeColor,
                        marginRight: '5px', // Add right spacing between stripes
                    }}
                ></div>
            );
        }
        return progressBarStripesArr;
    };

    const handleSurvey = () => {
        try {
            navigate((localStorage.getItem("job_type") === "Professional") ? "/catsurvey" : "/natsurvey?explore", { state: { qa, surveyID, response } });
        }
        catch (err) {
            console.log("error at handleSurvey");
        }
    }; */}

    return (
        <>
            <CustomerHeader />
            <section className='customer-dashboard-wrapper'>
                <Container className='dashboard-container'>
                    <Row>
                        {/* <Col xs="12" lg="3">
                            <Card className='task-completion-track-card'>
                                <div className="centered-content">
                                    <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700'>{localStorage.getItem("job_type") === "Professional" ? "CAT survey" : "NAT survey"}</p>
                                    {localStorage.getItem("job_type") === "Professional" ? <p>{progress === 76 ? 100 : progress}% Completed</p> : "Create your"}<br />
                                    {localStorage.getItem("job_type") === "Professional" ?
                                        <div className="progress-bar-container">
                                            {renderProgressBarStripes()}
                                        </div> :
                                        "Job Requirements"
                                    }
                                    <Button variant="primary" className="complete-btn mt-5" onClick={handleSurvey}>
                                        {localStorage.getItem("job_type") === "Professional" ? (progress === 0 ? "Start Survey" : "Complete Now") : "Start Survey"}
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" lg="9">
                            <Container fluid className="h-100">
                                <Row className="h-100">
                                    <Col sm={12} md={6} className="left-side">
                                        <div className="background-image"></div>
                                        <div className="content">
                                            <div>
                                                <Image src={require('../../assets/images/MSABC-stacked-regular.svg').default} alt="logo" />
                                            </div>
                                            <h2 className="text-start ms-fw-600 mt-20">Knowledge of process and task safety analysis methods</h2>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} className="right-side">
                                        <Slider {...carouselSettings}>
                                            <div className="carousel-slide">
                                                <div>
                                                    <h1>Welcome to The OHS Competency Framework and Profile</h1>
                                                    <p>
                                                        As workplaces evolve with technology and diversity, Occupational Health and Safety (OHS) professionals are stepping up as
                                                        holistic advocates for worker well-being. From data-driven decision-making to fostering safety cultures, the <em>Framework</em>
                                                        describes he multidimensional skills needed to practice the profession. Crafted through collaboration, validation, and consensus,
                                                        it pioneers a standardized approach, ensuring competent OHS professionals can consistently mitigate risks and nurture a safer,
                                                        healthier work environment.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="carousel-slide">
                                                {(localStorage.getItem("job_type") === "Professional") ?
                                                    <div>
                                                        <h1>Discover the Competency Assessment Tool</h1>

                                                        <p>This tool allows OHS professionals to discover and document their knowledge and skills that make them able to help workers stay safe
                                                            on-the-job. Unveil your mastery of crucial competencies, devise tailored development strategies, and embark on a journey towards a
                                                            safer and more resilient workplace through using the <em>Framework</em>.
                                                        </p>
                                                    </div>
                                                    :
                                                    <div>
                                                        <h1>Discover the Needs Assessment Tool</h1>

                                                        <p>The Needs Assessment Tool is your compass to precision in workplace safety. Discover how you can use the Framework to explain your
                                                            organization’s OHS needs to current and future hires, and pave the way to a more unified approach to managing your workers’ safety
                                                            and your organization’s legal and other requirements.
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </Slider>
                                    </Col>
                                </Row>
                            </Container>
                        </Col> */}
                        {Object.keys(quesNumbers).map((index) => {
                            const titleIndex = parseInt(index);
                            const unitNumber = titleIndex + 1;
                            if (location.state === null ? show : location.state.area === `area${unitNumber}`) {
                                return (
                                    <Col xs="12" className='mt-3' key={`area${unitNumber}`}>
                                        <Card className='carousel-card pb-4 ps-4 pe-4 pe-md-0'>
                                            <div className='d-flex align-items-center py-3 px-4 justify-content-between flex-wrap'>
                                                {<OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip>{titleDesc[`titleDesc${unitNumber}`]}</Tooltip>}>
                                                        <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700 mb-0'>{title[`title${unitNumber}`]}</p>
                                                </OverlayTrigger>}
                                                <div className="custom-carousel-navigation">
                                                    <Button variant='link' className='px-2' onClick={prevSlides[titleIndex]}> <Image src={require('../../assets/images/circle-nav-left.svg').default} alt="nav-left" /></Button>
                                                    <Button variant='link' className='px-2' onClick={nextSlides[titleIndex]}> <Image src={require('../../assets/images/circle-nav-right.svg').default} alt="nav-right" /></Button>
                                                </div>
                                            </div>
                                            <Slider {...pageSettings} ref={slideRefs[titleIndex]} className='dashboard-slider-block'>
                                                {quesNumbers[titleIndex].map((quesNum) => (
                                                    <div className='slide-item' key={quesNum}>
                                                        <Card className={`content-card ${slideColours[`title${unitNumber}`]}`}>
                                                            <div className='px-3 py-4'>
                                                                <p className='ms-ft-16 ms-fw-700 ms-ft-darkgrey mb-0'>{header[`header${quesNum}`]}</p>
                                                                <p className='ms-ft-15 ms-ft-mediumgrey mt-3 ms-lh-20'>{questions[`question${quesNum}`]}</p>
                                                            </div>
                                                            <div className='card-footer'>
                                                                <Button variant='link' className='text-decoration-none ms-ft-primary ms-ft-15 ms-fw-600' onClick={() => handleClickReadNow({ title: title[`title${unitNumber}`], header: header[`header${quesNum}`], question: questions[`question${quesNum}`], answer: answers[[`${quesNum}`]], desc: description[`desc${quesNum}`], key: keyPoints[`key${quesNum}`], ...(location.state === null ? {} : { area: location.state.area }), suggested: suggestedLevels[`suggested${quesNum}`] })}>
                                                                    Read Now
                                                                </Button>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Card>
                                    </Col>
                                )
                            }
                        }
                        )}
                    </Row>
                </Container>
            </section>
            <ToastContainer
                position="top-center"
                hideProgressBar
            />
            <Footer />
        </>

    );
};

export default DashboardComponent;