const natSurveyData = [
  {
    "unit": "101",
    "natDesc": "Can identify and control hazards using hazard-specific legislation, standards, guidelines, and safety analysis methods.",
    "questionHeader": "101: Principles of Hazard Identification",
    "questionText": "OHS professionals must identify and control hazards using hazard-specific legislation, standards, guidelines, and safety analysis methods.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can follow up with other OHS professionals and experts about identified hazards and their harms. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify the primary types of hazards, including physical, ergonomic, chemical, biological, and psychological. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize relevant legal requirements and other requirements that pertain to workplace hazards. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review hazard analysis methods, such as failure modes and effects analysis, hazard and operability study, and fault tree analysis.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the importance of job safety analysis and hazard analysis to team members by engaging with stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can research hazard-specific legislation, standards, and guidelines to find the best practices for maintaining a safe work environment.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the quality and completeness of a job safety analysis. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon existing hazard analysis processes. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach the principles of hazard identification to other OHS professionals.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "102",
    "natDesc": "Can continuously find and respond to hazards quickly as they emerge.",
    "questionHeader": "102: Hazard Identification and Analysis",
    "questionText": "OHS professionals must continuously find and respond to hazards quickly as they emerge.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about the hazards identified by a job safety analysis. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain how to interpret the results of a job safety analysis. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the purpose of hazard identification and analysis. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements that pertain to the identification of hazards in the workplace.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess organizational processes by conducting a job safety analysis and identifying potential hazards. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop a job safety analysis to identify potential hazards and their impacts on organizational processes. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor hazard identification equipment within the workplace. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can review the results of a job safety analysis with stakeholders to communicate any identified hazards and their potential harms.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can innovate upon current job safety analysis processes. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a comprehensive job safety analysis evaluation system to ensure quality. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate hazard assessment and profiling procedures to adapt to changing worksite contexts. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve issues and concerns related to job safety analysis implementation through effective communication and collaboration. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach OHS professionals about the purpose and processes of a job safety analysis.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "103",
    "natDesc": "Can proactively identify new and emerging hazards and provide recommendations on how to address them.",
    "questionHeader": "103: Identifying New and Emerging Hazards",
    "questionText": "OHS professionals must proactively identify new and emerging hazards and provide recommendations on how to address them.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts to review new and emerging hazards that may produce unknown harms. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify risks involving conscious opponents who deliberately seek to avoid being compliant with legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can communicate with other OHS professionals and experts about them slow-acting hazards that may take many years to manifest negative effects.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess new and emerging hazards for their potential to produce harm. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders regarding the levels of hazards and the importance of reporting new and emerging hazards. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research best practices in identifying and controlling for new and emerging hazards.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of organizational preparedness programs. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a program for identifying new and emerging hazards. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate upon a standardized method, like  SWOT analysis for evaluating and analyzing new and emerging hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve issues related to identifying new and emerging hazards in the workplace through effective communication with stakeholders. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach OHS professionals how to identify new and emerging hazards.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "104",
    "natDesc": "Can understand and apply causation models to identify root causes of incidents and prevent future harm.",
    "questionHeader": "104: Causation Models",
    "questionText": "OHS professionals must understand and apply causation models to identify root causes of incidents and prevent future harm.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can recognize different models of causation. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can review resources and processes used to determine the causal relationship between hazards and harm.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a hazard by analyzing it with a cause model. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the principles of causation to stakeholders in a report and in conversation. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and apply the appropriate models of causation for hazard identification.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can innovate policies, objectives, and procedures by evaluating their effectiveness against models of causation. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can teach OHS professionals about the principles and application of models of causation to improve workplace safety.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "105",
    "natDesc": "Can identify and implement effective control measures for workplace hazards.",
    "questionHeader": "105: Principles of Risk Control",
    "questionText": "OHS professionals must identify and implement effective control measures for workplace hazards.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the hierarchy of controls. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the time sequence of pre-event, event, and post-event control and intervention points. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can follow criteria for critical controls and principles of critical control management. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can identify implementations of different risk controls. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize how control systems should be evaluated on an ongoing basis. ",
          "value": false
        },
        {
          "unit": "levelA5",
          "question": "Can recognize inherent safety and engineered safe design principles.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate risk control policies and procedures to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor risk controls and processes.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an organization's adherence to risk controls and identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze the effectiveness of risk controls and recommend modifications as necessary. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design and develop innovative risk controls to address identified workplace hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the principles of risk controls and how to implement them effectively.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "106",
    "natDesc": "Can design or redesign the workplace to eliminate hazards whenever possible.",
    "questionHeader": "106: Hazard Elimination and Substitution",
    "questionText": "OHS professionals must design or redesign the workplace to eliminate hazards whenever possible.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify appropriate replacement materials for hazardous ones. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize that elimination is the most effective way to control risks. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify workplace hazards that can be eliminated. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review and communicate with other OHS professionals and experts about hazards that can be eliminated or substituted.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess risks to determine if elimination or substitution is a viable control method. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the purpose of elimination or substitution to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor the elimination of a hazard from the work place. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and recommend alternative equipment or processes that substitute or eliminate the hazard.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of substitution controls to reduce exposure to hazards. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can teach OHS professionals about the importance of elimination and substitution controls. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate new elimination or substitution control methods to further reduce risk in the workplace. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve challenges related to implementing elimination or substitution control methods in a way that maximizes their effectiveness.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "107",
    "natDesc": "Can implement systems that reduce worker contact with a hazard.",
    "questionHeader": "107: Engineering Controls",
    "questionText": "OHS professionals must implement systems that reduce worker contact with a hazard.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about the effectiveness of engineering controls. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the purpose and implementation of engineering controls. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the legal reqiurements and other requirements regarding the implementation and maintenance of engineering controls. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can identify any deficiencies or issues with engineering controls. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize potential hazards and risks that require engineering controls. ",
          "value": false
        },
        {
          "unit": "levelA5",
          "question": "Can review the implementation of different types of engineering controls.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate with stakeholders about the importance of engineering controls in preventing harm. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor engineering controls. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can communicate with stakeholders about how to properly adhere to engineering controls.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze existing engineering controls to determine their effectiveness. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze procedural compliance to identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design engineering control programs to mitigate risk. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve issues related to the implementation and maintenance of engineering controls. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach OHS professionals about the benefits and limitations of different kinds of engineering controls.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "108",
    "natDesc": "Can maintain and ensure the effectiveness of administrative controls.",
    "questionHeader": "108: Administrative Controls",
    "questionText": "OHS professionals must maintain and ensure the effectiveness of administrative controls.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about any deficiencies or issues with administrative controls. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the purpose and implementation of administrative controls. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify potential hazards that require administrative controls. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review the implementation of different types of administrative controls, including training programs and procedures.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate to stakeholders how to properly adhere to administrative controls and the importance of such controls in preventing harm. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor administrative controls.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an organization's adherence to administrative controls and identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon existing administrative controls to enhance workplace safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design effective administrative control programs. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve issues related to the implementation and effectiveness of administrative controls through collaboration with stakeholders.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "109",
    "natDesc": "Can select, use, and maintain appropriate PPE for workers in hazardous environments.",
    "questionHeader": "109: Personal Protective Equipment",
    "questionText": "OHS professionals must select, use, and maintain appropriate PPE for workers in hazardous environments.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about any deficiencies or issues with PPE. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the purpose and implementation of PPE. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can follow established guidelines and procedures for the use of PPE. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can identify potential hazards that require PPE. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize the importance of properly fitting PPE.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess and maintain PPE according to a manufacturer's standard. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate with stakeholders about the importance of PPE in preventing harm and on its proper use. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor a PPE program. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement proper PPE fitting practices.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate an organization's compliance with PPE procedures to identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze and evaluate existing PPE to determine their effectiveness in mitigating risk. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design and develop effective PPE programs for organizations. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve issues related to PPE implementation and compliance through effective communication and problem-solving skills. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach OHS professionals about the methods of implementing PPE to ensure worker safety.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "201",
    "natDesc": "Can make decisions based on the risks present in an organization.",
    "questionHeader": "201: Principles of Risk Assessment",
    "questionText": "OHS professionals must make decisions based on the risks present in an organization.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the difference between hazards and risks. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify risks as a function of the likelihood of harm and the severity of harm. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize risk as a multi-faceted concept. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the qualitative and quantitative aspects of risk.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a risk's severity based on its likelihood and potential consequences. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders to ensure they are informed about risk assessment activities.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effects of risk assessment activities on an organization's performance. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze the interaction between organizational changes and risk. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can analyze workers' and senior management's understanding of risk in the workplace. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve complex problems related to changing levels of risk.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "202",
    "natDesc": "Can assess how likely and how severe a given risk is.",
    "questionHeader": "202: Risk Analysis",
    "questionText": "OHS professionals must assess how likely and how severe a given risk is.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about a risk and its associated likelihood and severity . ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the purpose of risk analysis. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the appropriate control measures to mitigate identified risks. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements that pertain to risk analysis.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a risk to estimate its likelihood and severity of produced harm. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate with stakeholders about the impact of identified risks and their severity on an organization's policies, objectives, and procedures. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop risk prioritization practices using established resources and processes, such as risk matrices.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an existing risk identification and profiling plan to identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze an organization's context and develop appropriate risk analysis procedures. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach OHS professionals about risk analysis procedures and their importance in promoting workplace safety.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "203",
    "natDesc": "Can develop strategies to manage risk effectively.",
    "questionHeader": "203: Risk Evaluation",
    "questionText": "OHS professionals must develop strategies to manage risk effectively.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain basic statistical concepts, such as the mean and the standard deviation, to non-technical stakeholders. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the processes used in risk evaluation, risk estimation and prioritization . ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the implications of statistical concepts on risk evaluation strategies. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements that pertain to the identification of risks in the workplace.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate recommendations for corrective actions based on risk evaluation to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate risk likelihoods and risk prioritization metrics to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor a system for risk re-evaluation.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze risk evaluation results to develop effective action plans. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate risk evaluation results and recommendations to stakeholders in a clear and concise manner. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design training programs to teach OHS professionals about risk evaluation methods. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can innovate strategies to enhance the efficiency and effectiveness of risk evaluation. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can resolve risk evaluation results with organizational priorities.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "204",
    "natDesc": "Can monitor and evaluate the ongoing effectiveness of risk controls.",
    "questionHeader": "204: Monitoring Risk",
    "questionText": "OHS professionals must monitor and evaluate the ongoing effectiveness of risk controls.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain how recurring data analysis can help identify trends and patterns in workplace incidents. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain how risk monitoring helps identify potential hazards in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can explain the importance of regular risk assessments in maintaining a safe work environment. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can explain the steps involved in conducting a thorough analysis of OHS performance data. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize how new risks are introduced to the workplace.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess how changes in risk impacts an organization. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can assess how changes in risk impact workers. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can communicate effectively with stakeholders about changes in workplace risk. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor an existing risk monitoring system.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can innovate by incorporating new technology into risk-monitoring systems. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate risk-monitoring systems to incorporate new technology and enhance monitoring capabilities.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "205",
    "natDesc": "Can conduct risk assessments and understand an organization's context and external agencies during emergencies.",
    "questionHeader": "205: Risk Assessment in Emergencies",
    "questionText": "OHS professionals must conduct risk assessments and understand an organization's context and external agencies during emergencies.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about the effectiveness of emergency response processes, including risk assessment processes. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the different types of emergency events, such as natural disasters, workplace accidents, and medical emergencies. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can follow procedures for responding to different emergency events. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements that pertain to emergency response.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess risk during an emergencies and extraordinary circumstances. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate with stakeholders about emergency response procedures. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor emergency response training programs and drills.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate existing emergency response procedures to identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate effectively with stakeholders to ensure a coordinated and efficient emergency response. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design and innovate emergency response procedures to effectively address potential safety risks. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve complex safety and emergency response challenges by considering the unique context and needs of the situation.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "206",
    "natDesc": "Can express the information gathered from risk assessment in written records.",
    "questionHeader": "206: Documentation of Risk Assessments",
    "questionText": "OHS professionals must express the information gathered from risk assessment in written records.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the formal structure and methods for report writing. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow established procedures for risk assessment reporting. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the appropriate and necessary information to include in risk assessment reporting. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements that pertain to documenting risk assessments. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can review the appropriate data from internal and external sources.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess other risk assessment documentation for completeness and conclusions. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct ongoing risk assessments in response to changes in the context of the organization. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research relevant data from internal and external sources to inform risk reporting.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the quality of other risk assessment documentation. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design effective risk assessment reports for OHS professionals to use. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach OHS professionals how to design and complete a risk assessment report.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "207",
    "natDesc": "Can design corrective action plans that address hazards and effectively communicate them to stakeholders.",
    "questionHeader": "207: Risk Assessment Action Plans",
    "questionText": "OHS professionals must design corrective action plans that address hazards and effectively communicate them to stakeholders.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about concerns about the functioning of hazard monitoring equipment. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can communicate with other OHS professionals and experts about outstanding risk-related concerns to specialists. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can explain basic OHS science and its relevance to different hazards and their controls. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can identify the functioning and hazards presented by different types of machinery and processes. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can review technical standards to ensure compliance.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate OHS performance metrics and action plans to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the relevance of changes in OHS best practices to an organization to that organization's stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research newly available technologies in risk assessment and control.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can communicate the importance of corrective action plans in addressing new or emerging hazards. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can resolve conflicts between corrective action plans and an organization's policies, objectives, and procedures. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach organizations how to develop and implement effective corrective action plans.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "208",
    "natDesc": "Can address risk through deploying strategies in partnership with stakeholders.",
    "questionHeader": "208: Reducing Risk within Organizations",
    "questionText": "OHS professionals must address risk through deploying strategies in partnership with stakeholders.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify different risk-reduction resources and processes. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify the requirements and objectives of a business continuity plan. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize different types of business continuity plans, including succession plans. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review the appropriate risk-reduction strategies to adopt to address different kinds of risk.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess an action plan for its compatibility with a business continuity plan. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders to ensure understanding and compliance with safe work practices. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor an OHS-relevant business continuity plan.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze a business continuity plan for feasibility and effectiveness. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a new business continuity plan in response to changes to the context of an organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate upon risk reduction strategies to improve workplace safety. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve conflicts and issues related to workplace safety through effective communication and collaboration. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach OHS professionals about risk reduction resources and processes to improve workplace safety.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "209",
    "natDesc": "Can conduct workplace inspections and recommend actions to control risks.",
    "questionHeader": "209: Workplace Inspections",
    "questionText": "OHS professionals must conduct workplace inspections and recommend actions to control risks.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about the recommended corrective actions based on an inspection. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify the different types of workplace inspections. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize how workplace inspections help achieve safety objectives. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal and other requirements that pertain to workplace inspections.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the effectiveness of workplace safety inspections and make recommendations for improvement to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct various types of workplace inspections, such as hazard assessments, ergonomic evaluations, and fire safety checks. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can review inspection reports and communicate findings to relevant stakeholders.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of inspection processes in addressing changes within the organization's context. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze the quality of completed inspection reports. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate new inspection processes that are more efficient and effective in identifying hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals how to perform various types of inspections and provide guidance on best practices.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "301",
    "natDesc": "Can understand incident investigation concepts to prevent workplace incidents.",
    "questionHeader": "301: Principles of Incident Investigation",
    "questionText": "OHS professionals must understand incident investigation concepts to prevent workplace incidents.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with relevant stakeholders during the investigation process. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the legal requirements and other requirements for reporting incidents. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the importance of scene preservation and evidence preservation. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can explain the link between incident-related data and the investigation process . ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize the importance of ensuring scene and evidence preservation. ",
          "value": false
        },
        {
          "unit": "levelA5",
          "question": "Can recognize the importance of determining the root cause of incidents rather than assigning blame. ",
          "value": false
        },
        {
          "unit": "levelA6",
          "question": "Can review and ensure urgent corrective actions are taken post incidents.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can conduct ongoing monitoring and evaluation of an incident investigation and prevention plan, including assessing the performance of incident investigation teams. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct ongoing monitoring of organizational roles and responsibilities to ensure compliance with legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor incident investigation protocols and procedures to ensure compliance with the organization's incident investigation and prevention program. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can communicate appropriate and management-approved information about the incident investigation process with stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can identify incidents that require either internal or external investigation.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design complex investigations utilizing the principles and processes standards. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design adaptable investigative procedures tailored to incident severity and location. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design strategies for continual improvement of the incident investigation and prevention program. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can analyze an incident investigation and prevention plan’s performance in engaging senior management and workers. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can innovate upon best practices in incident investigation and prevention to conduct better investigations. ",
          "value": false
        },
        {
          "unit": "levelC5",
          "question": "Can train others on the principles and importance of incident investigation. ",
          "value": false
        },
        {
          "unit": "levelC6",
          "question": "Can communicate OHS advice to legal teams as required. ",
          "value": false
        },
        {
          "unit": "levelC7",
          "question": "Can resolve barriers to the investigation process, such as conflicts of interest, confidentiality concerns, labour concerns, or trade secrets.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "302",
    "natDesc": "Can use incident theory to understand contributing factors and guide investigations.",
    "questionHeader": "302: Incident Theory",
    "questionText": "OHS professionals must use incident theory to understand contributing factors and guide investigations.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the concepts of incident proneness, near misses, risk transfer, risk retention, and risk responsibility. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify the various incident theories, including domino, energy release, human factors, and multi-causation. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the theories and models that guide investigations.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can implement different incident theories during an investigation. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct an unbiased investigation that considers multi-factor causation. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can communicate different incident theories, including their strengths and weaknesses, to workers and senior management. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can conduct an incident investigation by following a systematic process and incorporating the appropriate theories and models.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an investigation finding at a high level through applying different incident theories. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design incident prevention strategies using incident theories. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate investigative approaches by incorporating different models based on incident trends and organizational legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can train other OHS professionals on how to apply incident theories and models. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can communicate with executive level stakeholders about the rationale for applying a particular incident theory to an investigation.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "303",
    "natDesc": "Can effectively control an incident scence, assess risks, and preserve data.",
    "questionHeader": "303: Scene Management",
    "questionText": "OHS professionals must effectively control an incident scence, assess risks, and preserve data.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can recognize that an investigation prioritizes the safety of people involved in an incident. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow expert advice that supports the physical and psychological health of witnesses. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the duties and responsibilities of stakeholders during investigation initialization. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can identify the legal requirements and other requirements related to both scene management and evidence preservation.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the purpose, roles, and responsibilities of senior management, workers, and other stakeholders during an investigation initialization. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct a dynamic risk assessment and implement appropriate control strategies. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop an investigation plan based on the preliminary data and applying organizational protocols. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor an incident investigation plan for the preservation of evidence. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can support scene management and scene safety protocols, ensuring compliance with legal requirements and other requirements.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and manage a complex incident scene through navigating competing organizational interests and ensuring an organization’s compliance with legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can develop and present a written scene management plan to stakeholders, which includes expectations for stakeholder compliance and protocol. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can communicate with stakeholders to manage expectations of and promote compliance with an incident investigation. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can design policies, objectives, and processes for effective scene management that comply with an organization’s legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can train other OHS professionals on scene management protocols.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "304",
    "natDesc": "Can rigorously gather incident-relevant data through interviewing and evidence collection.",
    "questionHeader": "304: Data Collection",
    "questionText": "OHS professionals must rigorously gather incident-relevant data through interviewing and evidence collection.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify sector-specific factors, such as hazards, that can inform data collection strategies. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize the different types of data, including perishable data, interview data, documents, physical and digital evidence. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can review data collection and documentation best practices and an organization’s legal requirements and other requirements.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate effectively with stakeholders while maintaining the integrity of the investigation. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct preliminary interviews. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and maintain a strong and consistent digital record-keeping process. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can document all data collected at the scene, witness statements, interviews, research, and security camera images. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can follow best practice protocols for identifying, recording, handling, storing, documenting, and filing data. ",
          "value": false
        },
        {
          "unit": "levelB5",
          "question": "Can implement priority measures to prevent evidence contamination in situations such as adverse weather conditions.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design and implement a consistent methodology for evidence collection, adapting procedures based on incident scenarios. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design written data management protocols. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach and guide other investigation team members on how to responsibly collect data. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve any issues related to data collection to ensure accurate and reliable information is available for decision-making. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can design and conduct complex interviews in accordance to best practices.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "305",
    "natDesc": "Can organize and assess incident data with accuracy and completeness.",
    "questionHeader": "305: Data Analysis",
    "questionText": "OHS professionals must organize and assess incident data with accuracy and completeness.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate effectively with other OHS professionals and experts about data security concerns. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain potential reasons for information gaps or inaccurate data identified during an investigation. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize different analytical tools used to manage and interrogate data. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the importance of adhering to data management storage procedures.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess and identify emerging themes or trends related to data collected from an incident. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate statistical findings to various stakeholders through written reports and oral presentations. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and provide progress updates on data analysis to team members during the investigation. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can review and ensure that the gathered and collated data is presented in a clear and consistent format, utilizing various software tools as needed.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze both primary and secondary data using best practices . ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze complex data and verify the accuracy of any results before incorporating them into a report. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can communicate data findings effectively to stakeholders through clear and concise presentations. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can design data presentations that adhere to best practice standards. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can train other incident investigators and senior management in an analytical approach to exploring data and determining its validity, accuracy, and completeness.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "306",
    "natDesc": "Can write thorough and factual investigation reports, linking data to causal factors and proposing prevention activities.",
    "questionHeader": "306: Investigation Reports",
    "questionText": "OHS professionals must write thorough and factual investigation reports, linking data to causal factors and proposing prevention activities.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the required elements for a complete investigation report. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the differences between a preliminary report and a final report. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the legal requirements and other requirements for investigators regarding preliminary and final reporting. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review existing workplace health and safety procedures and their relevance to a preliminary or final report.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can document a chronological account of the events prior, during and post the incident. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate with stakeholders about the investigation findings in an unbiased manner. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop complete incident investigation reports that include the immediate corrective actions taken during the early investigative phase and recommendations for further risk controls. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can document data supporting the investigation hypotheses within the investigation report.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze team members' investigation contributions through peer review. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate investigation reports to various stakeholders, including with internal legal professionals as required. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design investigative reports in a clear, concise, and consistent format, following recognized industry standards.. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can design tabulated and logically organized preliminary and final reports, ensuring all data is included and supporting metrics are provided as annexes.. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can analyze the performance of corrective actions taken during the investigation and include that analysis within the report. ",
          "value": false
        },
        {
          "unit": "levelC5",
          "question": "Can teach and guide others on how to prepare and produce excellent investigation reports. ",
          "value": false
        },
        {
          "unit": "levelC6",
          "question": "Can communicate an investigation report to various external stakeholders such as provincial and federal officers, legal representatives, labour representatives, and media.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "307",
    "natDesc": "Can develop, monitor, and communicate an action plan based on investigation findings to prioritize corrective actions.",
    "questionHeader": "307: Investigation Action Plans",
    "questionText": "OHS professionals must develop, monitor, and communicate an action plan based on investigation findings to prioritize corrective actions.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can implement corrective actions based on hazard identification and risk assessment throughout an incident investigation. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can communicate the purpose of a corrective action to stakeholders . ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify and document immediate corrective actions taken after an incident. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements during before-, during-, and post-incident events.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the response performance of emergency response procedures to develop or revise response plans. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and revise a post-incident action plan. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can conduct a peer review of corrective actions already in place. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can communicate operational metric data to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can implement, monitor, and document post-incident performance management practices, using concepts like balance scorecards and key performance indicators. ",
          "value": false
        },
        {
          "unit": "levelB5",
          "question": "Can monitor and adjust an action plan based on a suitable performance management methodology.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze key performance indicators for an action plan’s performance and provide appropriate reports to internal stakeholders. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and communicate action plans to internal stakeholders that describe lagging and leading OHS issues. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can analyze and monitor the outcomes of a corrective actions to identify data patterns and target specific mitigation measures. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can communicate corrective actions and investigation findings to stakeholders to embed safer practices with daily workplace operations. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can resolve issues related to corrective actions and staff training, and communicate the outcomes to relevant stakeholders. ",
          "value": false
        },
        {
          "unit": "levelC5",
          "question": "Can train OHS professionals on different approaches to risk assessment and control during incident investigation and prevention.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "401",
    "natDesc": "Can develop strategies that promote worker health and wellness and educate workers and senior management.",
    "questionHeader": "401: Principles of Health",
    "questionText": "OHS professionals must develop strategies that promote worker health and wellness and educate workers and senior management.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the importance of maintaining good physical and psychological health. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize legal requirements and other requirements that pertain to an organization's commitment to worker health. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the relationship between hazards and harm, including psychological harms such as stress and fatigue.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a workplace for risks to physical and psychological health. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the impact of physical and psychological health on organizational performance to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement policies, objectives, and practices into an OHSMS to reduce risk of physical and psychological harm.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the level of worker health and well-being within an organization. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can teach and design training resources to enhance an organization's understanding of physical and psychological health.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "402",
    "natDesc": "Can promote psychologically healthy and safe work processes.",
    "questionHeader": "402: Principles of Psychological Health and Safety",
    "questionText": "OHS professionals must promote psychologically healthy and safe work processes.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify the signs and symptoms of burnout in employees. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain how psychological health and safety is relevant to OHS activities. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify existing resources, like standards and guidelines, that can be used to promote psychological health within an organization.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess workplace mental health using existing resources, like surveys. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders to provide updates on the current or anticipated state of an organization's psychological health and safety. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor strategies to improve psychological health and safety.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing procedures and programs by comparing them against existing resources. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a training program to teach an organization about the principles of psychological health and safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate upon the content of existing resources to address the unique needs of an organization.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "403",
    "natDesc": "Can build workplaces that are defined by trust, respect, and civility.",
    "questionHeader": "403: Organizational Culture",
    "questionText": "OHS professionals must build workplaces that are defined by trust, respect, and civility.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify the factors involved in the modification of organizational culture, such as the relationship between workers and senior management. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize the importance of shared corporate values in establishing organizational culture. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the importance of trust within the workplace for maintaining a strong organizational culture.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess an organization's culture through existing resources. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate advice on how to improve organizational culture to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research industry best practices for improving organizational culture.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an organization's culture to identify areas for improvement and develop processes to address them. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design solutions to resolve complex tensions between policies, objectives, and procedures and the need for good organizational culture. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate by forecasting future trends within an organization's culture and developing strategies to adapt to them.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "404",
    "natDesc": "Can address the full spectrum of a worker's health and understand the multifaceted concept of worker wellness.",
    "questionHeader": "404: Worker Wellness",
    "questionText": "OHS professionals must address the full spectrum of a worker's health and understand the multifaceted concept of worker wellness.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate hazardous events to specialists in physical and psychological health. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the influence of an organization's context on worker wellness. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the difference between mental health and mental illness.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a workplace for chronic and cumulative health impacts. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can assess a workplace for levels of worker fatigue and stress. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can communicate with stakeholders about the impact of worker wellness on organizational performance. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement ideas and strategies to improve worker wellness in collaboration with stakeholders.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate existing or proposed policies, objectives, and procedures for their impact on worker wellness. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design policies, objectives, and procedures with considerations for worker well-being. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve complex workplace issues that impact worker wellness, such as interpersonal conflict.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "405",
    "natDesc": "Can develop and implement policies, objectives, and procedures that promote worker health and well-being.",
    "questionHeader": "405: Health Promotion",
    "questionText": "OHS professionals must develop and implement policies, objectives, and procedures that promote worker health and well-being.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about hazardous events that may have caused harm to workers' health. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow expert recommendations in the use of sensitive language and confidentiality when talking about worker health. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize strategies to address and diffuse organizational resistance or stigma to health promotion processes. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the impact of stigmatization on worker mental health.",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the importance of stigma reduction to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor health promotion processes, including action planning, to improve workers' health and wellness.",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze existing or proposed health promotion policies, objectives, and procedures. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate the importance of health promotion to employees and stakeholders. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design policies, objectives, and procedures that align with the principles of health promotion. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve conflicts related to health promotion policies, objectives, and procedures. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach the principles of health promotion to employees and stakeholders.",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "406",
    "natDesc": "Can assist in the development, implementation, and monitoring of a return-to-work program.",
    "questionHeader": "406: Return-to-Work Programs",
    "questionText": "OHS professionals must assist in the development, implementation, and monitoring of a return-to-work program.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can follow a return-to-work plan. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify the responsibilities of all stakeholders within a return-to-work program. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can explain how workplace accommodations are relevant to an OHSMS",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate effectively with stakeholders about the benefits of a return-to-work program. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement a persuasive approach when presenting a return-to-work program to workers and senior management. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor an organization's return-to-work program",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of intra-organizational collaboration in supporting workers returning from a disability or prolonged absence. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design effective return-to-work plans",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "407",
    "natDesc": "Can understand how vulnerability emerges at the workplace and how to address it.",
    "questionHeader": "407: Workplace Vulnerability",
    "questionText": "OHS professionals must understand how vulnerability emerges at the workplace and how to address it.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain how interpersonal relationships can create vulnerability in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify interpersonal relationships that can create vulnerability in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize relevant legal requirements and other requirements that prohibit discrimination. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can communicate with other OHS professionals and experts about issues related to diversity and inclusion",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the importance of addressing vulnerability to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor risk assessment processes centered on identifying and addressing vulnerability. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can assess the relationship between vulnerability and sociodemographic factors, like age, immigration status, and disability",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can communicate effectively with stakeholders about the importance of addressing vulnerability and the steps being taken to mitigate it. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and implement policies, objectives, and procedures to address vulnerability based on an evaluation and analysis of existing ones. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve issues related to vulnerability by collaborating with stakeholders and experts. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the principles of vulnerability and how to apply them in their work to improve workplace safety",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "501",
    "natDesc": "Can set policies, objectives, and procedures that take a systematic approach to preventing harm.",
    "questionHeader": "501: Principles of OHSMS",
    "questionText": "OHS professionals must set policies, objectives, and procedures that take a systematic approach to preventing harm.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the elements of an OHSMS, such as the roles of different stakeholder groups and the plan-do-check-act management method. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify and differentiate between different certificate of recognition programs. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the legal and other requirements for an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review the policies, objectives, and procedures contained within an OHSMS",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can develop and conduct training for various stakeholders in adhering to an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor an existing OHSMS. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research best practices for implementing an OHSMS within a specific sector. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can assess an OHSMS for its relevance and effectiveness during changes to the context of the organization",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can teach OHS professionals about the principles of developing and maintaining an effective OHSMS. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze the effectiveness of an existing OHSMS and identify areas for improvement",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "502",
    "natDesc": "Can work with various stakeholders within an organization to promote and maintain an OHSMS.",
    "questionHeader": "502: Commitment, Leadership, and Participation",
    "questionText": "OHS professionals must work with various stakeholders within an organization to promote and maintain an OHSMS.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain how an OHSMS integrates with organizational objectives. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify legal requirements and other requirements that pertain to worker and senior management participation within an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the challenges in maintaining an OHSMS across multiple worksites",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the importance of senior leadership and worker participation within an OHSMS to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and maintain an OHSMS across an entire organizations or multiple worksites. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and maintain processes for the performance monitoring and measurement of different stakeholder group involvement",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design an OHSMS that promotes stakeholder engagement across an organization. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can resolve complex issues in aligning an OHSMS with the context of an organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "503",
    "natDesc": "Can determine competency requirements for workers and oversee training activities.",
    "questionHeader": "503: Competence and Training",
    "questionText": "OHS professionals must determine competency requirements for workers and oversee training activities.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the principles of adult learning. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify different training resources and processes for working with difficult trainees. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the importance of maintaining accurate training records",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess learners to evaluate the effectiveness of training activities. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate with stakeholders about training needs and progress through informal and formal advice. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can conduct a training needs assessment. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can develop documentation processes for training. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research providers, instructor qualifications, and course learning objectives to inform training strategies. ",
          "value": false
        },
        {
          "unit": "levelB5",
          "question": "Can conduct training activities with different stakeholder groups",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design an OHS training program that meets sector best practice standards. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon OHS training programs to ensure they remain up to date with changes in the context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design training activities to meet future training needs. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others how to perform OHS training activities with high levels of worker engagement and retention",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "504",
    "natDesc": "Can navigate file management systems and maintain documentation for compliance with OHSMS requirements.",
    "questionHeader": "504: OHSMS Documentation",
    "questionText": "OHS professionals must navigate file management systems and maintain documentation for compliance with OHSMS requirements.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain sources of OHS information both internal and external to the organization. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify systems for managing OHS information. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize relevant legal requirements and other requirements that pertain to OHS documentation",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess an organization for good OHS document collection and file management processes. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor OHS document and information systems. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research best practices to make recommendations for improvement to existing systems",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and improve the effectiveness of OHS documentation collection and file management processes. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate the importance of proper OHS document collection and file management to all employees in the organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design OHS document collection and file management processes that meet an organization's OHS needs. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can innovate upon OHS document collection and file management processes to ensure compliance with OHS regulations",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "505",
    "natDesc": "Can establish and maintain procedures for evaluating and improving an organization's OHS performance.",
    "questionHeader": "505: Performance Monitoring and Measurement",
    "questionText": "OHS professionals must establish and maintain procedures for evaluating and improving an organization's OHS performance.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the different sources or modes of failure within a specific risk control. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow qualitative or quantitative performance monitoring procedures. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can explain different kinds of performance measures. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the role of health surveillance and health assessment in performance monitoring and measurement",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the effectiveness of an OHS policy, objective, or procedure through data gathered from performance monitoring. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the results and interpretation of performance measurement to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor a regular OHS performance monitoring process",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate current processes for monitoring OHS performance. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design OHS performance measures that are adaptable to changes in the context of an organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach the principles of OHS performance monitoring and measurement to other OHS professionals",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "506",
    "natDesc": "Can conduct regular and comprehensive internal audits of policies, objectives, and procedures.",
    "questionHeader": "506: Internal Auditing",
    "questionText": "OHS professionals must conduct regular and comprehensive internal audits of policies, objectives, and procedures.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the different types of OHSMS audits. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify and communicate with other OHS professionals and experts about the findings and scores from a completed OHSMS audit. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the different types of evidence used during OHSMS audits, such as documentation, observation, and interviews. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the purpose of regular OHSMS audits",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess an organization's compliance with its auditing legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can assess different sources of evidence during an OHSMS audit. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can communicate audit findings to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can conduct a documentation review. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can conduct a gap analysis. ",
          "value": false
        },
        {
          "unit": "levelB5",
          "question": "Can implement and monitor an audit using an audit workplan",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design a post-audit action plan that identifies areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate audit findings and recommendations to management in a clear and concise manner. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design an audit workplan to ensure all necessary areas are covered during the audit process. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve any discrepancies or issues identified during the audit process to ensure compliance with OHS regulations. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach OHS professionals about how to conduct an OHSMS audit and how to effectively communicate audit findings",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "507",
    "natDesc": "Can identify opportunities for improvement and make decisions related to changes in an organization's OHSMS.",
    "questionHeader": "507: Continual Improvement",
    "questionText": "OHS professionals must identify opportunities for improvement and make decisions related to changes in an organization's OHSMS.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can follow established procedures for reporting and addressing any discrepancies or concerns about OHS policies, objectives, and procedures. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize how OHS policies, objectives, and procedures interact with worker behaviour. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can review OHS policies, objectives, and procedures to ensure compliance with relevant legal requirements and other requirements",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate with senior management about the importance of continuous improvement and its impact on the organization. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and maintain practices for the continuous improvement of an OHSMS",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an OHSMS for its compatibility with organizational goals across changes in the context of an organization. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate OHS policies, objectives, or procedures to adapt to changes in the context of the organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "508",
    "natDesc": "Can understand the fundamental concepts and principles of quality management.",
    "questionHeader": "508: Quality Management",
    "questionText": "OHS professionals must understand the fundamental concepts and principles of quality management.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify interactions between an OHSMS and other workplace systems. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize the value of quality management when managing an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can review different quality management resources and activities",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the benefits of quality management to stakeholders, emphasizing its role in preventing worker harm. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor resources and activities for the quality management of an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research best practices in integrating quality management within OHS-related policies, objectives, and procedures",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of quality management practices for an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon quality management resources and processes to adapt to changes in the context of the organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "601",
    "natDesc": "Can demonstrate commercial awareness.",
    "questionHeader": "601: Principles of Commercial Awareness",
    "questionText": "OHS professionals must demonstrate commercial awareness.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain concepts related to business organization, project management, and operations management. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain different organizational structures. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify different project planning practices. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can identify different operations management practices",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can conduct the delivery of OHS resources across an organization. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate OHS considerations and concerns with stakeholders during project planning. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can assess the relationship between a project plan, OHS concerns, and the organization to stakeholders",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze a strategic plan for OHS concerns to identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate effectively with senior management about non-OHS organization policies, objectives, and procedures.. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve OHS issues by conducting thorough investigations and implementing corrective actions",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "602",
    "natDesc": "Can participate in structures and processes that control organizational decision making.",
    "questionHeader": "602: Corporate Governance",
    "questionText": "OHS professionals must participate in structures and processes that control organizational decision making.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the rationale and application of different governance procedures. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow the application of governance procedures including terms of reference and meeting rules. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize relevant legal requirements and other requirements that pertain to incorporating OHS within corporate governance",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess existing corporate governance structures and processes to identify potential hazards. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders to address identified gaps in OHS governance policies. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor OHS-relevant governance mechanisms",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze an existing corporate governance structure or process to determine its effectiveness in preventing worker harm. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate corporate governance procedures to address changes in the context of an organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "603",
    "natDesc": "Can understand how organizational risk impacts policies, objectives, and procedures.",
    "questionHeader": "603: Organizational Risk",
    "questionText": "OHS professionals must understand how organizational risk impacts policies, objectives, and procedures.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify different sources of organizational risk. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize how OHS objectives interact with organizational decision-making processes. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize how the context of an organization impacts levels of organizational risk tolerance. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize legal requirements and other requirements regarding acceptable levels of organizational risk",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess OHS decisions based on levels and severity of organizational risk. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders regarding OHS policies and their impact on organizational risk",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the impact of OHS decisions in producing or mitigating organizational risk. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design effective OHS policies and procedures that reduce organizational risk and promote a safe work environment",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "604",
    "natDesc": "Can integrate OHS considerations into the overall business strategy.",
    "questionHeader": "604: Strategic Plans",
    "questionText": "OHS professionals must integrate OHS considerations into the overall business strategy.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the key components of a strategic plan. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify the various resources and practices involved in developing a strategic plan. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize how day-to-day operations within an organization can impact the success of a strategic plan. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the importance of strategic planning",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can develop and implement specific OHS concepts into overall strategic planning and communicate these effectively with stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor OHS objectives within day-to-day operations",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate OHS strategies using key performance indicators and by setting realistic objectives. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design new OHS strategies that are adaptable to changes in the organizational context. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve complex issues related to integrating OHS strategies within organizational objectives",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "605",
    "natDesc": "Can work with an HRM and understand its concepts.",
    "questionHeader": "605: Human Resource Management",
    "questionText": "OHS professionals must work with an HRM and understand its concepts.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain how the principles of human resource management are related to OHS. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize how worker accommodations  can be integrated within an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the importance of soft skills in the workplace",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate effectively with stakeholders about applicants during job interviews or onboarding. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and implement soft skills such as active listening and empathy to promote a positive safety culture. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor processes for maintaining worker documentation",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of collaboration between OHS and HRM. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon workforce onboarding or work modification processes to make them more effective",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "606",
    "natDesc": "Can facilitate discussion and negotiation when resolving worker safety concerns.",
    "questionHeader": "606: Worker Representation",
    "questionText": "OHS professionals must facilitate discussion and negotiation when resolving worker safety concerns.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate the elements of a JHSC meeting. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the differences between a JHSC and an HSR. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can follow governance procedures at JHSC meetings. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements that pertain to worker participation within OHS",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the state of a JHSC or HSR for conformance to legal requirements and other requirements. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct consulting duties for worker representatives. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor training programs for worker representatives. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and evaluate best practices for communicating OHS information to worker representatives",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and assess the effectiveness of worker representation within OHS. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design policies and procedures that promote worker representation within OHS . ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve and prevent conflicts problems related to OHS by leading or chairing a JHSC. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can design and teach worker representatives courses about OHS principles and best practices",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "607",
    "natDesc": "Can estimate costs and work within budgetary constraints to prevent worker harm efficiently.",
    "questionHeader": "607: Budgeting and OHS",
    "questionText": "OHS professionals must estimate costs and work within budgetary constraints to prevent worker harm efficiently.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain how to interpret budgets. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify different types of financial statements. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize resources and activities that are involved in creating and managing budgets. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review important financial metrics",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess an OHS initiative's performance using its budget. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the return on investment of an OHS initiative to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor a budget for an OHS initiative",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the context of the organization to develop feasible budgets for OHS initiatives. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can analyze the budgetary performance of an OHS initiative to ensure its successful implementation",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "608",
    "natDesc": "Can develop proactive strategies for promoting worker health and corporate social responsibility.",
    "questionHeader": "608: Corporate Social Responsibility",
    "questionText": "OHS professionals must develop proactive strategies for promoting worker health and corporate social responsibility.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify the benefits of implementing sustainable business practices. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify practices to reduce an organization's environmental footprint. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the legal requirements and other requirements related to an organization's commitments to external stakeholders",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the impact of company decisions on different populations and the environment. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate corporate social responsibility principles to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor the principles of corporate social responsibility within different OHS activities",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing OHS policies and programs to ensure compliance with corporate social responsibility requirements. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design new strategies and initiatives to promote worker health and corporate social responsibility within an organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "701",
    "natDesc": "Can interpret and apply legislation related to workplace health and safety.",
    "questionHeader": "701: Legislation",
    "questionText": "OHS professionals must interpret and apply legislation related to workplace health and safety.",
    "suggested": "levelC",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate effectively with workers about their rights and responsibilities under OHS legislation. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the process for filing a complaint under employment standards legislation. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize potential hazards in the workplace and take appropriate action to mitigate them",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate with stakeholders about the key elements and relevance of OHS legislation. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement regular reviews of OHS policies, objectives, and procedures in response to changes in legal requirements. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can conduct regular reviews to promote organizational understanding of its legal requirements",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze existing OHS policies, objectives, and procedures for compliance with legal requirements. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design workplace practices for continually ensuring conformity with legal requirements. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve issues with legal compliance by developing and implementing corrective action plans. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals how to apply legal requirements to their workplace and develop effective compliance strategies",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "702",
    "natDesc": "Can uphold ethical obligations and promote high standards of professionalism.",
    "questionHeader": "702: Ethics and Conduct",
    "questionText": "OHS professionals must uphold ethical obligations and promote high standards of professionalism.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify the rights, responsibilities, and accountabilities of OHS professionals. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can communicate potential conflicts of interest involving oneself to other OHS professionals and experts. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the impact of whistleblowing on an organization. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements for an OHS professional",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the severity of a conflict of interest. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and maintain procedures for ensuring confidentiality. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can conduct oneself with impartiality and collegiality",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design disciplinary processes that are fair and well-founded. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can teach ethical conduct to other OHS professionals. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies, objectives, or procedures to effectively resolve conflicts of interest. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can innovate upon professional codes of conduct to better align with changes in the context of an organization. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can resolve ethical dilemmas in a professional and ethical manner",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "703",
    "natDesc": "Can communicate effectively with non-professionals and work collaboratively with others.",
    "questionHeader": "703: Effective Communication",
    "questionText": "OHS professionals must communicate effectively with non-professionals and work collaboratively with others.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify communication barriers between different cultural or language groups. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize appropriate forms of communication for different contexts or messages. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize non-verbal communication cues and their meanings. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the differences between technical and non-technical conversations. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can review strategies for effective oral communication in presentations, meetings, and conversations",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess current communication strategies to identify areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate technical and non-technical issues and ideas to various audiences using clear and concise language. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and deliver presentations on OHS policies, objectives, and procedures using plain, audience-appropriate language. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research best practices for effective communication within the context of OHS",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can design communication processes to adapt to changes in the context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can evaluate and analyze previous records of communication to identify areas of excellence and areas for improvement. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach OHS professionals about how to write audience-appropriate documents about OHS concepts",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "704",
    "natDesc": "Can apply decision-making theories, consider organizational decisions, and communicate their rationale effectively.",
    "questionHeader": "704: Decision-Making",
    "questionText": "OHS professionals must apply decision-making theories, consider organizational decisions, and communicate their rationale effectively.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify relevant sources of data related to OHS decision making. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize the importance of incorporating data within decision making",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the quality and reliability of evidence and data gathered to support decision-making. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct stakeholder consultations to gather input and perspectives for decision-making. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor OHS decision-making models. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and analyze industry trends and best practices in OHS decision-making",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze raw or academic research data to inform decision-making. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can resolve complex research questions to deliver decisions to an organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach decision-making models and theories to other OHS professionals",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "705",
    "natDesc": "Can utilize resources and activities to promptly resolve new and intricate OHS issues in the workplace.",
    "questionHeader": "705: Problem Solving",
    "questionText": "OHS professionals must utilize resources and activities to promptly resolve new and intricate OHS issues in the workplace.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain potential problems in a workplace that emerge between people, organizational objectives, and the environment. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify surface-cause and root-cause problems related to a specific workplace issue. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize various problem-solving resources and processes",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a current OHS problem for similarities with former, solved OHS problems. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor problem-solving resources and processes to address non-complex OHS problems. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research additional information and evidence when problem solving",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can develop policies, objectives, and procedures that address and prevent the recurrence of identified problems. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon existing resources and practices to address complex OHS problems. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach others OHS professionals about problem-solving within the context of OHS",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "706",
    "natDesc": "Can manage and document processes and resources, monitor for continual improvement, and prioritize tasks.",
    "questionHeader": "706: Project Management",
    "questionText": "OHS professionals must manage and document processes and resources, monitor for continual improvement, and prioritize tasks.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can follow project timelines and deadlines. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify different project management resources and practices. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the key stakeholders involved in a project. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review the components of a project budget",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate project updates to stakeholders through regular meetings and progress reports. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor a project plan throughout all stages, including implementation and continual improvement",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze data to optimize project delivery. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate upon a project plan to incorporate improvements. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve problems related to project scope, budget, and time constraints. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach effective project management processes to other OHS professionals",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "707",
    "natDesc": "Can build and maintain relationships with stakeholders.",
    "questionHeader": "707: Work Relationship Management",
    "questionText": "OHS professionals must build and maintain relationships with stakeholders.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate effectively with workers and senior management regarding OHS concerns and incidents. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain how the various stakeholder groups involved with OHS activities impact OHS objectives and performance. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can follow established ethical standards when addressing stakeholder concerns with OHS processes",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess how OHS activities impact the organizational culture on OHS. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can assess the interests and values of the stakeholder groups involved with an OHS activity. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can communicate effectively with both internal and external stakeholders about OHS processes and initiatives",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze stakeholder feedback to inform decision making. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design messaging strategies for different stakeholder groups. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate upon current practices to address gaps in stakeholder engagement. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve complex problems between stakeholder groups through effective negotiation",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "708",
    "natDesc": "Can identify and address potential sources of worker harm.",
    "questionHeader": "708: Professional Development",
    "questionText": "OHS professionals must identify and address potential sources of worker harm.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can follow general guidance for OHS professional development. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify personal and professional objectives. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the value of professional networks. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can communicate with other OHS professionals and experts about one's own professional development progress",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess one's own professional development needs with existing resources and processes. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct an independent learning process. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor a personal professional development plan",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the impact of a professional development plan. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design OHS professional development programs for others that align with organizational and personal objectives. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach other OHS professionals how to develop an effective personal professional development plan for themselves",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "801",
    "natDesc": "Can identify and control the risk presented by hazardous energy.",
    "questionHeader": "801: Hazardous Energy",
    "questionText": "OHS professionals must identify and control the risk presented by hazardous energy.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify different lockout devices. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize legal requirements and other requirements that pertain to hazardous energy control. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize machinery, equipment, or processes that produce hazardous energy. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the importance of a hazardous energy control program",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a workplace for the risk of hazardous energy release. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop training materials and conduct training sessions for workers to participate in a hazardous energy control program. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor a hazardous energy control program. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor a lockout program. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research and stay up to date on the best practices for managing hazardous energy sources",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and improve the effectiveness of existing OHS policies and procedures in preventing the hazardous release of energy. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a comprehensive lockout program to address new or emerging hazardous energy sources. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate new hazardous energy control programs in response to changes in the context of an organization. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals the fundamentals of risk assessment for hazardous energies",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "802",
    "natDesc": "Can identify potential hazards and implement appropriate safeguards to prevent incidents and injuries.",
    "questionHeader": "802: Safeguarding Machinery and Equipment",
    "questionText": "OHS professionals must identify potential hazards and implement appropriate safeguards to prevent incidents and injuries.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about hazards requiring safeguarding. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify different types of safeguards, such as adjustable guards, fixed guards, and self-closing guards. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize legal requirements and other requirements that pertain to safeguarding equipment and machinery",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the effectiveness of current safeguards. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders about policies and procedures related to safeguarding. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor safeguards in line with the hierarchy of controls. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices in safeguarding",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing policies and procedures for safeguarding. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design safeguards that are effective and efficient in preventing workplace accidents and injuries. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures in response to changes in context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others how to develop policies and procedures for safeguarding",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "803",
    "natDesc": "Can identify hazards and implement risk controls to avoid harm to workers caused by electricity.",
    "questionHeader": "803: Electrical Systems",
    "questionText": "OHS professionals must identify hazards and implement risk controls to avoid harm to workers caused by electricity.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the proper safety practices when using power tools. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify and discuss electrical safety hazards in the workplace with other OHS professionals and experts. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the different types of harm resulting from electrical currents. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the relevant legal requirements and other requirements that pertain to working with electrical systems",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess workplaces for improperly controlled electrical hazards and risks. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and conduct training programs for OHS policies and procedures related to working with electrical systems. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor safety procedures and controls for electrical equipment. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research best practices for electrical safety",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze data on workplace incidents related to electrical safety and use it to identify areas for improvement in existing policies and procedures. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate effectively with stakeholders to ensure that electrical safety policies and procedures are understood and followed. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design and implement training programs in electrical safety that incorporates interactive and hands-on learning methods. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can innovate OHS policies and procedures for electrical safety in response to newly introduced or previously identified hazards. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can innovate OHS policies and procedures to integrate the latest advancements in electrical safety technology",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "804",
    "natDesc": "Can communicate knowledge effectively to people who perform welding and similar activities.",
    "questionHeader": "804: Welding and Allied Processes",
    "questionText": "OHS professionals must communicate knowledge effectively to people who perform welding and similar activities.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify appropriate risk controls for work involving welding or an allied process. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize chemical hazards associated with welding and allied processes, such as toxic fumes, vapours, gases, and particulate. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the legal requirements and other requirements that pertain to welding and allied processes. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the physical hazards associated with welding and allied processes, such as fire, radiation, and electric shock",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risks presented by welding or an allied process. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop training programs for safe processes in welding and allied processes. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor safety systems for welding and allied processes. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for safety during welding and allied processes",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing policies and procedures in promoting safety during welding and allied processes. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate and resolve any issues or concerns related to safety during welding and allied processes. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate OHS policies and procedures for welding and allied processes in response to changes in context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the fundamentals of safe processes for welding and allied processes",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "805",
    "natDesc": "Can collaborate with specialists in managing chemical and biological agents.",
    "questionHeader": "805: Chemical Agents and Biological Agents",
    "questionText": "OHS professionals must collaborate with specialists in managing chemical and biological agents.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the key principles about chemical and biological agent hazards, such as systems of classification and methods of exposure. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify chemical and biological agents in a workplace and their associated controls. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify sources of safety information on chemical and biological agents. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can follow relevant legal requirements and other requirements pertaining to chemical and biological agents in a workplace",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risk associated with working with chemical and biological agents. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor safety procedures for working with chemical and biological agents. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research and stay up to date on the best practices for working with chemical and biological agents",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing policies and procedures in promoting safe work processes with chemical and biological agents. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a comprehensive training program for safely working with chemical and biological agents. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for working with chemical and biological agents in response to the introduction of new agents. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others about the fundamentals of managing chemical and biological agents",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "806",
    "natDesc": "Can identify and manage potential hazards associated with combustible dust in their workplace.",
    "questionHeader": "806: Combustible Dust",
    "questionText": "OHS professionals must identify and manage potential hazards associated with combustible dust in their workplace.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain effective controls for combustible dust safety in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain workplace events that may produce a dust explosion, such as through the dust explosion pentagon. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the importance of thorough inspections and proper storage for combustible dust safety. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the relevant legal requirements and other requirements that pertain to combustible dusts",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess workplace hazards related to combustible dust. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the importance of following policies and procedures related to combustible dust hazards to all stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor risk controls to mitigate the risks associated with combustible dust hazards. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for assessing the risk of combustible dust",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing policies and procedures for combustible dust safety. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and implement comprehensive training programs on combustible dust safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for combustible dust safety in response to identified and emerging hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others about the fundamentals of combustible dust safety",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "807",
    "natDesc": "Can establish processes for safely working in confined spaces.",
    "questionHeader": "807: Work in Confined Spaces",
    "questionText": "OHS professionals must establish processes for safely working in confined spaces.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate the necessary safety measures and precautions to be taken before entering a confined space. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow existing safety procedures used in confined spaces. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the potential hazards and risks associated with a confined space. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the legal requirements and other requirements related to working in confined spaces. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize the roles and responsibilities of senior management, the entry team, and emergency response team",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess a workplace for risks presented by confined spaces. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct atmospheric testing within confined spaces. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and implement a communication plan to inform stakeholders about policies and procedures related to confined spaces. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor entry and egress procedures. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research and stay up to date on the best practices for working in confined spaces",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing policies and procedures for confined space safety and recommend improvements. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a comprehensive training program on confined space safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for confined space safety in response to new and emerging hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the fundamentals of managing confined space safety and provide practical examples",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "808",
    "natDesc": "Can establish safe work processes for work at heights.",
    "questionHeader": "808: Work at Heights",
    "questionText": "OHS professionals must establish safe work processes for work at heights.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can recognize the relevant legal requirements and other requirements that pertain to working at heights. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify hazards presented by working at heights. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the importance of the proper selection and use of fall protection systems when working at heights. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review risk controls for working at heights, such as guardrails, control zones, and safety monitors",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risk presented by working at heights. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the importance of safety during work at heights to all stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and deliver training for policies and procedures in working at heights. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor risk controls for working at heights. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research and stay up to date on the best practices for working at heights",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing OHS policies and procedures for work at heights. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design a comprehensive training program for safety during work at heights. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate OHS policies and procedures for work at heights processes in response to changes in context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the fundamentals of implementing fall protection",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "809",
    "natDesc": "Can develop safety controls specific to the equipment used in their workplace.",
    "questionHeader": "809: Mobile Equipment",
    "questionText": "OHS professionals must develop safety controls specific to the equipment used in their workplace.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the operating principles of commonly used mobile equipment. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow the proper use of mobile equipment attachments and potential hazards associated with these attachments. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the different types of mobile equipment in the workplace and their associated hazards. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize relevant legal requirements and other requirements that pertain to mobile equipment safety. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can review risk controls for mobile equipment",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risk presented by specific pieces of mobile equipment. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop training materials and programs for the safe use of mobile equipment. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research and stay up to date on the best practices for safely using mobile equipment",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing OHS policies and procedures in preventing hazardous events involving mobile equipment. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design comprehensive training programs in the safe operation of mobile equipment. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate OHS policies and procedures in response to the introduction of new mobile equipment. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the fundamentals of mobile equipment safety management",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "810",
    "natDesc": "Can advise on the handling, transportation, storage, and disposal of dangerous goods.",
    "questionHeader": "810: Transportation of Dangerous Goods",
    "questionText": "OHS professionals must advise on the handling, transportation, storage, and disposal of dangerous goods.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain the purpose and structure of WHMIS and GHS. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow relevant legal requirements and other requirements that pertain to the transportation of dangerous goods. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize dangerous goods in use at a workplace. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review pictograms, labels, and safety data sheets for dangerous goods",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the level of risk presented by the transport of a specific dangerous good. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop training materials and conduct training sessions for policies and procedures for the transport of dangerous goods. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor OHS policies and procedures for the transport of dangerous goods. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for the transport of dangerous goods",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing policies and procedures for the transport of dangerous goods. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate new approaches to comprehensive training programs for safely transporting dangerous goods. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate OHS policies and procedures in response to new or emerging dangerous goods hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others OHS professionals about the fundamentals of safety management for dangerous goods",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "811",
    "natDesc": "Can provide guidance on creating and maintaining a safe working environment for shipping and receiving operations.",
    "questionHeader": "811: Shipping and Receiving",
    "questionText": "OHS professionals must provide guidance on creating and maintaining a safe working environment for shipping and receiving operations.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify controls for shipping and receiving risks in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize legal requirements and other requirements that pertain to shipping and receiving safety. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize shipping and receiving hazards in a workplace",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risks presented by shipping and receiving. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and conduct training programs for safe shipping and receiving. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor OHS policy and procedure for shipping and handling. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for addressing shipping and receiving",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of current policies and procedures in promoting safe shipping and receiving processes. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate policies and procedures for shipping and receiving safety in response to changes in context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach OHS professionals about the fundamentals of shipping and receiving safety management",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "812",
    "natDesc": "Can develop and implement an OHSMS for contractors.",
    "questionHeader": "812: Contractor Health and Safety",
    "questionText": "OHS professionals must develop and implement an OHSMS for contractors.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about weaknesses in contractor safety management processes. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can explain the resources and practices involved in contractor management. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize how contractor safety management integrates with an OHSMS. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the relevant legal requirements and other requirements that pertain to contractor safety",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess contractor compliance with OHS policies and procedures. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can assess the adherence of contractors to workplace safety measures and procedures. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop onboarding and refreshing programs for contractors on an organization's OHS policies and procedures, and workplace safety measures and procedures. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for contractor management and workplace safety",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze incident reports and identify trends to improve contractor safety performance. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design interactive and engaging training modules to enhance contractor safety awareness. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures processes in response to changes in contractor relationships. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve OHS-relevant conflicts between contractors and internal stakeholders, such as workers and senior management, through effective communication and negotiation. ",
          "value": false
        },
        {
          "unit": "levelC4",
          "question": "Can teach others OHS professionals about the fundamentals of contractor management",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "813",
    "natDesc": "Can manage and control the risks introduced by technology and automation.",
    "questionHeader": "813: Smart Manufacturing",
    "questionText": "OHS professionals must manage and control the risks introduced by technology and automation.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate hazards involving smart manufacturing technologies, including the potential for malicious attacks, to other OHS professionals and experts. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow OHS-relevant cyber security standards and guidelines. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify smart manufacturing technologies and associated hazards. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the relevant legal requirements and other requirements that pertain to smart manufacturing",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate the significance of cyber security in the smart manufacturing workplace to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement and monitor expert advice to promote safety in smart manufacturing. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research and stay up to date on the best practices for managing safety during smart manufacturing",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing OHS policies and procedures for smart manufacturing. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and implement comprehensive training programs for safe work in smart manufacturing. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for cyber security in response to the introduction of new technologies. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can resolve any issues related to worker safety in smart manufacturing by implementing new policies and procedures",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "814",
    "natDesc": "Can identify musculoskeletal injury hazards and prevent worker harm.",
    "questionHeader": "814: Ergonomics",
    "questionText": "OHS professionals must identify musculoskeletal injury hazards and prevent worker harm.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about preventing and treating musculoskeletal injuries in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow advice from ergonomists to reduce the risk of harm. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify the signs and symptoms of musculoskeletal injuries. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize the relevant legal requirements and other requirements that pertain to ergonomics. ",
          "value": false
        },
        {
          "unit": "levelA4",
          "question": "Can recognize workplace scenarios that present musculoskeletal hazards",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risk presented by musculoskeletal injury hazards. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can conduct an ergonomic assessment to identify potential hazards and risks. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and implement training programs for workers on proper ergonomic work processes. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor ergonomic solutions to address identified risks. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research and stay up to date on the best practices for preventing musculoskeletal injuries",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing policies and procedures in promoting ergonomic safety. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and develop comprehensive training programs in OHS policies and procedures for ergonomic safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for ergonomic safety in response to newly introduced or previously identified hazards. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others OHS professionals about the fundamentals of risk assessment for musculoskeletal injury hazards",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "815",
    "natDesc": "Can prevent occupational diseases by understanding workplace factors.",
    "questionHeader": "815: Occupational Hygiene",
    "questionText": "OHS professionals must prevent occupational diseases by understanding workplace factors.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about instances of occupational disease. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify sources of physical, chemical, and biological hazards in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize relevant legal requirements and other requirements that pertain to occupational hygiene",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risk of occupational disease within a workplace. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate effectively with stakeholders regarding policies and procedures related to occupational hygiene hazards and the importance of implementing them. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor control measures to minimize occupational hygiene hazards. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research best practices in occupational hygiene",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing policies and procedures in promoting occupational hygiene, and innovate new strategies as needed. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and implement comprehensive training programs to prevent occupational diseases. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate occupational hygiene management processes in response to changes in the context of the organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "816",
    "natDesc": "Can properly assess and manage hazards associated with cranes and hoists.",
    "questionHeader": "816: Cranes and Hoists",
    "questionText": "OHS professionals must properly assess and manage hazards associated with cranes and hoists.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can explain capacity ratings and load charts. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify hazards related to using cranes and hoists. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize legal requirements and other requirements that pertain to cranes and hoists",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risks presented by using cranes and hoists. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and conduct training programs for the safe use of cranes and hoists. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor OHS policies and procedures for cranes and hoists. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for using cranes and hoists",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing policies and procedures in promoting the safe operation of cranes and hoists. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate OHS policies and procedures for cranes and hoists in response to the introduction of new equipment. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can teach others OHS professionals about the fundamentals of cranes and hoists safety management",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "817",
    "natDesc": "Can identify ionizing radiation-emitting devices and develop control measures to ensure a safe work environment.",
    "questionHeader": "817: Ionizing Radiation",
    "questionText": "OHS professionals must identify ionizing radiation-emitting devices and develop control measures to ensure a safe work environment.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can follow procedures for handling sources of ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can identify sources of ionizing radiation in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize legal requirements and other requirements that pertain to ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review hazards or hazardous events involving ionizing radiation exposure with other OHS professionals and experts",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess workplace hazards presented by sources of ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and conduct training in ionizing radiation safety. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can evaluate the effectiveness of ionizing radiation safety training. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor safety procedures for ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research and stay up to date on the best practices for addressing ionizing radiation",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of existing policies and procedures in promoting ionizing radiation safety. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can design and implement a comprehensive training program in ionizing radiation safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for ionizing radiation safety management processes in response to changes in context of the organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "818",
    "natDesc": "Can identify instances of worker exposure and implement measures to control hazards of non-ionizing radiation.",
    "questionHeader": "818: Non-ionizing Radiation",
    "questionText": "OHS professionals must identify instances of worker exposure and implement measures to control hazards of non-ionizing radiation.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate with other OHS professionals and experts about hazards and hazardous events involving non-ionizing radiation exposure. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can follow procedures for handling sources of non-ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can identify sources of non-ionizing radiation in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can recognize legal requirements and other requirements that pertain to non-ionizing radiation",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess workplace hazards presented by sources of non-ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can communicate the importance of ionizing radiation safety to stakeholders and provide information on policies and procedures. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can develop and conduct training sessions on non-ionizing radiation safety. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can implement and monitor safety procedures for non-ionizing radiation. ",
          "value": false
        },
        {
          "unit": "levelB4",
          "question": "Can research and stay up to date on the best practices for addressing non-ionizing radiation",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze the effectiveness of existing policies and procedures in promoting non-ionizing radiation safety. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate comprehensive training programs in non-ionizing radiation safety. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can innovate policies and procedures for non-ionizing radiation safety management processes in response to changes in context of the organization",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "819",
    "natDesc": "Can navigate an organization's supply chain to ensure alignment with OHS objectives.",
    "questionHeader": "819: Supply Chains",
    "questionText": "OHS professionals must navigate an organization's supply chain to ensure alignment with OHS objectives.",
    "suggested": "levelA",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can identify effective controls for supply chain safety in the workplace. ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize legal requirements and other requirements pertaining to supply chain safety. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize supply chain safety hazards and risks in a workplace. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review and discuss supply chain safety hazards or issues with other OHS professionals and experts",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can communicate policies and procedures related to safety along the supply chain to stakeholders. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can implement training in OHS policies and procedures for organizations along the supply chain. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can research the developing relationship between OHS and supply chain management",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate an existing supply chain to identify strengths and opportunities to promote safety along the supply chain. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can communicate the importance of supply chain safety to stakeholders and team members. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can design policies and procedures that promote safety across the supply chain. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach OHS professionals about the fundamentals of OHS objectives and supply chain management",
          "value": false
        }
      ]
    }
  },
  {
    "unit": "820",
    "natDesc": "Can support workers who are isolated in their workplace.",
    "questionHeader": "820: Lone Workers",
    "questionText": "OHS professionals must support workers who are isolated in their workplace.",
    "suggested": "levelB",
    "enabled": true,
    "answerText": {
      "levelA": [
        {
          "unit": "levelA0",
          "question": "Can communicate effectively with team members during a crisis involving a lone worker . ",
          "value": false
        },
        {
          "unit": "levelA1",
          "question": "Can recognize legal requirements and other requirements that pertain to lone workers. ",
          "value": false
        },
        {
          "unit": "levelA2",
          "question": "Can recognize the hazards associated with working alone. ",
          "value": false
        },
        {
          "unit": "levelA3",
          "question": "Can review safety protocols for working in confined spaces",
          "value": false
        }
      ],
      "levelB": [
        {
          "unit": "levelB0",
          "question": "Can assess the risks presented by working alone. ",
          "value": false
        },
        {
          "unit": "levelB1",
          "question": "Can develop and deliver effective training programs for lone workers. ",
          "value": false
        },
        {
          "unit": "levelB2",
          "question": "Can implement and monitor OHS policies and procedures for working alone. ",
          "value": false
        },
        {
          "unit": "levelB3",
          "question": "Can research and stay up to date on the best practices for addressing working alone",
          "value": false
        }
      ],
      "levelC": [
        {
          "unit": "levelC0",
          "question": "Can analyze and evaluate the effectiveness of current policies and procedures for lone workers and innovate new ones to improve safety. ",
          "value": false
        },
        {
          "unit": "levelC1",
          "question": "Can innovate policies and procedures for lone workers in response to changes in the context of the organization. ",
          "value": false
        },
        {
          "unit": "levelC2",
          "question": "Can resolve any issues or concerns related to the safety of lone workers by communicating with relevant stakeholders and designing solutions. ",
          "value": false
        },
        {
          "unit": "levelC3",
          "question": "Can teach others about managing the safety of lone workers and communicate the importance of implementing effective policies and procedures",
          "value": false
        }
      ]
    }
  }
];

export default natSurveyData;