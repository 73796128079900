// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.admin-sidebar .nav-link.active {
  background: #00863F;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  gap: 10px;
  color: #fff;
}

.admin-sidebar .nav-link {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
  margin-top: 24px;
  display: flex;
  gap:10px;
}
.admin-sidebar .settings-menu.nav-link{
  color: #4C4C4C;
  font-weight: 400;
}
.admin-sidebar .logout-menu.nav-link{
  color: #A65959;
  font-weight: 400;
}
.admin-sidebar #sidebar-nav{
  max-width: 167px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/screens/sidebar/sidebar.css"],"names":[],"mappings":";;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,SAAS;EACT,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,QAAQ;AACV;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":["\n\n.admin-sidebar .nav-link.active {\n  background: #00863F;\n  border-radius: 4px;\n  padding: 10px;\n  display: flex;\n  gap: 10px;\n  color: #fff;\n}\n\n.admin-sidebar .nav-link {\n  font-family: Open Sans;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #808080;\n  margin-top: 24px;\n  display: flex;\n  gap:10px;\n}\n.admin-sidebar .settings-menu.nav-link{\n  color: #4C4C4C;\n  font-weight: 400;\n}\n.admin-sidebar .logout-menu.nav-link{\n  color: #A65959;\n  font-weight: 400;\n}\n.admin-sidebar #sidebar-nav{\n  max-width: 167px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
