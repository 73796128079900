import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../index.css";
import "../../assets/css/app.css";
import AdminSidebar from "../sidebar/AdminSidebar";
import AdminHeader from "../header/AdminHeader";

const  LayoutRoute = () => {

  return ( 
      <Container fluid>
        <Row>
          <Col xs={12} lg={2} className="sidebar">
            <AdminSidebar />
          </Col>
          <Col xs={12} lg={10}>
            <AdminHeader />
            <Container fluid>
             <Outlet/>
            </Container>
          </Col>
        </Row>
      </Container>
  );
};

export default LayoutRoute;