import React from 'react';
import Table from 'react-bootstrap/Table';
import { Image, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import Chart from './chart';
import autoTable from 'jspdf-autotable';
import title from './title.json';
import programData from './reportTableData';
import { getFormattedTime } from '../utils/utils';

const ReportTableFilled = (props) => {

    const tableHeadings = ['', 'Date', 'Report', 'PDF'];

    const exportMultipleChartsToPdf = async (id, dateTime) => {
        const doc = new jsPDF("p", "px");
        const elements = document.getElementsByClassName('custom_' + id);
        await createPdf(doc, elements, id);
        doc.save(`Report ${dateTime}.pdf`);
    };

    async function createPdf(doc, elements, id) {
        const padding = 10;
        const marginTop = 30;
        let top = marginTop;

        for (let i = 0; i < elements.length; i++) {
            const el = elements.item(i);
            const imgData = await htmlToImage.toPng(el);

            let elHeight = el.offsetHeight;
            let elWidth = el.offsetWidth;

            const pageWidth = doc.internal.pageSize.getWidth();

            if (elWidth > pageWidth) {
                const ratio = pageWidth / elWidth;
                elHeight = elHeight * ratio - padding * 2;
                elWidth = elWidth * ratio - padding * 2;
            }

            const pageHeight = doc.internal.pageSize.getHeight();

            if (top + elHeight > pageHeight) {
                doc.addPage();
                top = marginTop;
            }

            doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
            top += elHeight + marginTop;
        }

        const headerStyles = {
            textColor: "#1c1c1c",       // Text color (black)
            fontStyle: 'normal',         // Font style (light)
            fontSize: 11
        };
        const contentStyles = {
            textColor: "#1c1c1c",       // Text color (black)
            fontStyle: 'italic',         // Font style (light)
        };

        // const table1Headings = ['Exceeds Threshold', 'Level Selected', 'Unit']; // Customize the headings
        doc.text('', 20, top + marginTop); // Add a title for the first table
        const table1Options = {
            startY: top + marginTop + 0,
            // head: [table1Headings], // Add table headings
            head: [
                [
                    {
                        content: 'Exceeds Threshold',
                        styles: {
                            fontStyle: 'bold',
                            fontSize: 12,
                            textColor: "#fff", // Text color (black)
                            fillColor: "#3666F6", // Background color
                        },
                    },
                    {
                        content: '',
                        styles: {
                            fillColor: "#3666F6", // Background color
                        },
                    },
                    {
                        content: '',
                        styles: {
                            fillColor: "#3666F6", // Background color
                        },
                    },
                ],
            ],
            body: [], // Add table data
            theme: 'striped', // Choose a theme (e.g., 'striped', 'grid', 'plain')
            columnStyles: {
                0: { cellWidth: 300 }, // Adjust the width of the first column
                1: { cellWidth: 'auto' }, // Adjust the width of the second column
                2: { cellWidth: 'auto' }, // Adjust the width of the third column
            },
        };
        table1Options.body.push(
            [{ content: `${localStorage.getItem('first_name')} exceeds the OHS professional competency threshold in the following areas:`, styles: headerStyles }]
        );
        let conditionMet1 = false;
        let conditionMet2 = false;
        let conditionMet3 = false;
        let conditionMet4 = false;
        let conditionMet5 = false;
        let conditionMet6 = false;
        let conditionMet7 = false;
        let conditionMet8 = false;
        (props.data.length !== 0) && props.data.forEach((surveyItem) => {
            if (id === surveyItem.survey_id) {
                surveyItem.qa.sort((a, b) => a.id - b.id).forEach((item) => {
                    if (item.criterion < item.label.slice(-1)) {
                        if (item.questionHeader && ["101", "102", "103", "104", "105", "106", "107", "108", "109"].includes(item.questionHeader.slice(0, 3)) && !conditionMet1) {
                            table1Options.body.push([{ content: title.title1, styles: headerStyles }]);
                            conditionMet1 = true;
                        } else if (item.questionHeader && ["201", "202", "203", "204", "205", "206", "207", "208", "209"].includes(item.questionHeader.slice(0, 3)) && !conditionMet2) {
                            table1Options.body.push([{ content: title.title2, styles: headerStyles }]);
                            conditionMet2 = true;
                        } else if (item.questionHeader && ["301", "302", "303", "304", "305", "306", "307"].includes(item.questionHeader.slice(0, 3)) && !conditionMet3) {
                            table1Options.body.push([{ content: title.title3, styles: headerStyles }]);
                            conditionMet3 = true;
                        } else if (item.questionHeader && ["401", "402", "403", "404", "405", "406", "407"].includes(item.questionHeader.slice(0, 3)) && !conditionMet4) {
                            table1Options.body.push([{ content: title.title4, styles: headerStyles }]);
                            conditionMet4 = true;
                        } else if (item.questionHeader && ["501", "502", "503", "504", "505", "506", "507", "508"].includes(item.questionHeader.slice(0, 3)) && !conditionMet5) {
                            table1Options.body.push([{ content: title.title5, styles: headerStyles }]);
                            conditionMet5 = true;
                        } else if (item.questionHeader && ["601", "602", "603", "604", "605", "606", "607", "608"].includes(item.questionHeader.slice(0, 3)) && !conditionMet6) {
                            table1Options.body.push([{ content: title.title6, styles: headerStyles }]);
                            conditionMet6 = true;
                        } else if (item.questionHeader && ["701", "702", "703", "704", "705", "706", "707", "708"].includes(item.questionHeader.slice(0, 3)) && !conditionMet7) {
                            table1Options.body.push([{ content: title.title7, styles: headerStyles }]);
                            conditionMet7 = true;
                        } else if (item.questionHeader && ["801", "802", "803", "804", "805", "806", "807", "808", "809", "810", "811", "812", "813", "814", "815", "816", "817", "818", "819", "820"].includes(item.questionHeader.slice(0, 3)) && !conditionMet8) {
                            table1Options.body.push([{ content: title.title8, styles: headerStyles }]);
                            conditionMet8 = true;
                        }
                        table1Options.body.push([item.question.slice(23).charAt(0).toUpperCase() + item.question.slice(24), item.label, item.questionHeader ? `Unit ${item.questionHeader.slice(0, 3)}` : '']);
                    }
                });
            }
        });

        autoTable(doc, table1Options);

        // const table2Headings = ['Meets Threshold', 'Level Selected', 'Unit'];
        doc.text('', 20, top + marginTop);
        const table2Options = {
            startY: top + marginTop + 230,
            // head: [table2Headings],
            head: [
                [
                    {
                        content: 'Meets Threshold',
                        styles: {
                            fontStyle: 'bold',
                            fontSize: 12,
                            textColor: "#fff", // Text color (black)
                            fillColor: "#494F54", // Background color
                        },
                    },
                    {
                        content: '',
                        styles: {
                            fillColor: "#494F54", // Background color
                        },
                    },
                    {
                        content: '',
                        styles: {
                            fillColor: "#494F54", // Background color
                        },
                    },
                ],
            ],
            body: [],
            theme: 'striped',
            columnStyles: {
                0: { cellWidth: 300 }, // Adjust the width of the first column
                1: { cellWidth: 'auto' }, // Adjust the width of the second column
                2: { cellWidth: 'auto' }, // Adjust the width of the third column
            },
        };
        table2Options.body.push(
            [{ content: `${localStorage.getItem('first_name')} meets the OHS professional competency threshold in the following areas:`, styles: headerStyles }]
        );
        let conditionMet21 = false;
        let conditionMet22 = false;
        let conditionMet23 = false;
        let conditionMet24 = false;
        let conditionMet25 = false;
        let conditionMet26 = false;
        let conditionMet27 = false;
        let conditionMet28 = false;
        (props.data.length !== 0) && props.data.forEach((surveyItem) => {
            if (id === surveyItem.survey_id) {
                surveyItem.qa.sort((a, b) => a.id - b.id).forEach((item) => {
                    if (item.criterion === item.label.slice(-1)) {
                        if (item.questionHeader && ["101", "102", "103", "104", "105", "106", "107", "108", "109"].includes(item.questionHeader.slice(0, 3)) && !conditionMet21) {
                            table2Options.body.push([{ content: title.title1, styles: headerStyles }]);
                            conditionMet21 = true;
                        } else if (item.questionHeader && ["201", "202", "203", "204", "205", "206", "207", "208", "209"].includes(item.questionHeader.slice(0, 3)) && !conditionMet22) {
                            table2Options.body.push([{ content: title.title2, styles: headerStyles }]);
                            conditionMet22 = true;
                        } else if (item.questionHeader && ["301", "302", "303", "304", "305", "306", "307"].includes(item.questionHeader.slice(0, 3)) && !conditionMet23) {
                            table2Options.body.push([{ content: title.title3, styles: headerStyles }]);
                            conditionMet23 = true;
                        } else if (item.questionHeader && ["401", "402", "403", "404", "405", "406", "407"].includes(item.questionHeader.slice(0, 3)) && !conditionMet24) {
                            table2Options.body.push([{ content: title.title4, styles: headerStyles }]);
                            conditionMet24 = true;
                        } else if (item.questionHeader && ["501", "502", "503", "504", "505", "506", "507", "508"].includes(item.questionHeader.slice(0, 3)) && !conditionMet25) {
                            table2Options.body.push([{ content: title.title5, styles: headerStyles }]);
                            conditionMet25 = true;
                        } else if (item.questionHeader && ["601", "602", "603", "604", "605", "606", "607", "608"].includes(item.questionHeader.slice(0, 3)) && !conditionMet26) {
                            table2Options.body.push([{ content: title.title6, styles: headerStyles }]);
                            conditionMet26 = true;
                        } else if (item.questionHeader && ["701", "702", "703", "704", "705", "706", "707", "708"].includes(item.questionHeader.slice(0, 3)) && !conditionMet27) {
                            table2Options.body.push([{ content: title.title7, styles: headerStyles }]);
                            conditionMet27 = true;
                        } else if (item.questionHeader && ["801", "802", "803", "804", "805", "806", "807", "808", "809", "810", "811", "812", "813", "814", "815", "816", "817", "818", "819", "820"].includes(item.questionHeader.slice(0, 3)) && !conditionMet28) {
                            table2Options.body.push([{ content: title.title8, styles: headerStyles }]);
                            conditionMet28 = true;
                        }
                        table2Options.body.push([item.question.slice(23).charAt(0).toUpperCase() + item.question.slice(24), item.label, item.questionHeader ? `Unit ${item.questionHeader.slice(0, 3)}` : '']);
                    }
                });
            }
        });

        autoTable(doc, table2Options);

        // const table3Headings = ['For Development', 'Level Selected', 'Unit'];
        doc.text('', 20, top + marginTop);
        const table3Options = {
            startY: top + marginTop + 300,
            // head: [table3Headings],
            head: [
                [
                    {
                        content: 'For Development',
                        styles: {
                            fontStyle: 'bold',
                            fontSize: 12,
                            textColor: "fff", // Text color (black)
                            fillColor: "#247A3D", // Background color
                        },
                    },
                    {
                        content: '',
                        styles: {
                            fillColor: "#247A3D", // Background color
                        },
                    },
                    {
                        content: '',
                        styles: {
                            fillColor: "#247A3D", // Background color
                        },
                    },
                ],
            ],
            body: [],
            theme: 'striped',
            columnStyles: {
                0: { cellWidth: 300 }, // Adjust the width of the first column
                1: { cellWidth: 'auto' }, // Adjust the width of the second column
                2: { cellWidth: 'auto' }, // Adjust the width of the third column
            },
        };
        table3Options.body.push(
            [{ content: `${localStorage.getItem('first_name')} would benefit from professional development in the following areas:`, styles: headerStyles }]
        );
        let conditionMet31 = false;
        let conditionMet32 = false;
        let conditionMet33 = false;
        let conditionMet34 = false;
        let conditionMet35 = false;
        let conditionMet36 = false;
        let conditionMet37 = false;
        let conditionMet38 = false;
        (props.data.length !== 0) && props.data.forEach((surveyItem) => {
            if (id === surveyItem.survey_id) {
                surveyItem.qa.sort((a, b) => a.id - b.id).forEach((item) => {
                    if (item.criterion > item.label.slice(-1)) {
                        if (item.questionHeader && ["101", "102", "103", "104", "105", "106", "107", "108", "109"].includes(item.questionHeader.slice(0, 3)) && !conditionMet31) {
                            table3Options.body.push([{ content: title.title1, styles: headerStyles }]);
                            conditionMet31 = true;
                        } else if (item.questionHeader && ["201", "202", "203", "204", "205", "206", "207", "208", "209"].includes(item.questionHeader.slice(0, 3)) && !conditionMet32) {
                            table3Options.body.push([{ content: title.title2, styles: headerStyles }]);
                            conditionMet32 = true;
                        } else if (item.questionHeader && ["301", "302", "303", "304", "305", "306", "307"].includes(item.questionHeader.slice(0, 3)) && !conditionMet33) {
                            table3Options.body.push([{ content: title.title3, styles: headerStyles }]);
                            conditionMet33 = true;
                        } else if (item.questionHeader && ["401", "402", "403", "404", "405", "406", "407"].includes(item.questionHeader.slice(0, 3)) && !conditionMet34) {
                            table3Options.body.push([{ content: title.title4, styles: headerStyles }]);
                            conditionMet34 = true;
                        } else if (item.questionHeader && ["501", "502", "503", "504", "505", "506", "507", "508"].includes(item.questionHeader.slice(0, 3)) && !conditionMet35) {
                            table3Options.body.push([{ content: title.title5, styles: headerStyles }]);
                            conditionMet35 = true;
                        } else if (item.questionHeader && ["601", "602", "603", "604", "605", "606", "607", "608"].includes(item.questionHeader.slice(0, 3)) && !conditionMet36) {
                            table3Options.body.push([{ content: title.title6, styles: headerStyles }]);
                            conditionMet36 = true;
                        } else if (item.questionHeader && ["701", "702", "703", "704", "705", "706", "707", "708"].includes(item.questionHeader.slice(0, 3)) && !conditionMet37) {
                            table3Options.body.push([{ content: title.title7, styles: headerStyles }]);
                            conditionMet37 = true;
                        } else if (item.questionHeader && ["801", "802", "803", "804", "805", "806", "807", "808", "809", "810", "811", "812", "813", "814", "815", "816", "817", "818", "819", "820"].includes(item.questionHeader.slice(0, 3)) && !conditionMet38) {
                            table3Options.body.push([{ content: title.title8, styles: headerStyles }]);
                            conditionMet38 = true;
                        }
                        table3Options.body.push([item.question.slice(23).charAt(0).toUpperCase() + item.question.slice(24), item.label, item.questionHeader ? `Unit ${item.questionHeader.slice(0, 3)}` : '']);
                    }
                });
            }
        });

        autoTable(doc, table3Options);

        // const table4Headings = ['Course', 'Program', 'Institution'];
        doc.text('', 20, top + marginTop);
        const table4Options = {
            startY: top + marginTop + 300,
            head: [
                [
                    {
                        content: 'Course',
                        styles: {
                            fillColor: "#247A3D", // Background color
                        },
                    },
                    {
                        content: 'Program',
                        styles: {
                            fillColor: "#247A3D", // Background color
                        },
                    },
                    {
                        content: 'Institution',
                        styles: {
                            fillColor: "#247A3D", // Background color
                        },
                    },
                ],
            ],
            body: [],
            columnStyles: {
                0: { cellWidth: 240 }, // Adjust the width of the first column
                1: { cellWidth: 100 }, // Adjust the width of the second column
                2: { cellWidth: 'auto' }, // Adjust the width of the third column
            },
        };
        table4Options.body.push(
            [{ content: `Program recommendations based on your competency profile`, styles: headerStyles }]
        );
        const matchedPrograms = [];
        (props.data.length !== 0) && props.data.forEach((surveyItem) => {
            if (id === surveyItem.survey_id) {
                surveyItem.qa.sort((a, b) => a.id - b.id).forEach((item) => {
                    if (item.criterion > item.label.slice(-1)) {
                        const unitsToCheck = programData.map((item, index) => item[`units${index+1}`]);
                        programData.forEach((data, index) => {
                            const currentIndex = index + 1;
                            const currentUnit = item.questionHeader.slice(0, 3);
                            const courseName = `courseName${currentIndex}`;
                            const programName = `programName${currentIndex}`;
                            const institutionName = `institutionName${currentIndex}`;
                            // const institutionUrl = `institutionUrl${currentIndex}`;
                            const coursesToFilter = data?.[courseName].filter(ele => ele.includes(`Unit ${currentUnit}`));
                            const courseFormatted = coursesToFilter?.join('\n').replace(/,\n$/, '\n\n');
                            if (unitsToCheck[index].includes(currentUnit)) {
                                matchedPrograms.push([
                                    courseFormatted, data[programName], `${data[institutionName]}`,
                                ]);
                            }
                        });
                    }
                });
            }
        });
        table4Options.body.push(...matchedPrograms);
        autoTable(doc, table4Options);

        const table5Options = {
            body: [],
            columnStyles: {
                0: { cellWidth: 385 }, // Adjust the width of the first column
            },
        };
        table5Options.body.push([
            { content: `While there are many OHS programs offered in BC, the courses listed above are selected for how well the course outline matches the competency descriptors in the Framework; only the top three matches are shown (where at minimum three exist).`, styles: contentStyles },
        ]);
        autoTable(doc, table5Options);
    };

    return (
        <Table responsive borderless className='report-table'>
            <thead>
                <tr>
                    {tableHeadings.map((heading, index) => (
                        <th key={index}>
                            {index === 0 ? (
                                ""
                            ) : (
                                heading
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.data.length === 0 ? (
                    <tr style={{ backgroundColor: "#fff" }}>
                        <td colSpan={tableHeadings.length} className='text-center'>
                            <Image src={require('../../assets/images/No data-cuate.svg').default} alt="no reports" />
                            <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700'>No Report found</p>
                            <p className='ms-ft-22 ms-ft-mediumgrey'>CAT survey reports were not found in the database.</p>
                        </td>
                    </tr>
                ) : (
                    props.data.map((item, index) => {
                        const dateTime = getFormattedTime(item.updatedAt);
                        return (
                            <tr key={index}>
                                <td></td>
                                <td>{dateTime}</td>
                                <td>{`RPT ${item.id}`}</td>
                                <td>
                                    <div className='chart-visibility'>
                                        <Chart
                                            id={item.survey_id}
                                        />
                                    </div>
                                    <Button variant="link" onClick={() => { exportMultipleChartsToPdf(item.survey_id, dateTime) }}>
                                        <Image className='view-icon' src={require('../../assets/images/download.svg').default} alt="pdf" title='Download' />
                                    </Button>
                                </td>
                            </tr>
                        );
                    })
                )}
            </tbody>
        </Table>
    );
}

export default ReportTableFilled;
