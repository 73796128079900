import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  Image,
  Button,
  Badge,
  Row,
  Col,
  Container,
  InputGroup,
  Form,
  Pagination,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import avatar from "../../assets/images/user.svg";
import { natsurveyById } from "../../services/adminService";

function ViewNATUserTable(props) {
  const location = useLocation();
  const { userId, userData } = location.state || {};
  const navigate = useNavigate();
  const [surveydata, setSurveyData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const tableHeadings = ["Survey Number", "Survey Date"];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };
  const filteredData = surveydata.filter((item) => {
    const { id } = item;
    
    const numericId = String(id).match(/\d+/);

    // If numericId is not null, use it for comparison, otherwise use an empty string
    const idToCompare = numericId ? numericId[0] : '';
  
    // Combine the "RPT 758H" prefix and the ID to form the complete search query
    const searchQueryWithPrefix = `RPT758H${idToCompare}`;
  
    // Perform case-insensitive comparison
    return searchQueryWithPrefix.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const Data = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    natsurveydata();
  }, []);

  const natsurveydata = () => {
    const user_id = userId;
    natsurveyById(user_id)
      .then((response) => {
        const all = response.data;
        setSurveyData(all);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section className="edit-user-table">
      <Container fluid className="pt-5">
        <Row>
          <Col xs="12" lg="6">
            <div className="d-flex gap-2 align-items-center">
              <Button variant="link" className="p-0">
                <div
                  className="circle-arrow"
                  onClick={() => navigate("/natlistuser")}
                >
                  <Image
                    src={require("../../assets/images/back-arrow.svg").default}
                    alt="back-arrow"
                  />
                </div>
              </Button>
              <div className="d-flex align-items-center">
                {/* <div className="avatar-image bordered">
                                    <Image src={require('../../assets/images/avatar1.svg').default} alt='avatar' />
                                </div> */}
                <div
                  className="avatar me-1"
                  style={{ backgroundImage: `url(${avatar})` }}
                ></div>
                <div>
                  <p className="ms-ft-18 ms-fw-700 ms-ft-fadedblack mb-1">
                  {`${userData.first_name.charAt(0).toUpperCase() + userData.first_name.slice(1)} ${userData.last_name.charAt(0).toUpperCase() + userData.last_name.slice(1)}`}
                  </p>
                  <Badge bg="secondary">
                    {userData.job_type === "Employer" ? "NAT USER" : ""}
                  </Badge>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" lg="6" className="d-flex">
            <div className="d-flex align-items-center gap-4 flex-wrap">
              <div className="d-flex align-items-center gap-2">
                <div>
                  <Image
                    src={
                      require("../../assets/images/message-green.svg").default
                    }
                    alt="message"
                  />
                </div>
                <p className="ms-ft-16 mb-0">{userData.email}</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div>
                  <Image
                    src={
                      require("../../assets/images/mobile-green.svg").default
                    }
                    alt="phone"
                  />
                </div>
                <p className="ms-ft-16 mb-0">{userData.phone} </p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div>
                  <Image
                    src={require("../../assets/images/user-green.svg").default}
                    alt="user"
                  />
                </div>
                <p className="ms-ft-16 mb-0">{userData.employer_name}</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-between mt-50">
          <p className="ms-ft-18 ms-fw-700 ms-ft-fadedblack mb-0">All Survey</p>
          <InputGroup className="search-bar">
            <InputGroup.Text id="basic-addon1">
              <Image
                src={require("../../assets/images/search-normal.svg").default}
                alt="search"
              />
            </InputGroup.Text>
            <Form.Control
              placeholder="Survey Number"
              aria-label="SurveyNumber"
              aria-describedby="basic-addon1"
              value={searchQuery}
                onChange={handleSearchChange}
            />
          </InputGroup>
        </div>
        <div className="table-wrapper">
          <Table responsive borderless className="userlist-table mt-3">
            <thead>
              <tr>
                {tableHeadings.map((title, index) => (
                  <th key={index}>{title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Data.length === 0 ? (
                <tr style={{ backgroundColor: "#fff" }}>
                  <td colSpan={tableHeadings.length} className="text-center">
                    <Image
                      src={
                        require("../../assets/images/No data-cuate.svg").default
                      }
                      alt="no reports"
                    />
                    <p className="ms-ft-40 ms-ft-darkgrey ms-fw-700">
                      No Saved Job found
                    </p>
                    <p className="ms-ft-22 ms-ft-mediumgrey">
                      Survey not found in the database.
                    </p>
                  </td>
                </tr>
              ) : (
                Data.map((data) => {
                  const timeZone = "America/Los_Angeles"; // PST
                  const updatedAt = new Date(data.updatedAt);
                  const time = updatedAt.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                    timeZone
                  });
                  const day = updatedAt.toLocaleString("en-US", {
                    weekday: "long",
                    timeZone
                  });
                  const date = updatedAt.toLocaleString("en-US", {
                    day: "numeric",
                    timeZone
                  });
                  const month = updatedAt.toLocaleString("en-US", {
                    month: "long",
                    timeZone
                  });
                  const year = updatedAt.getFullYear();

                  return (
                    <tr key={data.id}>
                      <td>
                        <div className="user-avatar gap-3">
                          <div className="">
                            <Image
                              src={
                                require("../../assets/images/bar.svg").default
                              }
                              alt="bar"
                            />
                          </div>
                          <span>{`RPT 758H ${data.id}`}</span>
                        </div>
                      </td>
                      <td>
                        <p className="ms-ft-12 light-text-med mb-0">{`${time} ${day}, ${date} ${month} ${year}`}</p>
                      </td>
                      {/* <td className='d-flex gap-2 align-items-center'>
                                    <Button variant="link">
                                        <Image src={require('../../assets/images/pdf-icon.svg').default} alt="pdf" />
                                    </Button>
                                </td> */}
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        <div className="pagination-wrapper">
          <Pagination className="mb-0">
            <Pagination.Prev
              className="page-prev"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"Prev Page"}
            </Pagination.Prev>

            {Array.from({
              length: Math.ceil(filteredData.length / itemsPerPage),
            }).map((item, index) => (
              <Pagination.Item
                key={index}
                active={currentPage === index + 1}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              className="page-next"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(filteredData.length / itemsPerPage)
              }
            >
              {"Next Page"}
            </Pagination.Next>
          </Pagination>
        </div>
      </Container>
    </section>
  );
}

export default ViewNATUserTable;
