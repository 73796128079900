import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav, Image, Badge } from 'react-bootstrap';
import CustomerHeader from '../header/CustomerHeader';
import Footer from '../footer/footer';
import { useNavigate, useLocation } from "react-router-dom";

const DescriptionPage = () => {
    
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
        const contentRow = document.getElementById(`content-row-${index}`);
        if (contentRow) {
            window.scrollTo({ top: contentRow.offsetTop, behavior: 'smooth' });
        }
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleClickBack = () => {
        navigate(((localStorage.getItem("job_type") === "Professional") && !location.state.value.area) ? "/metric" : "/dashboard", !location.state.value.area ? {} : { state: { area : location.state.value.area }});
    }

    // const handleClickNext = () => {
    //     navigate("/description");
    // }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CustomerHeader />
            <section className='description-wrapper'>
                <div className='title-wrapper'>
                    <Container>
                        <div className='d-flex align-items-center'>
                            <div className='circle-nav'>
                                <Image style={{ cursor: "pointer" }} onClick={handleClickBack} src={require('../../assets/images/left-nav-dark.svg').default} alt='logo' />
                            </div>
                            {/* <div className='circle-nav'>
                                <Image style={{ cursor: "pointer" }} onClick={handleClickNext} src={require('../../assets/images/right-nav-dark.svg').default} alt='logo' />
                            </div> */}
                            <p className='ms-ft-22 ms-ft-darkgrey ms-fw-700 mb-0 ms-2'>{location.state.value.header}</p>
                        </div>
                        <div className='mt-4 title-content-block'>
                            <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700 mb-0'>{location.state.value.title}</p>
                            <p className='ms-ft-22 ms-ft-mediumgrey mt-30'>
                                {location.state.value.question}
                            </p>
                        </div>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col>
                            <Nav variant='pills' className='section-nav'>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeTab === 0 ? 'active' : ''}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        Overview
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeTab === 1 ? 'active' : ''}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Level A
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeTab === 2 ? 'active' : ''}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Level B
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={activeTab === 3 ? 'active' : ''}
                                        onClick={() => handleTabClick(3)}
                                    >
                                        Level C
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link
                                        className={activeTab === 4 ? 'active' : ''}
                                        onClick={() => handleTabClick(4)}
                                    >
                                        Analysis Processes
                                    </Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </Col>
                    </Row>
                </Container>
                <Container className='mt-50'>
                    <Row className='content-img-section'>
                        <Col xs="12" lg="6" className='order-2 order-lg-1'>
                            <div className='content-wrapper' id='content-row-0'>
                                <div className='content'>
                                    <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700 mb-0'>Overview</p>
                                    <p className='ms-ft-20 ms-ft-mediumgrey mt-40'>
                                        {location.state.value.desc}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className='order-1 order-lg-2'>
                            <div className='image-wrapper'>
                                {/* <Image
                                    src={require('../../assets/images/ds-img-1.svg').default}
                                    alt='Left Content Image'
                                    className='img-fluid'
                                /> */}
                                <div className='radial-border'></div>
                            </div>

                        </Col>
                    </Row>
                    <Row className='content-img-section'>
                        <Col xs="12" lg="4">
                            <div className='image-wrapper'>
                                {/* <Image
                                    src={require('../../assets/images/ds-img-2.svg').default}
                                    alt='Right Content Image'
                                    className='img-fluid'
                                /> */}
                                <div className='radial-border'></div>
                            </div>
                        </Col>
                        <Col xs="12" lg="6">
                            <div className='content-wrapper' id='content-row-4'>
                                <div className='content'>
                                    <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700 mb-0'>Key Points</p>
                                    <p className='ms-ft-20 ms-ft-mediumgrey mt-40'>
                                    <ul>
                                        {location.state.value.key.map((number) =>
                                            <li className={`mt-3`}>{number}</li>
                                        )}
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='content-img-section'>
                        <Col xs="12" lg="6" className='order-2 order-lg-1'>
                            <div className='content-wrapper' id='content-row-1'>
                                <div className='content'>
                                    <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700 mb-0'>Level A{location.state.value.suggested === "A" ? <span className='ms-3'><Badge bg="primary">Suggested</Badge></span> : ""}</p>
                                    <p className='ms-ft-20 ms-ft-mediumgrey mt-40'>
                                    <ul>
                                        {location.state.value.answer.levelA.map((number) =>
                                            <li className={`mt-3`}>{number}</li>
                                        )}
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className='order-1 order-lg-2'>
                            <div className='image-wrapper'>
                                {/* <Image
                                    src={require('../../assets/images/ds-img-2.svg').default}
                                    alt='Right Content Image'
                                    className='img-fluid'
                                /> */}
                                <div className='radial-border'></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='content-img-section'>
                        <Col xs="12" lg="4">
                            <div className='image-wrapper'>
                                {/* <Image
                                    src={require('../../assets/images/ds-img-2.svg').default}
                                    alt='Right Content Image'
                                    className='img-fluid'
                                /> */}
                                <div className='radial-border'></div>
                            </div>
                        </Col>
                        <Col xs="12" lg="6">
                            <div className='content-wrapper' id='content-row-2'>
                                <div className='content'>
                                    <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700 mb-0'>Level B{location.state.value.suggested === "B" ? <span className='ms-3'><Badge bg="primary">Suggested</Badge></span> : ""}</p>
                                    <p className='ms-ft-20 ms-ft-mediumgrey mt-40'>
                                    <ul>
                                        {location.state.value.answer.levelB.map((number) =>
                                            <li className={`mt-3`}>{number}</li>
                                        )}
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='content-img-section'>
                        <Col xs="12" lg="6" className='order-2 order-lg-1'>
                            <div className='content-wrapper' id='content-row-3'>
                                <div className='content'>
                                    <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700 mb-0'>Level C{location.state.value.suggested === "C" ? <span className='ms-3'><Badge bg="primary">Suggested</Badge></span> : ""}</p>
                                    <p className='ms-ft-20 ms-ft-mediumgrey mt-40'>
                                    <ul>
                                        {location.state.value.answer.levelC.map((number) =>
                                            <li className={`mt-3`}>{number}</li>
                                        )}
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className='order-1 order-lg-2'>
                            <div className='image-wrapper'>
                                {/* <Image
                                    src={require('../../assets/images/ds-img-2.svg').default}
                                    alt='Right Content Image'
                                    className='img-fluid'
                                /> */}
                                <div className='radial-border'></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
};

export default DescriptionPage;
