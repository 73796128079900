import React, { useEffect, useState } from "react";
import { Container, Image, Button, Table, Spinner } from "react-bootstrap";
import CustomerHeader from "../header/CustomerHeader";
import Footer from "../footer/footer";
import "react-toastify/dist/ReactToastify.css";
import "./report.css";
import { getNatSurvey, deleteNatSurvey } from "../../services/userServices";
import DeleteModal from './DeleteModal';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import NATSurveyPDFTemplate from "./NATSurveyPDF";
import { getFormattedDataWithBullets, getFormattedTime } from "../utils/utils";
import CopyToClipboard from "react-copy-to-clipboard";

const SavedJobs = () => {
  const tableHeadings = ["", "Job Title", "Date", "Action"];
  const [showModal, setShowModal] = useState(false);
  const [savedJob, setSavedJob] = useState([]);
  const [deleteSurveyId, setDeleteSurveyId] = useState(null);
  const navigate = useNavigate();
  const [copyText, setCopyText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getsavedJob();
    window.scrollTo(0, 0);
  }, []);

  const openModal = (surveyId) => {
    setShowModal(true);
    setDeleteSurveyId(surveyId);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const navigatetosurveyEdit = (surveyId, surveyData) => {
    navigate("/needsassessment", {
      state: { surveyId, surveyData },
    });
  }

  const navigateToSurveyView = (surveyId, surveyData) => {
    navigate("/needsassessment?viewSurvey", {
      state: { surveyId, surveyData },
    });
  }

  const getsavedJob = () => {
    let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
    setIsLoading(true);
    getNatSurvey()
      .then((res) => {
        setTimeout(() => {
          setSavedJob(res.data.filter(item => { return item.user_id === uID }));
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast("Unable to retrieve saved jobs.");
        console.log(err);
        setIsLoading(false);
      });
  };

  const deleteuser = () => {
    const id = deleteSurveyId;
    deleteNatSurvey(id)
      .then((res) => {
        console.log("deleted", res)
        getsavedJob();
        closeModal();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleCopy = (job) => {
    try {
      if (Array.isArray(job)) {
        setCopyText(getFormattedDataWithBullets(job));
        toast.success("Copied.");
      }
    } catch (error) {
      console.error('Error while copying', error);
    }
  }

  return (
    <>
      <CustomerHeader />
      {isLoading && <div className="section-overlay">
          <Spinner className="loading section-loader" animation="border" />
      </div>}
      <section className="report-wrapper">
        <Container className="pb-3">
          <p className="ms-ft-darkgrey ms-ft-40 ms-fw-700 mb-0 pt-5">
            Saved Jobs
          </p>
          {/* <Form.Group className='mt-50'>
              <Form.Select aria-label="Content Date Range" className='w-auto' defaultValue="">
                  <option value="" disabled>
                      Date Range
                  </option>
                  <option value="today">Today</option>
                  <option value="this-week">This Week</option>
                  <option value="this-month">This Month</option>
              </Form.Select>
          </Form.Group> */}
          <p className="dark-text-med ms-ft-22 mb-0 pt-4">
            Showing {savedJob.length} results.
          </p>
          <Table responsive borderless className="report-table">
            <thead>
              <tr>
                {tableHeadings.map((heading, index) => (
                  <th key={index}>
                    {index === 0
                      ?
                      ""
                      : heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {savedJob.length === 0 ? (
                <tr style={{ backgroundColor: "#fff" }}>
                  <td colSpan={tableHeadings.length} className="text-center">
                    <Image
                      src={
                        require("../../assets/images/No data-cuate.svg").default
                      }
                      alt="no reports"
                    />
                    <p className="ms-ft-40 ms-ft-darkgrey ms-fw-700">
                      No Saved Job found
                    </p>
                    <p className="ms-ft-22 ms-ft-mediumgrey">
                      Saved Job were not found in the database.
                    </p>
                  </td>
                </tr>
              ) : (
                savedJob.map((item, index) => {
                  const dateTime = getFormattedTime(item.updatedAt);
                  return (
                    <tr key={index}>
                      <td>{/* <Form.Check type='checkbox' /> */}</td>
                      <td>{item.job_title}</td>
                      <td>{dateTime}</td>

                      <td className="d-flex gap-2 align-items-center">
                        <Button variant="link">
                          <Image
                            src={
                              require("../../assets/images/delete-icon.svg")
                                .default
                            }
                            onClick={() => openModal(item.survey_id)}
                            alt="Delete"
                            title="Delete"
                          />
                        </Button>
                        {showModal && <DeleteModal showModal={showModal} closeModal={closeModal} deleteuser={deleteuser} />}
                        <CopyToClipboard
                          text={copyText}
                          onCopy={handleCopy}
                        >
                          {/* <Button className='btn-secondary' disabled={isView} >Copy to Clipboard</Button> */}
                          <Button variant="link">
                            <Image
                              src={
                                require("../../assets/images/copy.svg").default
                              }
                              alt="Copy"
                              title="Copy to Clipboard"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCopy(item?.answer)
                              }}
                            />
                          </Button>
                        </CopyToClipboard>
                        <Button variant="link">
                          <Image
                            src={
                              require("../../assets/images/edit-grey.svg")
                                .default
                            }
                            onClick={() => navigatetosurveyEdit(item.survey_id, item)}
                            alt="Edit"
                            title="Edit"
                          />
                        </Button>
                        <Button variant="link">
                          <Image
                            className="view-icon"
                            src={
                              require("../../assets/images/view-grey.svg")
                                .default
                            }
                            onClick={() => navigateToSurveyView(item.survey_id, item)}
                            alt="View"
                            title="View"
                          />
                        </Button>
                        <Button variant="link">
                          <PDFDownloadLink document={<NATSurveyPDFTemplate data={item.answer} jobTitle={item.job_title}/>} fileName={`${item.job_title} ${dateTime}.pdf`}>
                            <Image
                              className="view-icon"
                              src={
                                require('../../assets/images/download.svg')
                                  .default
                              }
                              alt="pdf"
                              title='Download'
                            />
                          </PDFDownloadLink>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default SavedJobs;
