import React from 'react';
import './footer.css';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const CATFooter = () => {

    const navigate = useNavigate();

    const handleNATLogin= event => {
        try {
            event.preventDefault();
            localStorage.clear();
            setTimeout(() => {
                navigate("/login");
            }, 1000);
        } catch (err) {
            console.log("error at handleNATLogin in CAT Footer");
        }
    };

    return (
        <section className='cta-wrapper'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <p className='title'>Are you an employer interested in our Occupational Health and Safety (OHS) Needs Assessment Tool (NAT) to help recruit and train talent?</p>
                            <p className='body-text'>Employers can use the NAT to create job profiles for qualified OHS professionals using consistent and straightforward language from the Competency Framework and Profile. You’ll need to create a new employer profile with a separate email address to access the NAT.</p>
                            <div>
                                <Button variant='secondary' onClick={handleNATLogin}>
                                    Visit the Needs Assessment Tool
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CATFooter;
