import React, { useState, useEffect, Fragment } from 'react';
import './header.css'
import { Navbar, Container, Nav, Image, Dropdown } from 'react-bootstrap';
import avatar from '../../assets/images/user.svg';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCatSurvey } from "../../services/userServices";
import NatUnSaveModal from '../dashboard/NatUnSaveModal';
import SaveJobModal from '../dashboard/SaveJobModal';

const CustomerHeader = (props) => {
    const { job, surveyData, isView, button } = props;
    const jobLength = (job && job.length) || 0;
    const navigate = useNavigate();
    const location = useLocation();
    const [qa, setQA] = useState(0);
    const [surveyID, setSurveyID] = useState("");
    const [response, setResponse] = useState([]);
    const [showModal, setModal] = useState(false);
    const [redirect, setRedirect] = useState('');
    const [activeKey, setActiveKey] = useState("");
    const [showSidebar, setShowSidebar] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const closeModal = () => {
        setModal(false);
    };

    const proceed = () => {
        navigate(redirect);
    }

    const saveJob = () => {
        if (jobLength > 0) {
            setShowSaveModal(true);
            closeModal();
        }
    }

    const handleToggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/home':
                setActiveKey("1");
                break;
            case '/dashboard':
            case '/metric':
                setActiveKey("2");
                break;
            case '/catsurvey':
            case '/assessyourskills':
            case '/natsurvey':
            case '/needsassessment':
                setActiveKey("3");
                break;
            case '/report':
            case '/savedjobs':
                setActiveKey("4");
                break;
            case '/help':
                setActiveKey("5");
                break;
            default:
                setActiveKey("");
                break;
        }
    }, [location.pathname]);


    useEffect(() => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        getCatSurvey()
            .then((result) => {
                result.data.forEach((index) => {
                    if ((uID === index.user_id) && (index.survey_status === "Pending")) {
                        setQA(index.qa.length);
                        setSurveyID(index.survey_id);
                        setResponse(index.qa);
                    }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSignout = event => {
        try {
            event.preventDefault();
            localStorage.clear();
            setTimeout(() => {
                navigate("/login");
                toast.success("Logout successfull.");
            }, 1000);
        } catch (err) {
            console.log("error at handleSignout in userProfile");
        }
    };

    const handleHeaderHome = () => {
        if (jobLength > 0 && !isView && button) {
            setModal(true);
            setRedirect('/home');
        } else {
            setModal(false);
            navigate("/home");
        }
    }

    const handleHeaderFramework = () => {
        try {
            if (jobLength > 0 && !isView && button) {
                setModal(true);
                setRedirect((localStorage.getItem("job_type") === "Professional") ? '/metric' : '/dashboard');
            } else {
                setModal(false);
                navigate((localStorage.getItem("job_type") === "Professional") ? '/metric' : '/dashboard');
            }
        }
        catch (err) {
            console.log("error at handleHeaderFramework");
        }
    }

    const handleHeaderSurvey = () => {
        try {
            navigate((localStorage.getItem("job_type") === "Professional") ? "/catsurvey" : "/natsurvey?explore", { state: { qa, surveyID, response } });
        }
        catch (err) {
            console.log("error at handleHeaderSurvey");
        }
    }

    const handleHeaderReport = () => {
        try {
            navigate("/report");
        }
        catch (err) {
            console.log("error at handleHeaderReport");
        }
    }

    const handleHeaderSavedjob = () => {
        if (jobLength > 0 && !isView && button) {
            setModal(true);
            setRedirect('/savedjobs')
        } else {
            setModal(false);
            navigate("/savedjobs");
        }
    }

    // const handleHeaderMetric = () => {
    //     try {
    //         navigate("/metric");
    //     }
    //     catch (err) {
    //         console.log("error at handleHeaderMetric");
    //     }
    // }

    const handleHeaderHelp = () => {
        if (jobLength > 0 && !isView && button) {
            setModal(true);
            setRedirect('/help')
        } else {
            setModal(false);
            navigate("/help");
        }
    }

    const profile = () => {
        if (jobLength > 0 && !isView && button) {
            setModal(true);
            setRedirect('/profile')
        } else {
            setModal(false);
            navigate("/profile");
        }
        // try {
        //         navigate("/profile");
        //     } 
        // catch (err) {
        //     console.log("error at profile");
        // }
    }

    return (
        <Fragment>
            <Navbar bg="white">
                <Container className='header-wrapper'>
                    <Navbar.Brand onClick={handleHeaderHome}>
                        <Image src={require('../../assets/images/msabc-logo.svg').default} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="header-nav" />
                    <Navbar.Collapse id="header-nav">
                        {/* <Nav className="mr-auto flex-grow-1">
                            <InputGroup>
                                <FormControl placeholder="What are you looking for today ?" />
                                <InputGroup.Text>
                                    <Button variant="outline-secondary">
                                        <Image src={require('../../assets/images/search-icon.svg').default} alt="search-icon" />
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Nav> */}
                        <Nav className="ms-auto align-items-center">
                            {/* <Nav.Link href="#">
                                <Image src={require('../../assets/images/help.svg').default} alt="help" />
                            </Nav.Link>
                            <Nav.Link href="#">
                                <Image src={require('../../assets/images/bell.svg').default} alt="bell" />
                            </Nav.Link> */}
                            <Nav.Link href="#" onClick={profile} className='d-none d-lg-block'>
                                <p className='ms-ft-20 ms-ft-mediumgrey ms-fw-600 mb-0'>Profile</p>
                            </Nav.Link>
                            <Dropdown align="end">
                                <Dropdown.Toggle
                                    className="avatar-container"
                                    id="avatar-dropdown"
                                >
                                    <div className="avatar" style={{ backgroundImage: `url(${avatar})` }} />
                                    {/* <div className="mini-circle"></div> */}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={profile}>My Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={handleSignout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar bg="white" expand="lg" className="sub-header">
                <Container>
                    <Navbar.Toggle
                        aria-controls="navbar-nav"
                        onClick={handleToggleSidebar}
                        className={showSidebar ? 'open' : ''}
                    />
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="mr-auto d-none d-lg-flex align-items-center" activeKey={activeKey}>
                            {/* <NavDropdown title="Framework" id="dropdown-menu" eventKey="link-dropdown">
                                <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                                <NavDropdown.Item href="/dashboard">Another action</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/dashboard">Something else</NavDropdown.Item>
                            </NavDropdown> */}
                            <Nav.Link
                                href='#'
                                eventKey="1"
                                onClick={handleHeaderHome}
                            >
                                {'Home'}
                            </Nav.Link>
                            <Nav.Link
                                href='#'
                                eventKey="2"
                                onClick={handleHeaderFramework}
                            >
                                {'Competency Framework and Profile (CFP)'}
                            </Nav.Link>
                            <Nav.Link
                                href='#'
                                eventKey="3"
                                onClick={handleHeaderSurvey}
                            >
                                {localStorage.getItem("job_type") === "Employer" ? 'Needs Assessment Tool (NAT)' : 'Competency Assessment Tool (CAT)'}
                            </Nav.Link>
                            <Nav.Link
                                href='#'
                                eventKey="4"
                                onClick={localStorage.getItem("job_type") === "Professional" ? handleHeaderReport : handleHeaderSavedjob}
                            >
                                {localStorage.getItem("job_type") === "Professional" ? 'Report' : 'Saved Jobs'}
                            </Nav.Link>
                            <Nav.Link
                                href='#'
                                eventKey="5"
                                onClick={handleHeaderHelp}
                            >
                                {'Help'}
                            </Nav.Link>
                            <div className="vertical-border"></div>
                            <Nav.Link
                                href={(localStorage.getItem("job_type") === "Professional") ? 'https://questionnaire.simplesurvey.com/f/s.aspx?s=fb77d0f0-03c2-4b28-8c6b-d3f6d4d9429e' : 'https://questionnaire.simplesurvey.com/f/s.aspx?s=e2f3211b-5559-46e8-b93e-9ee3b4eb0a6c'}
                                target="_blank"
                            >
                                {'Feedback Survey'}
                            </Nav.Link>
                        </Nav>

                        {/* <Nav>
                            <Nav.Link
                                href='#'
                                eventKey="6"
                                onClick={handleHeaderFeedback}
                            >
                                {'Share Feedback'}
                            </Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {showSidebar && (
                <div className="sidebar-overlay" onClick={handleToggleSidebar} style={{ display: "block" }}></div>
            )}
            <div className={`customer-sidebar ${showSidebar ? 'show' : ''}`}>
                <Navbar>
                    <Nav className="mx-auto d-flex flex-column mobile-subheader-nav" activeKey={activeKey}>
                        {/* <NavDropdown title="Framework" id="dropdown-menu" eventKey="link-dropdown">
                                <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                                <NavDropdown.Item href="/dashboard">Another action</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/dashboard">Something else</NavDropdown.Item>
                            </NavDropdown> */}
                        <div className='d-flex justify-content-between my-3' onClick={handleToggleSidebar}>
                            <h3 className=''>Menu</h3>
                            <Image src={require('../../assets/images/close-icon.svg').default} alt="close" />
                        </div>
                        <Nav.Link
                            href='#'
                            eventKey="1"
                            onClick={handleHeaderHome}
                        >
                            {'Home'}
                        </Nav.Link>
                        <Nav.Link
                            href='#'
                            eventKey="2"
                            onClick={handleHeaderFramework}
                        >
                            {'Competency Framework and Profile (CFP)'}
                        </Nav.Link>
                        <Nav.Link
                            href='#'
                            eventKey="3"
                            onClick={handleHeaderSurvey}
                        >
                            {localStorage.getItem("job_type") === "Employer" ? 'Needs Assessment Tool (NAT)' : 'Competency Assessment Tool (CAT)'}
                        </Nav.Link>
                        <Nav.Link
                            href='#'
                            eventKey="4"
                            onClick={localStorage.getItem("job_type") === "Professional" ? handleHeaderReport : handleHeaderSavedjob}
                        >
                            {localStorage.getItem("job_type") === "Professional" ? 'Report' : 'Saved Jobs'}
                        </Nav.Link>
                        {/* {localStorage.getItem("job_type") === "Professional" && <Nav.Link
                            href="#"
                            eventKey="5"
                            onClick={handleHeaderMetric}
                        >
                            {'Unit-Level Review'}
                        </Nav.Link>} */}
                        <Nav.Link
                            href='#'
                            eventKey="5"
                            onClick={handleHeaderHelp}
                        >
                            {'Help'}
                        </Nav.Link>
                        <div className="horizontal-border"></div>
                        <Nav.Link
                            href={(localStorage.getItem("job_type") === "Professional") ? 'https://questionnaire.simplesurvey.com/f/s.aspx?s=fb77d0f0-03c2-4b28-8c6b-d3f6d4d9429e' : 'https://questionnaire.simplesurvey.com/f/errormessage.aspx?s=e2f3211b-5559-46e8-b93e-9ee3b4eb0a6c&lang=EN&m=inactive&ref=986'}
                            target="_blank"
                        >
                            {'Feedback Survey'}
                        </Nav.Link>
                    </Nav>




                </Navbar>
                {/* <Nav>
                    <Nav.Link
                        href='#'
                        eventKey="6"
                        onClick={handleHeaderFeedback}
                    >
                        {'Share Feedback'}
                    </Nav.Link>
                </Nav> */}
            </div>
            <ToastContainer
                position="top-center"
                hideProgressBar
            />
            {showModal && (
                <NatUnSaveModal
                    showModal={showModal}
                    closeModal={closeModal}
                    proceed={proceed}
                    save={saveJob}
                />
            )}
            {showSaveModal && <SaveJobModal
                {...props}
                data={job}
                surveyData={surveyData}
                showSave={showSaveModal}
            />}
        </Fragment>
    );
};

export default CustomerHeader;



