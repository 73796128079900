import React from 'react';
import NotFoundImage from '../../../src/assets/images/404.png';
import { useNavigate } from "react-router-dom";

const NoPage = () => {
  
  const navigate = useNavigate();

  const handleImageClick = () => {
    if(window.location.pathname.slice(0,15) === "/admindashboard"){
      navigate("/admindashboard");
    } else{
      navigate("/home");
    }
  }

  return (
    <div className="not-found-container">
      <img src={NotFoundImage} alt="Page Not Found" onClick={handleImageClick} width="100%" height="750"/>
    </div>
  );
};

export default NoPage;