import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { natSurvey, updateNatSurvey } from "../../services/userServices";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SaveJobModal = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = () => {
    try {
      var input = {
        job_title: jobTitle,
        answer: props.data,
        user_id: JSON.parse(JSON.stringify(localStorage.getItem("user_id"))),
      };
      if (props.surveyData) {
        updateNatSurvey(input, props.surveyData.survey_id)
          .then((response) => {
            console.log("NAT survey response from back-end====>", response);
            setTimeout(() => {
              navigate("/savedjobs");
              toast.success("Job updated successfully.");
            }, 1000);
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            toast.error("Save failed..!");
          });
      } else {
        natSurvey(input)
          .then((response) => {
            console.log("NAT survey response from back-end====>", response);
            setTimeout(() => {
              navigate("/home");
              toast.success("Job saved successfully.", );
            }, 1000);
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            toast.error("Save failed..!");
          });
      }
    } catch (err) {
      console.log("error at handleSave");
    }
  };
  useEffect(() => {
    if (props.surveyData && props.surveyData.job_title) {
      setJobTitle(props.surveyData.job_title);
    }
  }, [props.surveyData]);

  return (
    <div>
      {!props.showSave && <Button variant="primary" disabled={!props.isButton} onClick={handleShow}>
        Save
      </Button>}
      <Modal show={show || props.showSave} onHide={handleClose} className="save-job-modal">
        <Modal.Header className="border-0 pb-1" closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <p className="ms-ft-24 ms-ft-medblack ms-fw-700 mb-0">
            Save Job Requirements
          </p>
          <Form.Group controlId="jobTitleInput">
            <Form.Label className="mt-40">Job Title*</Form.Label>
            <Form.Control
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!jobTitle} onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SaveJobModal;
