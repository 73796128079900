import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { v4 as uuidv4 } from 'uuid';
import logo from '../../assets/images/msabc_logo.png';
import phone from '../../assets/images/phone-dark.png';
import email from '../../assets/images/mail-dark.png';
import user from '../../assets/images/user-dark.png';
import title from '../dashboard/title.json'

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  dataContainer: {
    marginBottom: 20,
  },
  banner: {
    backgroundColor: 'transparent',
    padding: 10,
    marginBottom: 10,
    width: '100%'
  },
  logobox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '10px',
  },
  logo: {
    maxWidth: 160,
    height: 45,
    display: 'flex',
  },
  details: {
    fontSize: '10px',
    fontWeight: 700,
    marginBottom: '10px',
    marginTop: '10px'
  },
  detailText: {
    fontSize: '8px',
    marginBottom: '5px',
    marginLeft: '10px',
    color: '#757575',
  },
  profileContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    gap: "5px",
    padding: "0"
  },
  profileDetails: {
    flexDirection: 'row',
    backgroundColor: "#eee",
    padding: "10px",
    width: "100%",
    alignItems: "center",
    textAlign: "left"
  },
  profileIconsContainer: {
    flexDirection: 'column',
    justifyContent: "center"
  },
  profileIconsDetails: {
    flexDirection: 'row',
    color: '#494f54',
    gap: 5,
    justifyContent: "center"
  },
  profileDetailText: {
    fontSize: '12px',
    color: '#494f54',
    marginBottom: "5px"
  },
  profileImage: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    marginRight: 10,
    backgroundColor: '#d9d9d9',
  },
  profileName: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#fff',
  },
  phoneIcon: {
    width: '12px',
  },
  emailIcon: {
    width: '12px',
  },
  userIcon: {
    width: '12px',
  },
  titleBlock: {
    backgroundColor: "#eee",
    padding: "20px 10px",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center"
  },
  titleText: {
    color: "#494f54",
    fontSize: "14px",
    marginBottom: "0px",
    flexGrow: "1"
  },
  contentTitle: {
    color: "#494f54",
    margin: "0 10px",
  },
  contentSubText: {
    fontSize: '12px',
    fontWeight: 700,
    marginBottom: '10px',
    marginTop: '10px'
  },
});

const NATSurveyPDFTemplate = ({ data, jobTitle }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        {/* Top Banner */}

        <View style={styles.dataContainer}>
          <View style={styles.banner}>
            <View style={styles.logobox}>
              <Image src={logo} style={styles.logo} />
            </View>

            <View style={styles.profileContainer}>
              <View style={styles.titleBlock}>
                <Text style={styles.titleText}>Needs Assessment Tool: Saved Jobs</Text>
              </View>
              <View style={styles.profileDetails}>
                <Text style={styles.titleText}>{jobTitle}</Text>
                {/* <View style={styles.profileIconsContainer}>

                  <View style={styles.profileIconsDetails}>
                    <View><Image src={user} style={styles.userIcon} /></View>
                    <Text style={styles.profileDetailText}> {localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')}</Text>
                  </View>
                  <View style={styles.profileIconsDetails}>
                    <View><Image src={email} style={styles.emailIcon} /></View>
                    <Text style={styles.profileDetailText}>{localStorage.getItem('email')}</Text>
                  </View>
                  <View style={styles.profileIconsDetails}>
                    <View><Image src={phone} style={styles.phoneIcon} /></View>
                    <Text style={styles.profileDetailText}>{localStorage.getItem('phone')}</Text>
                  </View>
                </View> */}
              </View>
            </View>
          </View>
          {
            Object.keys(title).map(key => {
              return (
                <View style={styles.contentTitle} key={`title-${key}`}>
                  <Text style={styles.contentSubText}>{title[key]}</Text>
                  {data.filter(item => item.unit.slice(0, 1) === key.slice(-1)).map((item) => {
                    if (item?.enabled) {
                      return (<View key={`pdf-${item?.unit}`}>
                        <Text style={styles.details}>{item?.natDesc}</Text>
                        {Object.keys(item.answerText).map((level) => (
                          item.answerText[level].map((question) => {
                            if (question.value) {
                              return (
                                <Text key={uuidv4()} style={styles.detailText}>
                                  • {question.question}
                                </Text>
                              );
                            }
                          })
                        ))}
                      </View>)
                    }
                  }
                  )}
                </View>
              )
            })
          }
        </View>
      </Page>
    </Document>
  );
};

export default NATSurveyPDFTemplate;
