import axios from 'axios';

const API_URL = process.env.REACT_APP_API;

export function natSurveyUser() {
    return axios.get(`${API_URL}/api/get_nat_user`);
}

export function catSurveyUser() {
    return axios.get(`${API_URL}/api/get_cat_user`);
}

export function getAllUser() {
    return axios.get(`${API_URL}/api/get_all_user`);
}

export function catUserCount() {
    return axios.get(`${API_URL}/api/countcatuser`);
}

export function natUserCount() {
    return axios.get(`${API_URL}/api/countnatuser`);
}

export function UserUpdate(data) {
    return axios.post(`${API_URL}/api/updateuser/${data.user_id}`,data);
}

export function catSurveyCount() {
    return axios.get(`${API_URL}/api/catsurveycount`);
}

export function adminuserfeedback(id) {
    return axios.get(`${API_URL}/api/getfeedback/${id}`);
}

export function adminfeedback() {
    return axios.get(`${API_URL}/api/getallfeedback`);
}

export function catsurveyById(id) {
    return axios.get(`${API_URL}/api/catsurveybyid/${id}`);
}

export function natsurveyById(id) {
    return axios.get(`${API_URL}/api/natsurveybyid/${id}`);
}