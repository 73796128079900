import React, { useState, useEffect } from "react";
import { Container, Accordion, Row, Col } from "react-bootstrap";
import CustomerHeader from "../header/CustomerHeader";
import Footer from "../footer/footer";
import "react-toastify/dist/ReactToastify.css";
import './help.css'

const Help = () => {
  const [activeTab, setActiveTab] = useState("FAQ");
  const [isSticky, setIsSticky] = useState(false);


  const handleClick = (tabName) => {
    setActiveTab(tabName);
    window.scrollTo(0, 0);
  }


  useEffect(() => {
    const handleScroll = () => {
      const leftContent = document.querySelector('.left-menu');
      const topOffset = 20;
      const isScrolledPast = window.scrollY > leftContent.offsetTop - topOffset;
      if (isScrolledPast) {
        setIsSticky(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <>
      <CustomerHeader />
      <section className="help-page-wrapper">
        <div className="title-wrapper">
          <Container>
            <Row>
              <Col xs={12} md={3} className={`left-menu ${isSticky ? 'sticky-left-content' : ''}`}>
                <ul className="help-tab-wrapper list-unstyled">
                  <li className={activeTab === "FAQ" ? "active" : ""} onClick={() => handleClick("FAQ")}>FAQ</li>
                  <li className={activeTab === "definitions" ? "active" : ""} onClick={() => handleClick("definitions")}>Definitions</li>
                  <li className={activeTab === "glossary" ? "active" : ""} onClick={() => handleClick("glossary")}>Glossary</li>
                  <li className={activeTab === "userGuides" ? "active" : ""} onClick={() => handleClick("userGuides")}>User Guide</li>
                </ul>
              </Col>
              <Col xs={12} md={9} className='login-form-wrapper'>
                {activeTab === "FAQ" && (
                  <Accordion defaultActiveKey={['0']} className="mb-4">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><em>What is the Occupational Health and Safety (OHS)
                        Competency Framework and Profile?</em></Accordion.Header>
                      <Accordion.Body>
                        The Framework is a comprehensive set of knowledge,
                        skills, and behaviours that define the scope of practice within
                        the OHS profession. It outlines the competencies required for
                        OHS professionals to effectively reduce worker harm within
                        workplaces.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header><em> Why was the <em>Framework</em> developed?
                      </em></Accordion.Header>
                      <Accordion.Body>
                        The <em>Framework</em> was developed to standardize professional
                        competencies within the OHS field, addressing the need for
                        consistency and reliability. It aims to provide a common
                        understanding of the skills and knowledge OHS professionals
                        should possess to improve worker health and safety.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header><em>
                        How does the <em>Framework</em> adapt to changes in the OHS
                        profession?
                      </em></Accordion.Header>
                      <Accordion.Body>
                        The <em>Framework</em> is designed to be adaptable to different
                        contexts. This means that different stakeholders can focus on
                        specific components of the <em>Framework</em> that are relevant
                        to their needs, allowing it to accommodate changes in the
                        profession over time.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header> <em>
                        Who can benefit from using the <em>Framework</em>?
                      </em></Accordion.Header>
                      <Accordion.Body>
                        The <em>Framework</em> is intended for various parties engaged
                        with the OHS profession, including OHS professionals themselves,
                        organizations hiring OHS professionals, and educational
                        institutions. It provides guidance for professional development,
                        hiring practices, and training programs.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header> <em>
                        How can OHS professionals use the <em>Framework</em> for their
                        development?
                      </em></Accordion.Header>
                      <Accordion.Body>
                        OHS professionals can use the <em>Framework</em> as a tool for
                        self-assessment and professional growth. By identifying areas of
                        improvement in their practice, they can increase their value to
                        potential employers and enhance their skills and knowledge.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>  <em>
                        How can organizations benefit from the <em>Framework</em>?
                      </em></Accordion.Header>
                      <Accordion.Body>
                        Organizations can incorporate the <em>Framework</em>'s language
                        and scope in their hiring practices to communicate their
                        expectations to potential hires. This helps ensure that OHS
                        professionals meet industry standards and contribute effectively
                        to the organization's safety culture.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>   <em>What is the competency profile?</em></Accordion.Header>
                      <Accordion.Body>
                        The competency profile defines the behaviours and skills
                        expected from a functionally adequate OHS professional. It sets
                        the standard for entry-level, fully trained, and experienced OHS
                        professionals by describing the competencies they should
                        demonstrate.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
                {activeTab === "definitions" && (
                  <div>
                  <h2>Abbreviations</h2>
                  <ul>
                    <li><em>GHS:</em> Globally Harmonized System of Classification and Labelling of Chemicals </li>
                    <li><em>HSR:</em> Health and safety representative </li>
                    <li><em>JHSC:</em> Joint health and safety committee </li>
                    <li><em>OHS:</em> Occupational health and safety </li>
                    <li><em>OHSMS:</em> Occupational health and safety management system </li>
                    <li><em>PPE:</em> Personal protective equipment </li>
                    <li><em>WHMIS:</em> Workplace Hazardous Materials Information System </li>
                  </ul>
                  <div >
                    <h6 className="ms-fw-600"><em>Board of Canadian Registered Safety Professionals</em></h6>
                    <ul>
                      <li>
                        Blueprint for the Canadian Registered Safety Professional
                        Examination (2020)
                      </li>
                    </ul>
                    <em className="ms-fw-600">Bureau de normalization du Québec and CSA Group</em>
                    <ul>
                      <li>CAN/BNQ 9700-800/2020</li>
                      <li>
                        Healthy Enterprise - Prevention, Promotion, and Organizational
                        Practices Contributing to Health and Wellness in the Workplace
                      </li>
                      <li>CAN/CSA-Z1003-13/BNQ 9700-803/2013</li>
                      <li>Psychological Health and Safety in the Workplace</li>
                    </ul>
                    <em className="ms-fw-600">CSA Group</em>
                    <ul>
                      <li>
                        CAN/CSA-ISO 9000:16 (R2020) - Quality Management Systems -
                        Fundamentals and Vocabulary (Adopted ISO 9000:2015, Fourth
                        Edition, 2015-09-15)
                      </li>
                      <li>
                        CSA ISO 31000:18 - Risk Management - Guidelines (Adopted ISO
                        31000:2018, Second Edition, 2018-02)
                      </li>
                      <li>
                        CSA ISO/TR 31004:14 (R2019) - Risk Management - Guidance for
                        the Implementation of ISO 31000 (Adopted ISO Technical Report
                        31004:2013, First Edition, 2013-10-15)
                      </li>
                      <li>
                        CSA SPE-116:23 - Environmental, Social, and Governance (ESG)
                        and Sustainable Development Goals (SDGs) Guidance for OHS
                        Competency Framework and Profile 5
                      </li>
                      <li>
                        CSA Z1000:14 (R2019) - Occupational Health and Safety
                        Management
                      </li>
                      <li>
                        CSA Z1002:12 (R2022) - Occupational Health and Safety - Hazard
                        Identification and Elimination and Risk Assessment and Control
                      </li>
                      <li>CSA Z1005:21 - Workplace Incident Investigation</li>
                      <li>
                        CSA Z460:20 - Control of Hazardous Energy - Lockout and Other
                        Methods
                      </li>
                      <li>CSA Z432-16 (R2021) - Safeguarding of Machinery</li>
                      <li>
                        CSA W117.2:19 - Safety in Welding, Cutting, and Allied
                        Processes
                      </li>
                      <li>
                        CSA Z1006:16 (R2020) - Management of Work in Confined Spaces
                      </li>
                      <li>
                        CSA Z259.16:21 - Design of Active Fall-Protection Systems
                      </li>
                      <li>CSA Z462:21 - Workplace Electrical Safety</li>
                    </ul>
                    <em className="ms-fw-600">Institute for Work and Health</em>
                    <ul>
                      <li>OHS Vulnerability Measure (2017)</li>
                    </ul>
                    <em className="ms-fw-600">Institution of Occupational Safety and Health</em>
                    <ul>
                      <li>
                        Competency Framework: Professional Standards for Safety and
                        Health at Work (2019)
                      </li>
                    </ul>
                    <em className="ms-fw-600">
                      International Network of Safety and Health Practitioner
                      Organisations
                    </em>
                    <ul>
                      <li>
                        The Occupational Health and Safety Professional Capability
                        Framework: A Global Framework for Practice (2017)
                      </li>
                    </ul>
                    <em className="ms-fw-600">International Organization for Standardization</em>
                    <ul>
                      <li>
                        ISO 20700:2017 - Guidelines for Management Consultancy
                        Services
                      </li>
                      <li>
                        ISO 45001:2018 - Occupational Health and Safety Management
                        Systems - Requirements with Guidance for Use
                      </li>
                      <li>
                        ISO 6184-1:1985 - Explosion Protection systems — Part 1:
                        Determination of Explosion Indices of Combustible Dusts in Air
                      </li>
                    </ul>
                    <em className="ms-fw-600">Federal Regulations</em>
                    <ul>
                      <li>Transportation of Dangerous Goods SOR/2001-286</li>
                    </ul>
                  </div>
                </div>
                )}
                {activeTab === "glossary" && (
                  <div>
                  <ul>
                    <li><em>Audit:</em> A systematic, documented process to obtain and objectively evaluate evidence for the purpose of determining the extent to which the <em>audit criteria</em> are fulfilled. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Commercial awareness:</em> The ability to understand the marketplace and context in which one's organization operates. </li>
                    <li><em>Competency:</em> An observable application of the attributes that comprise the competent practice of the OHS profession within a specific setting and context, such as <em>skills</em> and <em>knowledge</em>. </li>
                    <li><em>Context of the organization:</em> The environment in which the organization seeks to define and achieve its <em>objectives</em>. (Adapted from CSA ISO 31000:18) </li>
                    <li><em>Contractor:</em> An organization or individual providing services to another organization in accordance with agreed-upon specifications, terms, and conditions. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Corporate governance:</em> The structure of policies and procedures used to direct and manage an organization. (Adapted from CSA SPE-116:23) </li>
                    <li><em>Guidelines:</em> Documents that are used to interpret legislation. </li>
                    <li><em>Harm:</em> An injury or damage to health. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Hazard:</em> A potential source of harm to a worker. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Hazardous event:</em> An event that exposes workers to a hazard. </li>
                    <li><em>Health:</em> A state of complete physical, social, and mental well-being, and not merely the absence of disease or infirmity. (Adapted from CAN/BNQ 9700-800/2020) </li>
                    <li><em>Health promotion:</em> A process offering workers the means to ensure greater control over their health and to be able to improve it. (Adapted from CAN/BNQ 9700-800/2020) </li>
                    <li><em>Incident:</em> An occurrence arising in the course of work that resulted in or could have resulted in harm. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Incident investigation:</em> A systematic, documented process to obtain and objectively evaluate evidence for the purpose of determining the influence of the workplace system on the occurrence of an incident. (Adapted from CSA Z1005:21) </li>
                    <li><em>Legal requirements:</em> Requirements of application of OHS federal, provincial/territorial, and municipal laws, regulations, and bylaws, and where they exist, provisions of the organization's collective agreements that relate to health and safety. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Legislation:</em> An enforceable piece of law, such as an Act, regulation, code, or a legally-adopted standard or guideline. </li>
                    <li><em>Psychological health:</em> A state of well-being in which the individual realizes their own abilities, can cope with the normal stresses of life, can work productively and fruitfully, and is able to make a contribution to their community. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Non-OHS risk:</em> See organizational risk. </li>
                    <li><em>Objective:</em> A result to be achieved. (Adapted from ISO 45001:2018) </li>
                    <li><em>Occupational health and safety management system:</em> Part of the overall management of the organization that addresses OHS hazards and risks associated with its activities. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>OHS risk:</em> See risk. </li>
                    <li><em>Organization:</em> An organization, employer, operation, undertaking, establishment, institution, or association, or a part or combination thereof, that has its own management. (Adapted from CAN/CSA- Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Organizational culture:</em> A pattern of basic assumptions invented, discovered, or developed by a given group that is a mix of values, beliefs, meanings, and expectations that group members hold in common and use as behavioral and problem-solving cues. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Organizational risk:</em> The effect of uncertainty on objectives unrelated to the prevention of harm. (Adapted from CSA ISO/TR 31004:14 (R2019)) </li>
                    <li><em>Other requirement:</em> Other OHS provisions adopted by the organization. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Policy:</em> Intentions and directions of an organization, as formally expressed by its senior management. (Adapted from CSA Z1005:21) </li>
                    <li><em>Procedure:</em> A documented method to carry out an activity. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Process:</em> A set of interrelated or interacting activities that transform inputs into outputs. (Adapted from CSA Z1000:14 (R2019)) </li>
                    <li><em>Mental health:</em> See psychological health. </li>
                    <li><em>Quality:</em> The degree to which an object (such as a material, non-material, or imagined) meets a set of requirements. (Adapted from CAN/CSA-ISO 9000:16 (R2020)) </li>
                    <li><em>Resource:</em> Assets, people, skills, information, technology, premises, supplies, and information that an organization has available to use when needed in order to operate and meet its objectives. (Adapted from ISO 20700:2017) </li>
                    <li><em>Risk:</em> The combination of the likelihood of the occurrence of harm and the severity of that harm. (Adapted from CSA Z1002:12 (R2022)) </li>
                    <li><em>Risk analysis:</em> A process for comprehending the nature of hazards and determining the level of risk. (Adapted from CSA Z1002:12 (R2022)) </li>
                    <li><em>Risk assessment:</em> The overall process of hazard identification, risk analysis, and risk evaluation. (Adapted from CSA Z1002:12 (R2022)) </li>
                    <li><em>Risk control:</em> Actions implementing risk evaluation decisions. (Adapted from CSA Z1002:12 (R2022)) </li>
                    <li><em>Risk evaluation:</em> The process of comparing an analyzed risk against given risk criteria to determine the significance of that risk. (Adapted from CSA Z1002:12 (R2022)) </li>
                    <li><em>Senior management:</em> The person(s) at the highest level of an organizational structure responsible for leading, managing, and/or directing an organization. (Adapted from CAN/CSA-Z1003-13/BNQ 9700- 803/2013) </li>
                    <li><em>Stakeholder:</em> A person or organization within the workplace that can affect or be affected by, or perceive themselves to be affected by, the decisions or activities related to health and safety factors within the workplace. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Standards:</em> Guidelines for good professional practice, produced by voluntary organizations. </li>
                    <li><em>Vulnerability:</em> The extent to which a worker is subject to elevated levels of risk as a result of sociodemographic or contextual factors related to work injury and illness. </li>
                    <li><em>Wellness:</em> A feeling or sensation of greater fulfillment, greater comfort, or greater overall satisfaction. (Adapted from CAN/BNQ 9700-800/2020) </li>
                    <li><em>Worker:</em> A person employed by an organization or a person under the day-to-day control of the organization who is actively engaged in undertaking activities for the benefit of the organization. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Worker representative:</em> A non-managerial worker who is a member of the workplace health and safety committee, a representative of other workers in accordance with legal requirements, or selected by non- managerial workers for other reasons. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                    <li><em>Workplace:</em> An area or location where a worker works for an organization, or is required or permitted to be present while engaging in service (including social events) on behalf of an organization. (Adapted from CAN/CSA-Z1003-13/BNQ 9700-803/2013) </li>
                  </ul>
                  </div>
                )}
                {activeTab === "userGuides" && (
                  <div>
                    <div className="title-bar">
                        <p className="title">{(localStorage.getItem("job_type") === "Professional") ? 'competency assessment tool' : 'needs assessment tool'}</p>
                    </div>
                    <h2>User Guide</h2>
                      {(localStorage.getItem("job_type") === "Professional") ? (
                      <div>
                        <p>The Competency Assessment Tool (CAT) is designed for Occupational Health and Safety (OHS) professionals who work in British Columbia. The goal of this tool is to help OHS professionals self-assess their ability to use their skills and knowledge to perform common OHS-relevant job tasks.</p>
                        <p>Each job task is represented by one of seventy-six competencies, called units in the OHS Competency Framework and Profile, Second Edition. Each unit represents a single task or role that an OHS professional might be expected to perform for an organization. These units are grouped into eight thematic areas, with the eighth being manufacturing industry-specific risk areas.</p>
                        <p>For each unit, there are three levels of competence: Level A, Level B, and Level C. Each level is made up of descriptions of actions a professional might take in performing the task. The actions in Level A are considered introductory, and require a basic level of skill or knowledge, whereas the actions in Level C are the most complex and require the application of knowledge and skills at a more advanced integrated level.</p>
                        <p>The Framework was designed so that the levels build on each other. That means that if someone was able to do the actions in Level B on-the-job, they would also be able to do the actions in Level A.</p>
                        <p>Your job is to go through each of the units and determine which level best describes the things you can competently do in the worksite. This is not an exam or a survey, so you will not be asked to justify your answers; however, the more honest you are, the more useful the assessment will be.</p>
                        <p>After you are done, you can submit your results. A report that shows how your answers measure against the Framework’s competency threshold (what is considered the minimum level of competency for each unit) will be generated. This comparison will be on each unit you complete, and you will receive a “Exceeds threshold”, “Meets threshold”, or a “For development” label.</p>
                        <ul>
                          <li>“Exceeds threshold”: Your self-assessed level of competence exceeds the minimum expectations of an OHS professional. You can be confident that your skills and knowledge in this area are well-aligned with what is needed to effectively support BC’s employers.</li>
                          <li>“Meets threshold”: Your self-assessed level of competence meets the minimum expectations of an OHS professional. You can be confident that your skills and knowledge in this area are aligned with what is needed to effectively support BC’s employers.</li>
                          <li>“For development”: Your self-assessed level of competence is below the  threshold. This may happen if you are either newly trained or have worked in OHS positions that do not consistently require this competency. It’s recommended that you focus on this competency on your future professional development planning by seeking additional training or resources.</li>
                        </ul>
                        <p>At the end of the report, we include a section that lists up to three OHS courses, and the corresponding BC post-secondary programs, that one could use to further develop their knowledge, skills, and abilities. This list is generated for any of the units self-assessed as under the threshold (labelled as "For development". While there are many OHS programs offered in BC, the three courses listed are selected for how well the course curricula matches the competency descriptors in the Framework; only the top three matches are shown.</p>
                      </div>
                      ) : (
                      <div>
                        <p>The Needs Assessment Tool (NAT) is designed for employers who hire Occupational Health and Safety (OHS) professionals working in British Columbia. The goal of this tool is to help employers describe their organization's OHS needs to current and prospective OHS professionals so that job expectations are better aligned.</p>
                        <p>The OHS Competency Framework and Profile, Second Edition, describes the role of an OHS professional through seventy-six competencies, called units. Each unit represents a single task or role that an OHS professional might be expected to perform for an organization. These units are grouped into eight thematic areas with the eighth being manufacturing industry-specific risk areas.</p>
                        <p>For each unit, there are three levels of competence: Level A, Level B, and Level C. Each level is made up of descriptions of actions a professional might take in performing the task. The actions in Level A are considered introductory, and require a basic level of skill or knowledge, whereas the actions in Level C are the most complex and require the application of knowledge and skills at a more advanced, integrated level.</p>
                        <p>The Framework was designed so that the levels build on each other: That means that if someone was able to do the actions in Level B on-the-job, they would also be able to do the actions in Level A.</p>
                        <p>The Needs Assessment Tool has been pre-populated with the minimum competency expectations of an effective OHS professional operating in BC’s manufacturing industry. Your job is to further customise the competency statements selected based on the knowledge, skills, and abilities needed for your workplace. For some units, you may find that minimum threshold is appropriate; for other units, you may want a professional at a higher level. Pay specific attention to customizing Knowledge Area 8 as there will be certain manufacturing industry risk areas that are more applicable than others.</p>
                        <p>To customize the job profile, just select or deselect the check boxes next to the competency statement. This will either add or remove the competency from the list.</p>
                        <p>After you are done, you can take your list of selections and copy them to another document. This list of statements is designed to be included in a job posting or professional development plan. You can also save a job profile to access again in the future.</p>
                        <p>Using the NAT over time can help you understand how your organization’s needs shift and identify emerging gaps.</p>
                      </div>
                      )}
                  </div>
                )}
              </Col>
            </Row>

          </Container>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Help;
