import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card, Image, Button } from "react-bootstrap";
import './cat.css'
import CustomerHeader from "../header/CustomerHeader";
import Footer from "../footer/footer";
import { useLocation, useNavigate } from "react-router-dom";
import NatPageData from "./NATPageData";

const NATPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const isExplore = location.search.includes('explore');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CustomerHeader />
            <section className="cat-section">
                <div className="title-bar">
                    <Container>
                        <p className="title">needs assessment tool</p>
                    </Container>
                </div>
                <Container>
                    <p className="body-text mt-5 mb-3">Employers can use the Needs Assessment Tool to build a job profile for a qualified Occupational Health and Safety (OHS) professional. Generate a detailed list of OHS skills and competencies that meet the organization’s specific needs, using the consistent straightforward language of the Framework.</p>
                    <div>
                        <p className="cardlist-title">Get Started</p>
                        {NatPageData.map(item => (
                            <Card className="unit-card" key={item.title}>
                                <div className="card-header">
                                </div>
                                <div className="card-body">
                                    <Container>
                                        <Row>
                                            <Col xs="12" md="2" lg="1">
                                                <div className="mb-2 mb-lg-0">
                                                    <Image
                                                        src={item.image}
                                                        alt="unit"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="12" md="10" lg="11">
                                                <div className="content-block">
                                                    <Container>
                                                        <Row>
                                                            <Col xs="12">
                                                                <p className="content-title mb-1">{item.title}</p>
                                                                <p className="content-subtitle">{item.subTitle}</p>
                                                                <p className="content-text mb-5">{item.description}</p>
                                                            </Col>
                                                            <Col xs="12" lg="3" className="d-flex">
                                                                <Button variant="link" className="d-flex align-items-center gap-1 px-0" onClick={() => navigate('/dashboard', { state: { area: `area${item.question}` }})}>
                                                                    <p className="">review the framework</p>
                                                                    <div className="carret">
                                                                        <Image
                                                                            src={
                                                                                require("../../assets/images/carret-green.svg")
                                                                                    .default
                                                                            }
                                                                            alt="carret-green"
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                            <Col xs="12" lg="3">
                                                                <Button variant="secondary" className="d-flex align-items-center gap-1" onClick={() => navigate(`/needsassessment?unit=${item.question}${isExplore ? '&explore' : ''}`)}>
                                                                    <p className="">needs assessment</p>
                                                                    <div className="carret">
                                                                        <Image
                                                                            src={
                                                                                require("../../assets/images/carret-grey.svg")
                                                                                    .default
                                                                            }
                                                                            alt="carret-grey"
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Card>
                        ))}
                    </div>
                </Container>

            </section>
            <Footer />
        </>
    )
}

export default NATPage;