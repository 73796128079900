import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import RadialSeparators from "./RadialSeparatorsCAT";
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from "react-router-dom";

const OHSSectionCAT = (props) => {
    
    const navigate = useNavigate();
    const surveyID = props.surveyID;

    return (
        <>
            <section className="ohs-section">
                <Container>
                    <Row className="steps-block">
                        <Col xs="12">
                            <div className="px-2" style={{ margin: "38px 0" }}>
                                <p className="big-title">OHS Competency Framework and Profile</p>
                                <p className="body-text">The Competency Framework describes the multidimensional skills needed to practice the Occupational Health and Safety (OHS) profession. Crafted through 
                                    collaboration, validation, and consensus, it pioneers a standardized approach. This ensures competent OHS professionals can consistently mitigate 
                                    workplace risks, and nurture a safe and healthier working environment.<br/><br/>
                                    The competency profile defines the behaviours and skills expected from a functionally adequate OHS professional. It sets the standard for entry, 
                                    intermediate, and advanced level OHS professionals by describing the competencies they should demonstrate.
                                </p>
                            </div>
                        </Col>
                        <Col xs="12">
                            <p className="cards-title">get started</p>
                        </Col>
                        <Col xs="12" lg="4" className="mb-3 mb-lg-0 px-0">
                            <div className="step-block">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/review.svg")
                                                .default
                                        }
                                        alt="review"
                                    />
                                </div>

                                <p className="img-title mb-2">Review</p>
                                <p className="img-content mb-0">Get to know the key competencies within Framework</p>
                                <div className="d-flex align-items-center gap-2 my-4">
                                    <div className="d-flex align-items-center" style={{ width: "42px", height: "42px" }}>
                                        <CircularProgressbarWithChildren
                                            value={props.progress}
                                            text={`${props.progress}%`}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        >
                                            <RadialSeparators
                                                count={12}
                                                style={{
                                                    background: "#fff",
                                                    width: "2px",
                                                    height: `${10}%`
                                                }}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <p className="progress-status-text mb-0">Complete</p>
                                </div>

                                <Button variant="primary" className="text-decoration-none" onClick={() => navigate('/metric')}>
                                    find out more
                                </Button>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className="mb-3 mb-lg-0 px-0">
                            <div className="step-block">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/assess.svg")
                                                .default
                                        }
                                        alt="assess"
                                    />
                                </div>

                                <p className="img-title mb-2">Assess</p>
                                <p className="img-content mb-4">Take CAT for a comprehensive OHS competency assessment</p>
                                <div className="d-flex align-items-center gap-2 my-4">
                                    <div className="d-flex align-items-center" style={{ width: "42px", height: "42px" }}>
                                        <CircularProgressbarWithChildren
                                            value={props.progress}
                                            text={`${props.progress}%`}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        >
                                            <RadialSeparators
                                                count={12}
                                                style={{
                                                    background: "#fff",
                                                    width: "2px",
                                                    height: `${10}%`
                                                }}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <p className="progress-status-text mb-0">Complete</p>
                                </div>
                                <Button variant="primary" className="text-decoration-none" onClick={() => navigate('/catsurvey', { state: { surveyID } })}>
                                    find out more
                                </Button>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className="mb-3 mb-lg-0 px-0">
                            <div className="step-block">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/report.svg")
                                                .default
                                        }
                                        alt="report"
                                    />
                                </div>

                                <p className="img-title mb-2">Report</p>
                                <p className="img-content mb-4">View your results from the Competency Assessment Tool</p>
                                <div className="d-flex align-items-center gap-2 my-4">
                                    <div>
                                        <Image src={
                                            require("../../assets/images/availability.svg")
                                                .default
                                        }
                                            alt="availability"
                                        />
                                    </div>
                                    <p className="progress-status-text mb-0">{props.report.length} available</p>
                                </div>
                                <Button variant="primary" className="text-decoration-none" onClick={() => navigate("/report")}>
                                    find out more
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default OHSSectionCAT;