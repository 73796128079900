import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";

const AssessmentCAT = () => {
    return (
        <>
            <section className="assessment-section">
                <Container>
                    <Row>
                        <Col xs="12">
                            <div className="px-2" style={{ margin: "58px 0" }}>
                                <p className="cards-title">What you’ll get from this assessment</p>
                                <p className="body-text">The Competency Assessment Tool (CAT) provides a detailed report that helps OHS professionals better understand their strengths and what gaps exist in 
                                    their health and safety training. The report also serves as a road map for professional development — including specialized training related to manufacturing specific risk areas.
                                </p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/settings-tool.svg")
                                                .default
                                        }
                                        alt="settings"
                                    />
                                </div>
                                <p className="cimg-content mt-4">Identify your competency strengths and gaps</p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/data-structure.svg")
                                                .default
                                        }
                                        alt="data"
                                    />
                                </div>
                                <p className=" img-content mt-4">Build a professional development road map</p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/hand-diamond.svg")
                                                .default
                                        }
                                        alt="han"
                                    />
                                </div>
                                <p className=" img-content mt-4">Demonstrate your value to employers</p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/worker.svg")
                                                .default
                                        }
                                        alt="resource"
                                    />
                                </div>
                                <p className=" img-content mt-4">Perform your OHS role with confidence</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default AssessmentCAT;