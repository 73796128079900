import React,{useEffect,useState} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import CustomerHeader from '../header/CustomerHeader';
import Footer from '../footer/footer';
import 'react-toastify/dist/ReactToastify.css';
import './report.css';
import './dashboard.css';
import ReportTableFilled from './ReportTable';
import { getCatSurvey } from '../../services/userServices';
import { useNavigate } from "react-router-dom";

const Report = (props) => {

    const [report, setReport] = useState([]);
    const navigate = useNavigate();
    const [qa, setQA] = useState(0);
    const [surveyID, setSurveyID] = useState("");
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        window.scrollTo(0, 0);
      }, []);

    useEffect(()=>{
        catreport();
    },[]);

    const catreport = () => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        setIsLoading(true);
        getCatSurvey()
            .then((response) => {
                setReport(response.data.filter(item=> {return item.user_id === uID}));
                response.data.map((index) => {
                    if ((uID === index.user_id) && (index.survey_status === "Pending")) {
                        setQA(index.qa.length);
                        setSurveyID(index.survey_id);
                        setResponse(index.qa);
                    }
                    setIsLoading(false);
                })
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const linkToCatSurvey = () => {
        try {
                navigate("/catsurvey", { state: {qa, surveyID, response} });
            } 
        catch (err) {
            console.log("error at handleSurvey");
        }
    }

    return (
        <>
            <CustomerHeader />
            <section className='report-wrapper'>
                {isLoading && <div className="section-overlay">
                    <Spinner className="loading section-loader" animation="border" />
                </div>}
                <Container className='pb-3'>
                    <p className='ms-ft-darkgrey ms-ft-40 ms-fw-700 mb-0 pt-5'>Reports</p>
                    <p className='dark-text-med ms-ft-22 mb-0 pt-3'>Showing {report.length} results.</p>
                    <p className='dark-text-med ms-ft-20 mb-0 pt-3'>Export your results on the Competency Assessment Tool, or continue using the tool:
                        <a style={{textDecoration:"none"}}
                            href="/catsurvey"
                            onClick={linkToCatSurvey}
                        >{' CAT'}</a>
                    </p>
                    <ReportTableFilled 
                        {...props} 
                        data={report}
                    />
                </Container>
            </section>
            <Footer />
        </>
    );
};

export default Report;
