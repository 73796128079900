import React from 'react';
import './footer.css';
import { Row, Col, Container, Image } from 'react-bootstrap';

const Footer = () => {
    return (
        <section className='footer-wrapper'>
            <Container>
                <Row>
                    <Col xs={12}>
                        {/* <div className="d-flex flex-column-reverse flex-md-row align-items-center justify-content-between">
                            <div className="d-flex align-items-center mb-3 mb-md-0 flex-wrap">
                                <div className="d-flex align-items-center m-3">
                                    <Image src={require('../../assets/images/circle-dot.svg').default} alt="location" />
                                    <p className='ms-ft-12 text-white ms-2 mb-0'>Address: 101-44981 Commercial Ct. Chilliwack, BC V2R 0A7</p>
                                </div>
                                <div className="d-flex align-items-center m-3">
                                    <Image src={require('../../assets/images/whatsapp.svg').default} alt="phone" />
                                    <p className='ms-ft-12 text-white ms-2 mb-0'>Phone: 604.795.9595</p>
                                </div>
                                <div className="d-flex align-items-center m-3">
                                    <Image src={require('../../assets/images/message-outline.svg').default} alt="email" />
                                    <p className='ms-ft-12 text-white ms-2 mb-0'>Email: manufacturing@safetyalliancebc.ca</p>
                                </div>
                            </div>
                            <div className="text-center text-md-end">
                                <Image src={require('../../assets/images/MSABC-stacked-regular.svg').default} alt="logo" />
                            </div>
                        </div> */}
                        <div className='d-flex justify-content-lg-between flex-wrap justify-content-center'>
                            <p className='left-content mb-2 mb-lg-0'>101-44981 Commercial Ct. Chilliwack, BC V2R 0A7 • 604.795.9595 • manufacturing@safetyalliancebc.ca</p>
                            <a className='right-content mb-2 mb-lg-0' style={{textDecoration: "none", cursor: "pointer"}} target='_blank' href="https://safetyalliancebc.ca/about/board-governance/privacy-policy/">Privacy Policy</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Footer;
