const catPageData = [
    {
        programName1: 'Environmental and Occupational Health Certificate.',
        institutionName1: 'UVic',
        institutionUrl1: 'https://continuingstudies.uvic.ca/health-wellness-and-safety/programs/environmental-and-occupational-health',
        units1: ['101', '102', '109', '201', '607', '704', '801', '805', '810', '814'],
        courseName1: [
            'Unit 101 \nHPEO 404: Human Health Risk Assessment\n',
            'Unit 101 \nHPEO 408: Occupational Health Hazards\n',
            'Unit 102 \nHPEO 404: Human Health Risk Assessment\n',
            'Unit 109 \nHPEO 411: Health Protection Technology\n',
            'Unit 201 \nHPEO 403: Risk Management: Perception and Communication\n',
            'Unit 201 \nHPEO 404: Human Health Risk Assessment\n',
            'Unit 607 \nHPEO 409: Environmental Health Economics\n',
            'Unit 704 \nHPEO 403: Risk Management: Perception and Communication\n',
            'Unit 801 \nHPEO 407: Hazardous Waste Management\n',
            'Unit 805 \nHPEO 408: Occupational Health Hazards\n',
            'Unit 810 \nHPEO 407: Hazardous Waste Management\n',
            'Unit 814 \nHPEO 405: Ergonomics\n',
        ],
    },
    {
        programName2: 'Occupational Health and Safety Diploma.',
        institutionName2: 'BCIT',
        institutionUrl2: 'https://www.bcit.ca/programs/occupational-health-and-safety-diploma-full-time-6850diplt/',
        units2: ['101' , '102' , '104' , '105' , '107' , '205' , '208' , '209' , '301' , '406' , '501' , '502' , '504' , '506' , '507' , '605' , '606' , '701' , '703' , '708' , '803' , '804', '805' , '806' , '808' , ' 810' , '814' , '815' , '817' , '818' ],
        courseName2: [
            'Unit 101 \nOCHS 4301: Occupational Hygiene 2\n',
            'Unit 102 \nOCHS 3361: Workplace Hazards and Controls 3\n',
            'Unit 104 \nOCHS 1201: Accident Causation and Analysis\n',
            'Unit 105 \nOCHS 2321: Workplace Hazards and Controls 1\n',
            'Unit 105 \nOCHS 2341: Workplace Hazards and Controls 2\n',
            'Unit 105 \nOCHS 3361: Workplace Hazards and Controls 3\n',
            'Unit 107 \nOCHS 2321: Workplace Hazards and Controls 1\n',
            'Unit 107 \nOCHS 2341: Workplace Hazards and Controls 2\n',
            'Unit 205 \nOCHS 2441: Emergency Preparedness and Response\n',
            'Unit 208 \nOCHS 2441: Emergency Preparedness and Response\n',
            'Unit 209 \nCOMM 4488: Writing Safety Program Reviews\n',
            'Unit 301 \nOCHS 1201: Accident Causation and Analysis\n',
            'Unit 406 \nOCHS 2201: Safety Program Design\n',
            'Unit 501 \nOCHS 1111: OHS Fundamentals\n',
            'Unit 501 \nOCHS 3201: Safety System Analysis\n',
            'Unit 502 \nOCHS 1111: OHS Fundamentals\n',
            'Unit 504 \nBSYS 1009: Business Information Systems\n',
            'Unit 504 \nHRMG 3060: Human Resource Management OCHS\n',
            'Unit 506 \nOCHS 3201: Safety System Analysis\n',
            'Unit 506 \nOCHS 4201: Safety Program Review 1\n',
            'Unit 506 \nOCHS 4221: Safety Program Review 2\n',
            'Unit 507 \nOCHS 3201: Safety System Analysis\n',
            'Unit 507 \nOCHS 4221: Safety Program Review 2\n',
            'Unit 605 \nHRMG 3060: Human Resource Management OCHS\n',
            'Unit 606 \nOCHS 2101: OHS Legislation\n',
            'Unit 606 \nHRMG 3220: Industrial Relations for OCHS\n',
            'Unit 701 \nOCHS 1101: BC OHS Legislation\n',
            'Unit 701 \nOCHS 2101: OHS Legislation\n',
            'Unit 703 \nCOMM 1188: Communication 1 for OCHS Professionals\n',
            'Unit 703 \nCOMM 2288: Communication 2 for OCHS Professionals\n',
            'Unit 708 \nCOMM 2288: Communication 2 for OCHS Professionals\n',
            'Unit 803 \nOCHS 3361: Workplace Hazards and Controls 3\n',
            'Unit 803 \nOCHS 4461: Fire Safety Planning and Systems\n',
            'Unit 804 \nCHEM 4418: Industrial Chemical Processes\n',
            'Unit 805 \nCHEM 2215: Chemistry 2 for OCHS\n',
            'Unit 806 \nOCHS 2421: Hazardous Materials Management\n',
            'Unit 808 \nOCHS 2341: Workplace Hazards and Controls 2\n',
            'Unit 810 \nOCHS 2421: Hazardous Materials Management\n',
            'Unit 814 \nOCHS 3521: Ergonomics\n',
            'Unit 815 \nOCHS 3301: Occupational Hygiene 1\n',
            'Unit 815 \nOCHS 4301: Occupational Hygiene 2\n',
            'Unit 817 \nPHYS 2288: Applied Physics 2 for OCHS\n',
            'Unit 817 \nOCHS 3301: Occupational Hygiene 1\n',
            'Unit 818 \nOCHS 3301: Occupational Hygiene 1\n',
        ],
    },
    {
        programName3: 'Foundations in Occupational Health and Safety.',
        institutionName3: 'BCIT',
        institutionUrl3: 'https://www.bcit.ca/programs/foundations-in-occupational-health-and-safety-associate-certificate-part-time-5325acert/',
        units3: ['102', '104', '107', '302', '406', '501', '507', '701', '702', '704', '801', '803', '804', '806', '810'],
        courseName3: [
            'Unit 102 \nOCHS 1200: Accident Causation and Analysis\n',
            'Unit 104 \nOCHS 1200: Accident Causation and Analysis\n',
            'Unit 107 \nOCHS 2320: Workplace Hazards and Controls 1\n',
            'Unit 302 \nOCHS 1200: Accident Causation and Analysis\n',
            'Unit 406 \nOCHS 2200: Safety Program Design\n',
            'Unit 501 \nOCHS 1000: Occupational Health and Safety Fundamentals\n',
            'Unit 507 \nOCHS 2200: Safety Program Design\n',
            'Unit 701 \nOCHS 2100: OHS Legislation\n',
            'Unit 702 \nOCHS 1000: Occupational Health and Safety Fundamentals\n',
            'Unit 704 \nOCHS 1000: Occupational Health and Safety Fundamentals\n',
            'Unit 801 \nOCHS 2340: Workplace Hazards and Controls 2\n',
            'Unit 803 \nOCHS 2320: Workplace Hazards and Controls 1\n',
            'Unit 804 \nOCHS 2340: Workplace Hazards and Controls 2\n',
            'Unit 806 \nOCHS 2420: Hazardous Materials Management\n',
            'Unit 810 \nOCHS 2420: Hazardous Materials Management\n',
        ],
    },
    {
        programName4: 'Masters of Science in Occupational and Environmental Hygiene.',
        institutionName4: 'UBC',
        institutionUrl4: 'https://spph.ubc.ca/programs/msc-oeh/',
        units4: ['103', '108', '201', '205', '305', '805', '815', '817', '818'],
        courseName4: [
            'Unit 103 \nSPPH 522: Topics in Environmental Health\n',
            'Unit 108 \nSPPH 563: Technical Aspects of Chemical and Biological Hazard Control\n',
            'Unit 201 \nSPPH 532: Environmental Health Risk Assessment and Communication\n',
            'Unit 205 \nSPPH 532: Environmental Health Risk Assessment and Communication\n',
            'Unit 205 \nSPPH 522: Topics in Environmental Health\n',
            'Unit 305 \nSPPH 567: Quantitative Methods for the Assessment and Analysis of Exposure Data\n',
            'Unit 805 \nSPPH 563: Technical Aspects of Chemical and Biological Hazard Control\n',
            'Unit 815 \nSPPH 535: Principles of Occupational and Environmental Hygiene\n',
            'Unit 817 \nSPPH 569: Industrial and Environmental Acoustics and Vibrations\n',
            'Unit 818 \nSPPH 569: Industrial and Environmental Acoustics and Vibrations\n',
        ],
    },
    {
        programName5: 'Advanced Certificate in Return to Work.',
        institutionName5: 'PCU-WHS',
        institutionUrl5: 'https://www.pcu-whs.ca/programs/acrtw/',
        units5: ['209', '402', '403', '405', '608', '705'],
        courseName5: [
            'Unit 209 \nWHDM 408: Workplace Insurance and Benefits\n',
            'Unit 402 \nWHDM 307: Mental Health Conditions & Work Retention\n',
            'Unit 403 \nWHDM 307: Mental Health Conditions & Work Retention\n',
            'Unit 405 \nWHDM 105: Disability Management & Return to Work\n',
            'Unit 608 \nWHDM 105: Disability Management & Return to Work\n',
            'Unit 608 \nWHDM 503: Refocusing Organizations and Systems on Return-to-Work Priorities\n',
            'Unit 705 \nWHDM 406: Case Management & Service Coordination Part 2\n',
        ],
    },
    {
        programName6: 'Bachelor of Disability Management.',
        institutionName6: 'PCU-WHS',
        institutionUrl6: 'https://www.pcu-whs.ca/programs/bdm/',
        units6: ['303', '305', '401', '402', '403', '404', '405', '406', '407', '503', '605', '606', '607', '608', '702', '703', '704 ', '705', '708', '814'],
        courseName6: [
            'Unit 303 \nWHDM 406: Case Management & Service Coordination Part 2\n',
            'Unit 305 \nWHDM 206: Decision Making & Data Analysis\n',
            'Unit 305 \nWHDM 207: Case Analysis & Research Methods\n',
            'Unit 401 \nWHDM 106: The Body and Mind at Work\n',
            'Unit 401 \nWHDM 107: Workplace Prevention & Health Promotion\n',
            'Unit 401 \nWHDM 215: Human Resources & Organizational Development\n',
            'Unit 402 \nWHDM 102: Workplace & Workplace Health Sciences\n',
            'Unit 402 \nWHDM 106: The Body and Mind at Work\n',
            'Unit 403 \nWHDM 307: Mental Health Conditions & Work Retention\n',
            'Unit 404 \nWHDM 106: The Body and Mind at Work\n',
            'Unit 404 \nWHDM 107: Workplace Prevention & Health Promotion\n',
            'Unit 404 \nWHDM 404: Economics of Workplace Health Initiatives\n',
            'Unit 405 \nWHDM 105: Disability Management & Return to Work\n',
            'Unit 406 \nWHDM 215: Human Resources & Organizational Development\n',
            'Unit 407 \nWHDM 102: Workplace & Workplace Health Sciences\n',
            'Unit 407 \nWHDM 304: Disability, Work & the Law\n',
            'Unit 407 \nWHDM 409: The Changing Nature of Work\n',
            'Unit 503 \nWHDM 306: Applied Research\n',
            'Unit 605 \nWHDM 215: Human Resources & Organizational Development\n',
            'Unit 605 \nWHDM 107: Workplace Prevention & Health Promotion\n',
            'Unit 606 \nWHDM 402: Unions and the Workplace\n',
            'Unit 607 \nWHDM 404: Economics of Workplace Health Initiatives\n',
            'Unit 608 \nWHDM 404: Economics of Workplace Health Initiatives\n',
            'Unit 702 \nWHDM 212: Introduction to Professional Ethics\n',
            'Unit 703 \nWHDM 318: Workplace Management and Communication Skills\n',
            'Unit 704 \nWHDM 206: Decision Making & Data Analysis\n',
            'Unit 705 \nWHDM 306: Applied Research\n',
            'Unit 705 \nWHDM 407: Conflict Resolution & Negotiation in the Workplace\n',
            'Unit 708 \nWHDM 306: Applied Research\n',
            'Unit 708 \nWHDM 214: Practicum Preparation and Proposal\n',
            'Unit 814 \nWHDM 401: Ergonomics\n',
        ],
    },
    {
        programName7: 'Vocational rehabilitation and disability management certificate.',
        institutionName7: 'SFU',
        institutionUrl7: 'https://www.sfu.ca/continuing-studies/programs/vocational-rehabilitation-and-disability-management-certificate.html',
        units7: ['403', '405', '702'],
        courseName7: [
            'Unit 403 \nVRDM 230: Mental Health in Disability Management\n',
            'Unit 405 \nVRDM 280: Disability Management and Return-To-Work\n',
            'Unit 702 \nVRDM 220: Professional Ethics in Vocational Rehabilitation\n',
        ],
    },
];

export default catPageData;