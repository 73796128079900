import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/css/app.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "./screens/login/login";
import Signup from "./screens/signup/signup";
import Forgot from "./screens/forgot/forgotPassword";
import Reset from "./screens/reset/resetPassword";
import Home from "./screens/home/Home";
import Dashboard from "./screens/dashboard/dashboard";
import NoPage from "./screens/nopage/nopage";
import DescriptionPage from "./screens/dashboard/DescriptionPage";
import Metric from "./screens/dashboard/Metric";
import AssessYourSkills from "./screens/dashboard/catSurvey";
import NeedsAssessment from "./screens/dashboard/natSurvey";
import CatSurvey from "./screens/CATPage/CATPage";
import NatSurvey from "./screens/CATPage/NATPage";
import Report from "./screens/dashboard/report";
import SavedJobs from "./screens/dashboard/savedJobs";
import Help from "./screens/dashboard/help";
import AdminLogin from "./screens/login/AdminLogin";
import UserProfileForm from "./screens/admin/ProfileForm";
import CatListTable from "./screens/dashboard/CatListTable";
import NatListTable from "./screens/dashboard/NatListTable";
import AdminComponent from "./screens/dashboard/adminDashboard";
import CreateCATUserForm from "./screens/admin/CreateCATUserForm";
import CreateNATUserForm from "./screens/admin/CreateNATUserForm";
import ViewCATUserTable from "./screens/admin/ViewCATUserTable";
import PrivateRoutes from "./privateRoute";
import LayoutRoute from "./screens/dashboard/LayoutRoute";
import AdminPrivateRoutes from "./AdminPrivateRoute";
import ViewNATUserTable from "./screens/admin/ViewNATUserTable";

const App = () => {

  return (
    <>
      <BrowserRouter>
        
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgotpassword" element={<Forgot />} />
          <Route path="resetpassword" element={<Reset />} />
          <Route path="adminlogin" element={<AdminLogin />} />
          <Route path="*" element={<NoPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="home" element={<Home />} exact />
            <Route path="dashboard" element={<Dashboard />} exact />
            <Route path="description" element={<DescriptionPage />} exact />
            <Route path="catsurvey" element={<CatSurvey />} exact />
            <Route path="assessyourskills" element={<AssessYourSkills />} exact />
            <Route path="natsurvey" element={<NatSurvey />} exact />
            <Route path="needsassessment" element={<NeedsAssessment />} exact />
            <Route path="metric" element={<Metric />} exact />
            <Route path="report" element={<Report />} exact />
            <Route path="savedjobs" element={<SavedJobs />} exact />
            <Route path="help" element={<Help />} exact />
            {/* <Route path="feedback" element={<Feedback />} exact /> */}
            <Route path="profile" element={<UserProfileForm />} exact />
          </Route>
          <Route element={<AdminPrivateRoutes />}>
            <Route element={<LayoutRoute />}>
              <Route path="admindashboard" element={<AdminComponent />} />
              <Route path="catlistuser" element={<CatListTable />} />
              <Route path="catedit" element={<CreateCATUserForm />} />
              <Route path="natlistuser" element={<NatListTable />} />
              <Route path="natedit" element={<CreateNATUserForm />} />
              {/* <Route path="adminfeedback" element={<AdminFeedback />} /> */}
              <Route path="/viewcatuser" element={<ViewCATUserTable />} />
              <Route path="/viewnatuser" element={<ViewNATUserTable />} />
              <Route
                path="admindashboard/catedit"
                element={<CreateCATUserForm />}
              />
              <Route
                path="admindashboard/natedit"
                element={<CreateNATUserForm />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
