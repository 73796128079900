import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import HeroBannerCAT from "./HeroBannerCAT";
import OHSSectionCAT from "./OHSSectionCAT";
import "./home.css"
import AssessmentCAT from "./AssessmentCAT";
import HeroBannerNAT from "./HeroBannerNAT";
import OHSSectionNAT from "./OHSSectionNAT";
import "./homeNAT.css"
import AssessmentNAT from "./AssessmentNAT";
import CATFooter from "../footer/CATFooter";
import Footer from "../footer/footer";
import CustomerHeader from "../header/CustomerHeader";
import NATFooter from "../footer/NATFooter";
import { getCatSurvey } from "../../services/userServices";

const HomePage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [progress, setProgress] = useState(0);
    const [report, setReport] = useState([]);
    const [surveyID, setSurveyID] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        setIsLoading(true);
        setTimeout(() => {
            getCatSurvey()
                .then((result) => {
                    const userReports = result.data.filter((item) => item.user_id === uID);
                    const pendingSurveys = userReports.filter((index) => index.survey_status === "Pending");
                    if (pendingSurveys.length > 0) {
                        setProgress(pendingSurveys[0].qa.length);
                        setSurveyID(pendingSurveys[0].survey_id);
                    }
                    setReport(userReports);
                    setIsLoading(false);
                    })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }, 2000);
    }, []);

    return (
        <>
            <CustomerHeader />
            {isLoading && <div className="section-overlay">
                <Spinner className="loading section-loader" animation="border" />
            </div>}
            {(localStorage.getItem("job_type") === "Professional") ? (
            <>
                <HeroBannerCAT 
                    {...props}
                    surveyID={surveyID}
                />
                <OHSSectionCAT 
                    {...props}
                    progress={progress}
                    surveyID={surveyID}
                    report={report}
                />
                <AssessmentCAT />
                <CATFooter />
            </>
            ) : (
            <>
                <HeroBannerNAT />
                <OHSSectionNAT />
                <AssessmentNAT />
                <NATFooter />
            </>
            )}
            <Footer />
        </>
    )
}

export default HomePage;