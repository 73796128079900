import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Image,
  Pagination,
} from "react-bootstrap";
import "./admin-dashboard.css";
import UserListTable from "./UserListTable";
import { catUserCount, natUserCount, getAllUser } from "../../services/adminService";
import DeleteModal from "./DeleteModal";
import { userdelete } from "../../services/userServices";

const AdminComponent = () => {
  const [catuserCount, setcatuserCount] = useState("");
  const [natuserCount, setnattuserCount] = useState("");
  const [searchQuery, setSearchQuery] = useState([]);
  const [allData, setAllData] = useState([]);

  const [deleteUserId, setDeleteUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = allData.filter((item) => {
    const { first_name } = item;
   // Check if searchQuery is a string before using toLowerCase
  const lowerCaseQuery = typeof searchQuery === 'string' ? searchQuery.toLowerCase() : '';
  return first_name.toLowerCase().includes(lowerCaseQuery);
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    catcountuser();
    natcountuser();
    AllUser();
  }, []);
  const AllUser = () => {
    getAllUser()
      .then((response) => {
        const userData = response.data;
        setAllData(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const catcountuser = () => {
    catUserCount()
      .then((response) => {
        const g = response.data.count;
        setcatuserCount(g.count);
      })

      .catch((error) => {
        console.error("Error fetching user count:", error);
      });
  };
 
  const natcountuser = () => {
    natUserCount()
      .then((response) => {
        const data = response.data.count;
        setnattuserCount(data.count);
      })
      .catch((error) => {
        console.error("Error fetching user count:", error);
      });
  };

  const openModal = (userid) => {
    setShowModal(true);
    setDeleteUserId(userid);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const deleteuser = () => {
    const id = deleteUserId;
    userdelete(id)
      .then((res) => {
        console.log("deleted", res);
        AllUser();
        closeModal();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <section className="admin-dashboard mt-3">
      <Container>
        <Row>
          <Col xs="12">
            {/* <p className="ms-ft-18 ms-fw-700 ms-ft-fadedblack">
              In the last 30 days,
            </p> */}
            <div className="d-flex align-items-center flex-wrap gap-5">
              <div className="count-block">
                <p className="ms-ft-24 ms-fw-700 text-white mb-2">
                  {catuserCount}
                </p>
                <p className="ms-ft-12 text-white mt-10 mb-0">CAT User</p>
              </div>
              <div className="count-block">
                <p className="ms-ft-24 ms-fw-700 text-white mb-2">
                  {natuserCount}
                </p>
                <p className="ms-ft-12 text-white mt-10 mb-0">NAT User</p>
              </div>
            </div>
            <div className="mt-50">
              <div className="table-header">
                <p className="ms-ft-18 ms-fw-700 ms-ft-fadedblack mb-1">
                  User List
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="light-text ms-ft-12 mb-0">
                    Both CAT and NAT user
                  </p>
                  <div className="d-flex align-items-center gap-3">
                    <InputGroup className="search-bar">
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={
                            require("../../assets/images/search-normal.svg")
                              .default
                          }
                          alt="search"
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Search User"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                         value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </InputGroup>
                    {/* <div>
                      <Button className="filter-btn">
                        <div className="d-flex align-items-center gap-1">
                          <Image src={require('../../assets/images/filter-search.svg').default} alt="search" />
                          Filter
                        </div>
                      </Button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                <UserListTable data={currentData} openModal={openModal} />
                {showModal && (
                  <DeleteModal
                    showModal={showModal}
                    closeModal={closeModal}
                    deleteuser={deleteuser}
                  />
                )}
              </div>
              <div className="pagination-wrapper">
                <Pagination className="mb-0">
                  <Pagination.Prev
                    className="page-prev"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {"Prev Page"}
                  </Pagination.Prev>

                  {Array.from({
                    length: Math.ceil(filteredData.length / itemsPerPage),
                  }).map((item, index) => (
                    <Pagination.Item
                      key={index}
                      active={currentPage === index + 1}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="page-next"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(filteredData.length / itemsPerPage)
                    }
                  >
                    {"Next Page"}
                  </Pagination.Next>
                </Pagination>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AdminComponent;
