import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './dashboard.css';
import { Container, Row, Col, Card, Button, Image, Spinner } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import Slider from "react-slick";
import CustomerHeader from '../header/CustomerHeader';
import Footer from '../footer/footer';
// import MetricNoDataFound from './MetricNoDataFound';
// import MetricShareFeedback from './MetricShareFeedback';
import header from './header.json';
import title from './title.json';
import keyPoints from './keyPoints.json';
import answers from './answers.json';
import questions from './questions.json';
import description from './description.json';
import { getCatSurvey } from "../../services/userServices";
import suggestedLevels from './suggested.json';
import { quesNumbers, slideColours } from '../utils/utils';

const Metric = () => {

    const [status, setStatus] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        setIsLoading(true);
        getCatSurvey()
            .then((result) => {
                const response = result.data[0];
                if (uID === (hasKeys(response) && response.user_id)) {
                    setStatus(response.qa);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, []);

    // Reference to the Slider component
    const slideRefs = {
        0: useRef(null),
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null),
        5: useRef(null),
        6: useRef(null),
        7: useRef(null),
    }

    // Previous slide function
    // Call the slickPrev method on the slider reference
    const prevSlides = {
        0: () => slideRefs[0].current.slickPrev(),
        1: () => slideRefs[1].current.slickPrev(),
        2: () => slideRefs[2].current.slickPrev(),
        3: () => slideRefs[3].current.slickPrev(),
        4: () => slideRefs[4].current.slickPrev(),
        5: () => slideRefs[5].current.slickPrev(),
        6: () => slideRefs[6].current.slickPrev(),
        7: () => slideRefs[7].current.slickPrev(),
    };

    // Next slide function
    // Call the slickNext method on the slider reference
    const nextSlides = {
        0: () => slideRefs[0].current.slickNext(),
        1: () => slideRefs[1].current.slickNext(),
        2: () => slideRefs[2].current.slickNext(),
        3: () => slideRefs[3].current.slickNext(),
        4: () => slideRefs[4].current.slickNext(),
        5: () => slideRefs[5].current.slickNext(),
        6: () => slideRefs[6].current.slickNext(),
        7: () => slideRefs[7].current.slickNext(),
    };

    var pageSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const hasKeys = (obj) => {
        if (!isEmpty(obj)) {
            return true;
        } else {
            return false;
        }
    }

    const linkToCatSurvey = () => {
        try {
            navigate("/catsurvey");
        }
        catch (err) {
            console.log("error at handleSurvey");
        }
    }

    const handleClickReadNow = (value) => {
        navigate("/description", { state: { value } });
    }

    const icons = {
        'Not Assessed': '',
        'For Development': <Image src={require('../../assets/images/alert.svg').default} alt="alert" />,
        'Meets Threshold': <Image src={require('../../assets/images/circle-check.svg').default} alt="circle-check" />,
        'Exceeds Threshold': <Image src={require('../../assets/images/circle-check.svg').default} alt="circle-check" />,
    }

    const getStatus = (quesNum) => {
        let currentStatus = 'Not Assessed';
        const statusObject = status?.find(obj => obj.id === quesNum);
        if (hasKeys(statusObject)) {
            const selectedLevel = statusObject['label'].slice(-1);
            const suggestedLevel = statusObject['criterion'];
            currentStatus = suggestedLevel === selectedLevel ? 'Meets Threshold' : selectedLevel < suggestedLevel ? 'For Development' : 'Exceeds Threshold'
        }
        return currentStatus;
    }

    return (
        <>
            <CustomerHeader />
            <section className='customer-dashboard-wrapper'>
                {isLoading && <div className="section-overlay">
                    <Spinner className="loading section-loader" animation="border" />
                </div>}
                <Container className='dashboard-container'>
                    <p className='dark-text-med ms-ft-22'>Explore your current level of competency against the <em>OHS Competency Profile and Framework</em>, or continue using the tool:
                        <a style={{ textDecoration: "none" }}
                            href="/catsurvey"
                            onClick={linkToCatSurvey}
                        >{' CAT'}</a>
                    </p>
                    <Row>
                        {Object.keys(title).map((key, titleIndex) => (
                            <Col xs="12" className='mt-3'>
                                <Card className='carousel-card pb-4 ps-4 pe-4 pe-md-0'>
                                    <div className='d-flex align-items-center py-3 px-4 justify-content-between flex-wrap'>
                                        <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700 mb-0'>{title[key]}</p>
                                        <div className="custom-carousel-navigation">
                                            <Button variant='link' className='px-2' onClick={prevSlides[titleIndex]}> <Image src={require('../../assets/images/circle-nav-left.svg').default} alt="nav-left" /></Button>
                                            <Button variant='link' className='px-2' onClick={nextSlides[titleIndex]}> <Image src={require('../../assets/images/circle-nav-right.svg').default} alt="nav-right" /></Button>
                                        </div>
                                    </div>
                                    <Slider {...pageSettings} ref={slideRefs[titleIndex]} className='dashboard-slider-block'>
                                        {quesNumbers[titleIndex].map((quesNum) => (
                                            <div className='slide-item'>
                                                <Card className={`content-card ${slideColours[key]}`} style={{ cursor: "pointer" }} onClick={() => handleClickReadNow({ title: title[key], header: header[`header${quesNum}`], question: questions[`question${quesNum}`], answer: answers[[`${quesNum}`]], desc: description[`desc${quesNum}`], key: keyPoints[`key${quesNum}`], suggested: suggestedLevels[`suggested${quesNum}`] })}>
                                                    <div className='px-3 py-4'>
                                                        <p className='ms-ft-16 ms-fw-700 ms-ft-darkgrey mb-0'>{header[`header${quesNum}`]}</p>
                                                        <p className='ms-ft-15 ms-ft-mediumgrey mt-3 ms-lh-20'>{questions[`question${quesNum}`]}</p>
                                                    </div>
                                                </Card>
                                                <div className='d-flex justify-content-center align-items-center mt-3 fixed-height-30 fixed-height-50'>
                                                    <div>
                                                        {icons[getStatus(quesNum)]}
                                                    </div>
                                                    <p className='ms-ft-16 ms-fw-700 ms-ft-lightgrey mb-0 ms-2'>{getStatus(quesNum)}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </Card>
                            </Col>
                        )
                        )}
                    </Row>
                </Container>
            </section>
            {/* <MetricNoDataFound /> */}
            {/* <MetricShareFeedback /> */}
            <Footer />
        </>

    );
};

export default Metric;