import React from "react";
import { Container, Col, Row, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HeroBannerNAT = () => {

    const navigate = useNavigate();

    return (
        <>
            <section className="hero-banner-block bg-img">
                <Container>
                    <Row className="h-100 justify-content-center content-wrapper">
                        <Col xs="12" className="d-flex flex-column justify-content-center">
                            <div className="content-block">
                                <p className="banner-title">Discover the Needs Assessment Tool</p>
                                <p className="banner-subtitle">Employers rely on Occupatoinal Health and Safety (OHS) professionals as expert advisors to inform 
                                    decisions impacting workers’ health, safety, and wellness.<br/><br/>
                                    The Needs Assessment Tool (NAT) helps employers target the competencies they need in an effective 
                                    OHS professional using a standardized approach to improve alignment with role expectations.
                                </p>
                                <div>
                                    <Button className="btn-primary" onClick={() => navigate('/natsurvey?explore')}>Take the Needs Assessment</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HeroBannerNAT;