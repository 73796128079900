import React, { useState, useEffect } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getCatSurvey } from "../../services/userServices";
import {
  PDFViewer
} from "@react-pdf/renderer";
import ReportHeader from '../header/ReportHeader';

const Chart = (props) => {

  const [performance1, setPerformance1] = useState([]);
  const [performance2, setPerformance2] = useState([]);
  const [performance3, setPerformance3] = useState([]);

  useEffect(() => {
    let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
    getCatSurvey()
      .then((result) => {
        const matchingSurvey = result.data.find((index) =>
          (uID === index.user_id) && (props.id === index.survey_id)
        );
        if (matchingSurvey) {
          const performanceData1 = [];
          const performanceData2 = [];
          const performanceData3 = [];
          matchingSurvey.qa.map((data) => {
            if ((data.criterion !== data.label.slice(6, 7)) && (data.label.slice(6, 7) === "C")) {
              performanceData1.push(data);
            }
            if ((data.criterion === data.label.slice(6, 7))) {
              performanceData2.push(data);
            }
            if ((data.criterion !== data.label.slice(6, 7)) && (data.label.slice(6, 7) !== "C")) {
              performanceData3.push(data);
            }
          });
          setPerformance1(performanceData1);
          setPerformance2(performanceData2);
          setPerformance3(performanceData3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.id]);

  const count1 = [{}];
  performance1.forEach(element => {
    count1[element] = (count1[element] || 0) + 1;
  });

  const count2 = [{}];
  performance2.forEach(element => {
    count2[element] = (count2[element] || 0) + 1;
  });

  const count3 = [{}];
  performance3.forEach(element => {
    count3[element] = (count3[element] || 0) + 1;
  });

  const maxCount = Math.max(
    Object.values(count1)[1] || 0,
    Object.values(count2)[1] || 0,
    Object.values(count3)[1] || 0
  );

  const yTickInterval = Math.ceil(maxCount / 10); // You can adjust the divisor (10) as needed

  const columnChartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "Overall Performance",
      style: {
        color: "#494F54", // Set the title text color to red
      },
    },
    colors: [ // Define your custom colors
      "#3666F6", // Exceeds Threshold
      "#494F54", // Meets Threshold
      "#247A3D"  // For Development
    ],
    yAxis: {
      labels: {
        format: "{value}%",
      },
      tickInterval: yTickInterval,
    },
    series: [
      {
        name: "Exceeds Threshold",
        type: "column",
        data: [Object.values(count1)[1]],
      },
      {
        name: "Meets Threshold",
        type: "column",
        data: [Object.values(count2)[1]],
      },
      {
        name: "For Development",
        type: "column",
        data: [Object.values(count3)[1]],
      }
    ],
  };

  return (
    <div style={{ margin: "auto" }}>
      <div className={`custom_${props.id}`}>
        <ReportHeader />

        <HighchartsReact
          highcharts={Highcharts}
          options={columnChartOptions}
          containerProps={{ style: { height: "100%", innerWidth: "100%", outerWidth: "100%", margin: "4px 40px" } }}
        />
        <PDFViewer width="900" height="0" />
      </div>
    </div>
  );
};

export default Chart;