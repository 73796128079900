import React, { useState } from 'react';
import './header.css'
import '../sidebar/sidebar.css'
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lightCategoryIcon from '../../assets/images/category.svg';
import darkCategoryIcon from '../../assets/images/category-dark.svg';
import lightUserIcon from '../../assets/images/profile.svg';
import darkUserIcon from '../../assets/images/profile-dark.svg';
import lightFeedbackIcon from '../../assets/images/feedback-icon.svg';
import darkFeedbackIcon from '../../assets/images/feedback-icon-dark.svg';
import lightSettingsIcon from '../../assets/images/setting.svg';
import darkSettingsIcon from '../../assets/images/setting-dark.svg';
import logoutIcon from '../../assets/images/logout.svg';
const AdminHeader = () => {

    const [isLightMode, setIsLightMode] = useState(true);

    const style = {
        float: "right"
    }

    return (
        <>
            <Navbar bg="white" expand="lg" className='py-4' style={style}>
                <Container className='adminheader-wrapper'>
                    {/* <Nav className="mr-auto w-100 header-search">
                        <InputGroup>
                            <InputGroup.Text>
                                <Button variant="link" className='p-0'>
                                    <Image src={require('../../assets/images/search-normal-lg.svg').default} alt="search-icon" />
                                </Button>
                            </InputGroup.Text>
                            <FormControl placeholder="Search artists, projects" />
                        </InputGroup>
                    </Nav> */}
                    <Nav className="ms-auto  align-items-center gap-2 gap-lg-5 flex-row" >
                        <Dropdown align="end">
                            <Dropdown.Toggle
                                className="avatar-container"
                                id="avatar-dropdown"
                            >
                                <div className='d-flex align-items-center'>
                                    {/* <div className="avatar me-1" style={{ backgroundImage: `url(${avatar})` }}>
                                    </div> */}
                                    <div className='text-start d-none d-lg-block'>
                                        {/* <p className='ms-ft-14 ms-ft-fadedblack mb-0 ms-fw-700'>{`${first_name} ${last_name}`}</p> */}
                                        <p className='ms-ft-14 ms-fw-700 light-text mb-0'>Admin</p>
                                    </div>
                                </div>

                            </Dropdown.Toggle>

                            {/* <Dropdown.Menu>
                                <Dropdown.Item onClick={handleSignup}>Add Account</Dropdown.Item>
                                <Dropdown.Item onClick={handleSignout}>Logout</Dropdown.Item>
                            </Dropdown.Menu> */}
                        </Dropdown>
                        {/* <Nav.Link href="#" className='bell-link'>
                            <Image src={require('../../assets/images/bell.svg').default} alt="bell" />
                        </Nav.Link> */}
                    </Nav>
                    <Navbar.Toggle aria-controls="adminheader-nav" className='ms-3' />
                </Container>
                <Navbar.Collapse id="adminheader-nav" className='mob-navmenu admin-sidebar'>
                    <Nav className="flex-column d-block d-lg-none w-100">
                        <Nav.Link className={isLightMode ? 'active' : ''}>
                            <img src={isLightMode ? lightCategoryIcon : darkCategoryIcon} alt="Category Icon" />
                            Dashboard
                        </Nav.Link>
                        <Nav.Link className={!isLightMode ? 'active' : ''}>
                            <img src={!isLightMode ? lightUserIcon : darkUserIcon} alt="User Icon" />
                            NAT
                        </Nav.Link>
                        <Nav.Link className={!isLightMode ? 'active' : ''}>
                            <img src={!isLightMode ? lightUserIcon : darkUserIcon} alt="User Icon" />
                            CAT
                        </Nav.Link>
                        <Nav.Link className={!isLightMode ? 'active' : ''}>
                            <img src={!isLightMode ? lightFeedbackIcon : darkFeedbackIcon} alt="Feedback Icon" />
                            Feedbacks
                        </Nav.Link>
                        <div className='mt-50'>
                            <Nav.Link className={`settings-menu ${!isLightMode ? 'active' : ''}`}>
                                <img src={isLightMode ? darkSettingsIcon : lightSettingsIcon} alt="Settings Icon" />
                                Settings
                            </Nav.Link>

                            <Nav.Link className='logout-menu'>
                                <img src={logoutIcon} alt="Settings Icon" />
                                Logout
                            </Nav.Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <ToastContainer
                position="top-center"
                hideProgressBar
            />
        </>
    );
};

export default AdminHeader;



