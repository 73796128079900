import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";

const AssessmentNAT = () => {
    return (
        <>
            <section className="assessment-section">
                <Container>
                    <Row>
                        <Col xs="12">
                            <div className="px-2" style={{ margin: "58px 0" }}>
                                <p className="cards-title">What you’ll get from this assessment</p>
                                <p className="body-text">The OHS Needs Assessment Tool will equip employers to better understand their health and safety needs, as it pertains to their specific areas of 
                                    risks. With the support of the Needs Assessment Tool, employers will have a better understanding of the scope of competency they need in their OHS 
                                    professional to support a resilient and sustainable health and safety management system.
                                </p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/search.svg")
                                                .default
                                        }
                                        alt="search"
                                    />
                                </div>
                                <p className="cimg-content mt-4">Get customized, detailed job descriptions</p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/humans.svg")
                                                .default
                                        }
                                        alt="humans"
                                    />
                                </div>
                                <p className=" img-content mt-4">Find OHS professional who meet your specific skills and understand your business</p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/humanity.svg")
                                                .default
                                        }
                                        alt="humanity"
                                    />
                                </div>
                                <p className=" img-content mt-4">Improve retainment of OHS talent</p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3">
                            <div className="text-center step-block ">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/tools-setting.svg")
                                                .default
                                        }
                                        alt="tools"
                                    />
                                </div>
                                <p className=" img-content mt-4">Build resilient and sustainable health and safety programs</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default AssessmentNAT;