import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, Image, InputGroup } from 'react-bootstrap';
import './reset.css';
import { pick } from "lodash";
import { userResetPassword } from "../../services/userServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
const ResetComponent = () => {

    const navigate = useNavigate();
    const [reset, setReset] = useState({});
    const [token, setToken] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const initialStateFields = {
        password: "",
        confirmPassword: "",
    };
    const [validationErrors, setValidationErrors] = useState({
        password: false,
        confirmPassword: false
    });
    useEffect(() => {
        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(currentUrl.split('?')[1]);
        const token = urlParams.get('token');
        setToken(token)
    }, [])

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onInputChange = (event) => {
        let value = '';
        const name = event.target.name;
        let lclFields = { ...reset };
        value = event.target.value;
        setReset({ ...lclFields, [name]: value });
        if ((name === 'password') || (name === 'confirmPassword')) {
            if (value.trim() === '') {
              setValidationErrors({ ...validationErrors, [name]: true });
            } else {
              setValidationErrors({ ...validationErrors, [name]: false });
            }
        }
    };

    const handleReset = (event) => {
        try {
            event.preventDefault();
            let saveReset = pick({ ...reset }, Object.keys(initialStateFields));
            if ((!saveReset.password) || (!saveReset.confirmPassword)) {
                toast.warn("Please complete all required fields.");
            } else if (saveReset.password.length < 6) {
                toast.warn("Password must be of atleast 6 characters long.");
            }else if (saveReset.password !== saveReset.confirmPassword) {
                toast.warn("Password and Confirm password must be same.");
            } else {
                var data = {
                    password: reset.password,
                    confirmPassword: reset.confirmPassword,
                    token: token
                }
                console.log("data", data);
                userResetPassword(data)
                    .then((response) => {
                        console.log(response);
                        console.log("reset response from back-end====>", response);
                        toast.success("Password updated successfully");

                        setTimeout(() => {
                            navigate("/login");
                        }, 2000);

                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Email not found.");
                    });
            }
        } catch (err) {
            console.log("error at handleReset");
        }
    }

    return (
        <>
        <section className='reset-wrapper'>
            <Container fluid>
                <Row>
                    <Col xs={12} md={7} className='d-none d-md-block p-0'>
                        <div className='reset-bg-img'>
                            <div className='img-wrapper'>
                                <Image src={require('../../assets/images/MSABC--logo.svg').default} alt="logo" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className='reset-form-wrapper'>
                        <div className='container'>
                            <div className='d-block d-md-none mb-3'>
                                <Image src={require('../../assets/images/msabc-logo-black.svg').default} alt="logo" />
                            </div>
                            <h2 className='ms-ft-30 ms-fw-600 mb-0'>Reset Password</h2>
                            <Form className='mt-50'>
                                <Form.Group controlId='formPassword' className='position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Password'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/padlock.svg').default} alt="password" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder='Enter your password'
                                            value={reset.password}
                                            onChange={onInputChange}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant='link'
                                                onClick={togglePasswordVisibility}
                                                className='password-toggle-btn'
                                            >
                                                {!showPassword ? (
                                                    <Image className='pwd-icon' src={require('../../assets/images/invisible.svg').default} alt="eye-slash" />
                                                ) : (
                                                    <Image className='pwd-icon' src={require('../../assets/images/view.svg').default} alt="eye" />
                                                )}
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {validationErrors.password && <small className="error-message">{"Password is required."}</small>}
                                </Form.Group>
                                <Form.Group controlId='formConfirmPassword' className='mt-45 position-relative'>
                                    <Form.Label>
                                        <span className="text-danger"
                                            title="required">{'*'}
                                        </span>
                                        {' Confirm password'}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <Image src={require('../../assets/images/padlock.svg').default} alt="confirm password" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name='confirmPassword'
                                            placeholder='Confirm your password'
                                            value={reset.confirmPassword}
                                            onChange={onInputChange}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant='link'
                                                onClick={toggleConfirmPasswordVisibility}
                                                className='password-toggle-btn'
                                            >
                                                {!showConfirmPassword ? (
                                                    <Image className='pwd-icon' src={require('../../assets/images/invisible.svg').default} alt="eye-slash" />
                                                ) : (
                                                    <Image className='pwd-icon' src={require('../../assets/images/view.svg').default} alt="eye" />
                                                )}
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {validationErrors.confirmPassword && <small className="error-message">{"Confirm password is required."}</small>}
                                </Form.Group>
                                <Form.Group className='d-flex align-items-center justify-content-end mt-3'>
                                    <a href='/login' className='ms-ft-12 text-decoration-none ms-ft-black'>Login here !</a>
                                </Form.Group>
                                <Button
                                    variant='primary'
                                    type='submit'
                                    className='w-100 mt-5 reset-btn'
                                    onClick={handleReset}
                                >
                                    Reset Password
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <ToastContainer
            position="top-center"
            hideProgressBar
        />
        </>
    );
};

export default ResetComponent;