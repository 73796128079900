import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Nav, Form, Accordion, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import CustomerHeader from '../header/CustomerHeader';
import Footer from '../footer/footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SaveJobModal from './SaveJobModal';
import title from './title.json';
import dataIndexes from './dataIndexes.json';
import natSurveyData from './natSurveyData';
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getFormattedDataWithBullets } from '../utils/utils';

const NatSurvey = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [button, setButton] = useState(false)
    const [job, setJob] = useState([]);
    const location = useLocation();
    const { surveyData } = location.state || {};
    const [isSticky, setIsSticky] = useState(false);
    const [copyText, setCopyText] = useState("");
    const [activeMobTab, setActiveMobTab] = useState("NAT Survey");
    const [width, setWidth] = useState(window.innerWidth);
    const isView = location.search.includes('viewSurvey');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setJob(natSurveyData.map(item => getFormattedJob(item, false)));
    }, [natSurveyData]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('unit')) {
            setActiveTab(queryParams.get('unit'));
        }
        setJob(natSurveyData.map(item => getFormattedJob(item)));
        setButton(isView ? false : true);
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            const rightContent = document.querySelector('.right-tab-content');
            const topOffset = 20;
            const isScrolledPast = window.scrollY > rightContent?.offsetTop || 0 - topOffset;
            if (isScrolledPast) {
                setIsSticky(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            if (surveyData) {
                const { answer } = surveyData;
                if (Array.isArray(answer)) {
                    const sorted = answer.sort((a, b) => a.unit - b.unit);
                    setJob(sorted);
                }
            }
            setIsLoading(false);
        }, 1000);
    }, [surveyData]);

    useEffect(() => {
        window.scrollTo(0, 1);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const getFormattedJob = (item, suggested = true) => ({
        ...item,
        answerText: Object.keys(item.answerText).reduce((result, key) => {
            result[key] = (item.suggested === key && suggested)
                ? item.answerText[key].map(question => ({ ...question, value: true }))
                : item.answerText[key];
            return result;
        }, {})
    });

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const handleCheckboxChange = (e, data) => {
        const { checked, name } = e.target;
        const { unit, answerLevel, unitLevel } = data;
        setJob((prevJob) =>
            prevJob.map((item) => {
                if (item.unit === unit) {
                    item.answerText[answerLevel] = item.answerText[answerLevel].map((question) => {
                        if (question.unit === name) {
                            question.value = checked;
                        }
                        return question;
                    });
                }
                return item;
            })
        );

        setButton((prevButton) => {
            const hasSelectedItems = job.some((item) => item.enabled > 0);
            return hasSelectedItems;
        });
    };

    const handleJobDescriptionChange = (e) => {
        const { checked, value } = e.target;
        const jobDetails = job.find(item => item.unit === value);
        if (jobDetails) {
            setJob((prevJob) => prevJob?.map((item) => {
                if (item && item.unit === value) {
                    item.enabled = checked;
                }
                return item;
            }));
        } else {
            const selectedJob = natSurveyData.find(item => item.unit === value);
            setJob([...new Set([...job, getFormattedJob(selectedJob)])]);
        }

        setButton((prevButton) => {
            const hasSelectedItems = job.some((item) => item.enabled > 0);
            return hasSelectedItems;
        });
    };

    const handleClick = (tabName) => {
        setActiveMobTab(tabName);
        window.scrollTo(0, 0);
    };

    const handleCopy = () => {
        try {
            if (Array.isArray(job)) {
                setCopyText(getFormattedDataWithBullets(job));
                toast.success("Copied.");
            }
        } catch (error) {
            console.error('Error while copying', error);
        }
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    };

    const isMobile = width <= 768;

    useEffect(() => {
        const handleScroll = () => {
            const topContent = document.querySelector('.top-menu');
            const topOffset = 20;
            const isScrolledPast = window.scrollY > topContent.offsetTop - topOffset;
            if (isScrolledPast) {
                setIsSticky(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <CustomerHeader job={job} surveyData={surveyData} button={button} isView={isView} />
            <section className='nat-survey-section'>
                {isLoading && <div className="section-overlay">
                    <Spinner className="loading section-loader" animation="border" />
                </div>}
                <Container className='mt-50'>
                    <p className='ms-ft-darkgrey ms-ft-40 ms-fw-700 mb-4'>Needs Assessment Tool</p>
                    <p className='dark-text-med ms-ft-18 mb-10'>Use this tool to select job descriptors from the <em>Framework</em> that best describe your organization’s OHS needs. You can then save these descriptors for later, or copy them and use them within job postings, performance reviews, and more.</p>
                    <p className='dark-text-med ms-ft-15'>The Needs Assessment Tool has been pre-populated with the minimum competency expectations of an effective OHS professional operating in BC’s manufacturing industry. Your job is to further customise the competency statements selected based on the knowledge, skills, and abilities needed for your workplace. To customize the job profile, select or deselect the check boxes next to the competency statement. This will either add or remove the competency from the list. When you are finished customizing all 8 Knowledge Areas, use the "Save" button to save your selections for future reference under your “Saved Jobs”. You can also use the "Copy to Clipboard" button to paste the content directly into your job profile document.</p>
                    <Row>
                        <Col xs="12" className={`top-menu mb-3 ${isSticky ? 'sticky-top-content' : ''}`}>
                            <ul className="help-tab-wrapper list-unstyled d-flex d-lg-none mb-0 justify-content-center">
                                <li key={"NATSurvey"} className={activeMobTab === "NAT Survey" ? "active" : ""} onClick={() => handleClick("NAT Survey")}>NAT Survey</li>
                                <li key={"JobRequirements"} className={activeMobTab === "Job Requirements" ? "active" : ""} onClick={() => handleClick("Job Requirements")}>Job Description</li>
                            </ul>
                        </Col>
                        {
                            (!isMobile || (isMobile && activeMobTab === "NAT Survey")) &&
                            (
                                <Col>
                                    <div className="tab-pills-container">
                                        <Nav className="nav-wrapper nav">
                                            {Object.keys(title).map(key => (
                                                <Nav.Item key={`nav-${key}`}>
                                                    <Nav.Link
                                                        className={`rounded-pill ${key.slice(-1) === activeTab ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(key.slice(-1))}
                                                    >
                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>
                                                            {title[key]}
                                                        </Tooltip>}
                                                        >
                                                            <span>{title[key].slice(0, 1)}</span>
                                                        </OverlayTrigger>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    </div>
                                </Col>
                            )
                        }
                        {
                            (!isMobile || (isMobile && activeMobTab === "NAT Survey")) &&
                            (
                                <Col xs="12" lg="7">
                                    <Row key={`data-title${activeTab}`}>
                                        <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700 mb-4'>{title[`title${activeTab}`]}</p>
                                        {job.slice(dataIndexes[`title${activeTab}`][0], dataIndexes[`title${activeTab}`][1]).map((item) => {
                                            const { id, natDesc, questionHeader, answerText, unit, enabled, suggested } = item;
                                            return (
                                                <div className='left-tab-content' key={id}>
                                                    <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700 mb-3'>{questionHeader}</p>
                                                    <Container className='mb-2'>
                                                        <div className='circle-line'>
                                                            <div className="d-flex ps-3">
                                                                <Form.Check
                                                                    disabled={isView}
                                                                    type="switch"
                                                                    value={unit}
                                                                    name={"Desc"}
                                                                    id="Desc"
                                                                    checked={enabled}
                                                                    onChange={(e) => handleJobDescriptionChange(e)}
                                                                />
                                                                <div className='ms-2 w-100 level-checkbox'>
                                                                    <Form.Label className='ms-ft-22'>
                                                                        {natDesc}
                                                                        {/* <span className='ms-ft-primary text-decoration-none'>(Unit {unitNumber})</span> */}
                                                                    </Form.Label>
                                                                    <Accordion defaultActiveKey={suggested} className='checkbox-accordion'>
                                                                        {Object.keys(answerText).map((key) => (
                                                                            <Accordion.Item eventKey={key}>
                                                                                <Accordion.Header>{`${key.slice(0, 5).toUpperCase()} ${key.slice(-1)}`}</Accordion.Header>
                                                                                <Accordion.Body className='checkbox-body'>
                                                                                    {answerText[key].map((question) => <Form.Check
                                                                                        disabled={!enabled || isView}
                                                                                        type="checkbox"
                                                                                        label={question.question}
                                                                                        // value={item.value}
                                                                                        name={question.unit}
                                                                                        id={key}
                                                                                        checked={question.value}
                                                                                        onChange={(e) => handleCheckboxChange(e, { unit, answerLevel: key, unitLevel: question.unit })}
                                                                                    />)}
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        ))}
                                                                    </Accordion>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Container>
                                                </div>
                                            )
                                        }
                                        )}
                                    </Row>
                                </Col>
                            )
                        }
                        {
                            (!isMobile || (isMobile && activeMobTab === "Job Requirements")) &&
                            (
                                <Col xs="12" lg="5">
                                    <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700 mb-5'></p>
                                    <div className={`right-tab-content ${isSticky ? 'sticky-right-content' : ''}`}>

                                        <>
                                            <div className='d-flex align-items-center justify-content-between flex-wrap'>
                                                <h2 className='ms-ft-35 ms-fw-700 mb-10'>Job Requirements</h2>
                                                <p className='ms-ft-darkgrey ms-ft-18 ms-fw-700'>{title[`title${activeTab}`]}</p>
                                                {/* <div className='icon-circle'>
                                                        <Image src={require('../../assets/images/minimize-icon.svg').default} alt='minimize' />
                                                    </div> */}
                                            </div>
                                            <div className='job-description-list'>
                                                {job?.length > 0 && job.slice(dataIndexes[`title${activeTab}`][0], dataIndexes[`title${activeTab}`][1]).map((item) => {
                                                    if (item?.enabled) return (<div key={item?.unit}>
                                                        <p className='ms-fw-600'>{item?.natDesc}</p>
                                                        {/* <span className='ms-ft-primary text-decoration-none'>(Unit {item?.unit})</span> */}
                                                        {<ul className='pl-3'>
                                                            {Object.keys(item?.answerText).map(key => (
                                                                item?.answerText[key].map(question => {
                                                                    if (question.value) {
                                                                        return (
                                                                            <li className='ms-ft-15 ms-ft-mediumgrey mt-2'>{question.question}</li>
                                                                        )
                                                                    }
                                                                }))
                                                            )}
                                                        </ul>
                                                        }
                                                    </div>)
                                                }
                                                )}
                                            </div>
                                            <div className='d-flex mt-auto justify-content-center flex-wrap'>
                                                <div className='m-2'>
                                                    <CopyToClipboard
                                                        text={copyText}
                                                        onCopy={handleCopy}
                                                    >
                                                        <Button className='btn-secondary' disabled={isView} >Copy to Clipboard</Button>
                                                    </CopyToClipboard>
                                                </div>
                                                <div className='m-2'>
                                                    <SaveJobModal
                                                        {...props}
                                                        data={job}
                                                        surveyData={surveyData}
                                                        isButton={button}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </Col>
                            )
                        }

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
};

export default NatSurvey;