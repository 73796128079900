import React from "react";
import Table from "react-bootstrap/Table";
import { Image, Button, Badge } from "react-bootstrap";
import avatar from "../../assets/images/user.svg";
import { useNavigate } from "react-router-dom";

function UserListTable({ data, openModal }) {

  const navigate = useNavigate();

  const tableHeadings = [
    "User Name",
    "User Type",
    "Total # of Surveys",
    "Action",
  ];

  const useredit = (userId, userData) => {
    if (userData.job_type === "Employer") {
      navigate("/admindashboard/natedit", {
        state: { userId, userData },
      });
    } else {
      navigate("/admindashboard/catedit", {
        state: { userId, userData },
      });
    }
  };
 

  return (
    <Table responsive borderless className="userlist-table">
      <thead>
        <tr>
          {tableHeadings.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr style={{ backgroundColor: "#fff" }}>
              <td colSpan={tableHeadings.length} className='text-center'>
                  <Image src={require('../../assets/images/No data-cuate.svg').default} alt="no reports" />
                  <p className='ms-ft-40 ms-ft-darkgrey ms-fw-700'>No Users found</p>
                  <p className='ms-ft-22 ms-ft-mediumgrey'>Users were not found in the database.</p>
              </td>
          </tr>
        ) : (data.map((data) => (
          <tr key={data.id}>
            <td>
              <div className="user-avatar">
                {/* <div className={`avatar-image ${data.userType === 'NAT USER' ? 'bordered' : ''}`}>
                                    <Image src={require('../../assets/images/avatar1.svg').default} alt={`Avatar ${data.id}`} />
                                </div> */}
                <div
                  className="avatar me-1"
                  style={{ backgroundImage: `url(${avatar})` }}
                ></div>
                <span>{`${data.first_name} ${data.last_name}`}</span>
              </div>
            </td>

            <td>
              <Badge
                bg={data.job_type === "Professional" ? "primary" : "secondary"}
              >
                {data.job_type === "Professional" ? "CAT USER" : "NAT USER"}
              </Badge>
            </td>
            <td>
              <div className="count">
                {data.job_type === "Professional" ? data.num_cat_survey : data.num_nat_survey}
              </div>
            </td>
            <td className="d-flex gap-2 align-items-center">
              <Button variant="link">
                <Image
                  src={require("../../assets/images/delete-icon.svg").default}
                  alt="Delete"
                  onClick={() => openModal(data.user_id)}
                />
              </Button>
              <Button variant="link">
                <Image
                  src={require("../../assets/images/edit-grey.svg").default}
                  alt="Edit"
                  onClick={() => useredit(data.user_id, data)}
                />
              </Button>
            </td>
          </tr>
        ))
        )}
      </tbody>
    </Table>
  );
}

export default UserListTable;
