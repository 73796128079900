import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import CustomerHeader from '../header/CustomerHeader';
import Footer from '../footer/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from "react-router-dom";
import data from './data';
import { catSurvey, updateCatSurvey, deleteCatSurvey } from "../../services/userServices";
import { getCatSurvey } from "../../services/userServices";
import RestartSurveyModal from './RestartSurveyModal';

const CatSurvey = () => {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const [currentQuestion, setCurrentQuestion] = useState(location.state.qa);
    const [assessSkills, setAssessSkills] = useState();
    const [checked, setChecked] = useState(null);
    const [options, setOptions] = useState([
        { id: 1, label: 'LEVEL A', text: data[currentQuestion].answerText[0].levelA },
        { id: 2, label: 'LEVEL B', text: data[currentQuestion].answerText[0].levelB },
        { id: 3, label: 'LEVEL C', text: data[currentQuestion].answerText[0].levelC }
    ]);
    const [isChecked, setIsChecked] = useState(null);
    const [localOptions, setLocalOptions] = useState([]);
    const [saved, setSaved] = useState(false);
    const [initialID, setInitialID] = useState("");
    const [initialResponse, setInitialResponse] = useState([]);
    const [radio, setRadio] = useState(null);
    const [previous, setPrevious] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setShowModal(false);
    };

    const getCatSurveyData = () => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        getCatSurvey()
            .then((result) => {
                const matchingSurvey = result.data.find((index) =>
                    (uID === index.user_id) && (index.survey_status === "Pending")
                );
                if (matchingSurvey) {
                    setLocalOptions(matchingSurvey.qa);
                    const performanceData = matchingSurvey.qa.map((data) => data.label);
                    setInitialID(matchingSurvey.survey_id);
                    setRadio(performanceData);
                    setInitialResponse(matchingSurvey.qa);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getCatSurveyData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (localOptions !== null) {
            const currentQuestionData = localOptions.find(item => item.id === currentQuestion + 1);
            if (currentQuestionData) {
                setChecked(currentQuestionData.label)
            } else {
                setChecked(null);
            }
        }
    }, [currentQuestion, localOptions]);

    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        setChecked(value);
        setSaved(true);
        const selectedQuestion = initialResponse.find((item) => item.id === currentQuestion + 1);
        if (selectedQuestion) {
            setInitialResponse((prevQues) => {
                return prevQues.map((item) => {
                    if (item.id === currentQuestion + 1) {
                        return { ...item, label: value };
                    }
                    return item;
                });
            });
        } else {
            var input = {
                id: currentQuestion + 1,
                label: value,
                question: data[currentQuestion].questionText,
                questionHeader: data[currentQuestion].questionHeader,
                criterion: data[currentQuestion].suggested
            };
            setInitialResponse([...initialResponse, input])
        }
    };

    const navigate = useNavigate();

    const handleSave = () => {
        setIsLoading(true);
        try {
            if (!checked) {
                toast.warn("Select any one option.");
                setIsLoading(false);
                return;
            }
            if (initialResponse) {
                if (currentQuestion !== 0 && initialID !== "") {
                    let updateData = {
                        qa: JSON.parse(JSON.stringify(initialResponse)),
                        survey_status: currentQuestion === 75 ? "Completed" : "Pending",
                        user_id: JSON.parse(JSON.stringify(localStorage.getItem('user_id')))
                    };
                    updateCatSurvey(updateData, initialID).then((response) => {
                        getCatSurveyData();
                        console.log("CAT survey response from back-end====>", response);
                        if (currentQuestion === 75) {
                            setSaved(true);
                            setTimeout(() => {
                                setIsLoading(false);
                                navigate("/home");
                                toast.success("Cat survey saved successfully.");
                            }, 1000);
                        } else {
                            if ([8, 17, 24, 31, 39, 47, 55].includes(currentQuestion)) {
                                setTimeout(() => {
                                    setIsLoading(false);
                                    navigate("/catsurvey");
                                    toast.success("Survey saved successfully.");
                                }, 1000);
                            } else {
                                setTimeout(() => {
                                    toast.success("Survey saved successfully.");
                                    setIsLoading(false);
                                }, 1000);
                            }
                            setSaved(false);
                        }
                    })
                        .catch((err) => {
                            console.log(err);
                            toast.error("Save failed.");
                            setIsLoading(false);
                        });
                } else {
                    let inputData = {
                        qa: JSON.parse(JSON.stringify(initialResponse)),
                        survey_status: currentQuestion === 75 ? "Completed" : "Pending",
                        user_id: JSON.parse(JSON.stringify(localStorage.getItem('user_id')))
                    };
                    catSurvey(inputData).then((response) => {
                        getCatSurveyData();
                        console.log("CAT survey response from back-end====>", response);
                        if (currentQuestion === 75) {
                            setSaved(true);
                            setTimeout(() => {
                                navigate("/home");
                                toast.success("Cat survey saved successfully.");
                            }, 1000);
                        } else {
                            setTimeout(() => {
                                toast.success("Survey saved successfully.");
                                setIsLoading(false);
                                setSaved(false);
                            }, 1000);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Save failed.");
                        setIsLoading(false);
                    });
            }
            }
        } catch (err) {
            console.log("error at handleSave");
            setIsLoading(false);
        }
    }

    const handlePrevious = () => {
        setIsLoading(true);
        try {
            setTimeout(() => {
                setPrevious(true);
                const previousQuestion = currentQuestion - 1;
                setCurrentQuestion(previousQuestion);
                setIsChecked(isChecked);
                getCatSurveyData();
                setOptions([
                    { id: 1, label: 'LEVEL A', text: data[previousQuestion].answerText[0].levelA },
                    { id: 2, label: 'LEVEL B', text: data[previousQuestion].answerText[0].levelB },
                    { id: 3, label: 'LEVEL C', text: data[previousQuestion].answerText[0].levelC }
                ]);
                setIsLoading(false);
            }, 1000);
        } catch (err) {
            console.log("error at handlePrevious");
            setIsLoading(false);
        }
    }

    const handleNext = () => {
        setIsLoading(true);
        try {
            if (checked !== null) {
                setTimeout(() => {
                    const nextQuestion = currentQuestion + 1;
                    setCurrentQuestion(nextQuestion);
                    getCatSurveyData();
                    if (saved) {
                        handleSave();
                    }
                    setIsLoading(false);
                    setChecked(null);
                    setSaved(false);
                    setPrevious(false);
                    setOptions([
                        { id: 1, label: 'LEVEL A', text: data[nextQuestion].answerText[0].levelA },
                        { id: 2, label: 'LEVEL B', text: data[nextQuestion].answerText[0].levelB },
                        { id: 3, label: 'LEVEL C', text: data[nextQuestion].answerText[0].levelC }
                    ]);
                }, 1000);
            } else {
                toast.warn("Select any one option.");
                setIsLoading(false);
            }
        } catch (err) {
            console.log("error at handleNext");
            setIsLoading(false);
        }
    }

    const handleRestart = () => {
        deleteCatSurvey(initialID).then(() => {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                navigate("/home");
            }, 1000);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        const firstChar = data[currentQuestion].questionHeader.charAt(0);
        switch (firstChar) {
            case '1':
                setAssessSkills(9);
                break;
            case '2':
                setAssessSkills(18);
                break;
            case '3':
                setAssessSkills(25);
                break;
            case '4':
                setAssessSkills(32);
                break;
            case '5':
                setAssessSkills(40);
                break;
            case '6':
                setAssessSkills(48);
                break;
            case '7':
                setAssessSkills(56);
                break;
            case '8':
                setAssessSkills(76);
                break;
            default:
                setAssessSkills();
                break;
        }
    }, [data[currentQuestion].questionHeader]);

    return (
        <>
            <CustomerHeader />
            {isLoading && <div className="section-overlay">
                <Spinner className="loading section-loader" animation="border" />
            </div>}
            <section className='cat-survey-wrapper'>
                <Container className='dashboard-container'>
                    <p className='ms-ft-darkgrey ms-ft-40 ms-fw-700 mb-4'>Competency Assessment Tool</p>
                    <p className='dark-text-med ms-ft-18 mb-0'>Use this tool to identify your level of competency in key areas of the OHS profession. You can then view how your levels of competence stack up against the competency profile contained within the <em>Framework</em>, and identify areas for professional development and areas of strength.</p>
                    <Row>
                        <Col>
                            <div className='d-flex justify-content-between my-3 align-items-center flex-wrap'>
                                <p className='ms-ft-darkgrey ms-ft-22 ms-fw-700 mb-0'>{data[currentQuestion].questionHeader}</p>
                                {currentQuestion !== 0 && <div>
                                    <Button variant='primary' className='my-2' onClick={openModal} disabled={(currentQuestion === 0) || isLoading}>Restart survey</Button>
                                </div>}

                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-5'>
                        <Col md={4} className='bg-white pt-5 pb-2 px-3 d-flex flex-column'>
                            <div className='d-flex flex-column h-100'>
                                <p className='ms-ft-16 ms-fw-700 ms-ft-primary'>{`Question ${currentQuestion + 1} of ${data.length}`}</p>
                                <h2 className='ms-ft-35 ms-fw-700 mb-0'>{data[currentQuestion].questionText}</h2>
                                <p className='ms-ft-15 ms-ft-mediumgrey mt-20'>Read the competency statements for levels A, B, and C, and select the one that best describes your current level of ability.</p>
                                <p className='dark-text-med ms-ft-17'>The actions in Level A are considered introductory, and require a basic level of skill or knowledge, whereas the actions in Level C are the most complex and require the application of knowledge and skills at a more advanced integrated level. The Framework is designed so that the levels build on each other. That means that if someone was able to do the actions in Level B on-the-job, they should also be able to do the actions in Level A.</p>
                                <div className='buttons-container d-flex mt-auto justify-content-between flex-wrap'>
                                    <div className='m-2'>
                                        <Button className='btn-info' onClick={handlePrevious} disabled={(currentQuestion === 0) || isLoading}>Previous</Button>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {([8, 17, 24, 31, 39, 47, 55].includes(currentQuestion)) ? (
                                            <div className='m-2'>
                                                <Button className='btn-primary' onClick={handleSave} disabled={isLoading}>
                                                    {(currentQuestion === 75) ? "Finish" : "Submit"}
                                                </Button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex" }}>
                                                {(currentQuestion !== 75) && (
                                                    <div className='m-2'>
                                                        <Button className='btn-secondary' onClick={handleNext} disabled={(currentQuestion === 75) || isLoading}>
                                                            {"Next"}
                                                        </Button>
                                                    </div>
                                                )}
                                                <div className='m-2'>
                                                    <Button className='btn-primary' onClick={handleSave} disabled={!saved || isLoading}>{(currentQuestion === 75) ? "Finish" : "Save"}</Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} className='checkbox-section'>
                            {options.map((option) => (
                                <Container
                                    className={`custom-checkbox ${(option.label === checked) ? 'selected' : ''}`}
                                    style={{ backgroundColor: (option.label === (checked)) ? '#00863F' : '' }}
                                >
                                    <Row>
                                        <Col xs={2} md={1} className="d-flex" key={option.id}>
                                            {<Form.Check
                                                type="radio"
                                                value={option.label}
                                                name={"levels"}
                                                checked={option.label === checked}
                                                disabled={isLoading}
                                                onChange={(e) => handleCheckboxChange(e)}
                                            />}
                                        </Col>
                                        <Col>
                                            <Form.Label style={{ color: (option.label === ((checked))) ? 'white' : '' }}>
                                                {option.label}
                                            </Form.Label>
                                            <ul className={`checkbox-content mt-2 ${(option.label === checked) ? 'selected' : ''}`}>
                                                {option.text.map((number) =>
                                                    <li className={`mt-1`}>{number}</li>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>
            <ToastContainer
                position="top-center"
                hideProgressBar
            />
            {showModal && (
                <RestartSurveyModal
                    showModal={showModal}
                    closeModal={closeModal}
                    handleRestart={handleRestart}
                    isLoading={isLoading}
                />
            )}
            <Footer />
        </>
    );
};

export default CatSurvey;
