import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card, Image, Button, Spinner } from "react-bootstrap";
import './cat.css'
import CustomerHeader from "../header/CustomerHeader";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
import { getCatSurvey, deleteCatSurvey } from "../../services/userServices";
import RestartSurveyModal from '../dashboard/RestartSurveyModal';
import CatPageData from './NATPageData';

const CATPage = () => {

    const [qa, setQA] = useState(0);
    const [surveyID, setSurveyID] = useState("");
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        setIsLoading(true);
        setTimeout(() => {
            getCatSurvey()
                .then((result) => {
                    const userReports = result.data.filter((item) => item.user_id === uID);
                    const pendingSurveys = userReports.filter((index) => index.survey_status === "Pending");
                    if (pendingSurveys.length > 0) {
                        setQA(pendingSurveys[0].qa.length);
                        setSurveyID(pendingSurveys[0].survey_id);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }, 2000);
    }, []);


    const handleRestart = () => {
        if (surveyID) {
            deleteCatSurvey(surveyID).then(() => {
                setIsLoading(true);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate("/home");
                }, 2000);
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const getStatus = (quesNum) => {
        const [start, end] = quesNum;
        let status = 'Not started. Please complete previous knowledge area.'
        if (qa === 0 && start === 0) {
            status = 'Not started';
        } else if ((qa >= start) && (qa < end)) {
            status = 'In Progress';
        } else if (qa >= end) {
            status = 'Complete';
        }
        return status;
    }

    return (
        <>
            <CustomerHeader />
            {isLoading && <div className="section-overlay">
                <Spinner className="loading section-loader" animation="border" />
            </div>}
            <section className="cat-section">
                <div className="title-bar">
                    <Container>
                        <p className="title">competency assessment tool</p>
                    </Container>
                </div>
                <Container>
                    <p className="body-text mt-5 mb-3">Occupational Health and Safety (OHS) professionals can use the Competency Assessment Tool (CAT) to self-assess their knowledge and competencies
                        against the OHS Competency Framework. A detailed report will show your mastery of key competencies for professional development planning and future job applications.
                    </p>
                    <div>
                        <div className='d-flex justify-content-between mb-2 align-items-center flex-wrap'>
                            <p className="cardlist-title">Get Started</p>
                            {qa !== 0 && <div>
                                <Button className='my-2' onClick={openModal} disabled={qa === 0}>Restart survey</Button>
                            </div>}
                        </div>
                        {CatPageData.map((item) => (
                            <Card className="unit-card">
                                <div className="card-header">
                                    <Container>
                                        <Row>
                                            <Col xs="12" md="2" lg="1">
                                            </Col>
                                            <Col xs="12" md="10" lg="11">
                                                <Container>
                                                    <Row>
                                                        <Col xs="12">
                                                            <p className="status-text mb-0">STATUS:<span> {getStatus(item.qa)}</span></p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="card-body">
                                    <Container>
                                        <Row>
                                            <Col xs="12" md="2" lg="1">
                                                <div className="mb-2 mb-lg-0">
                                                    <Image src={item.image} alt="unit" />
                                                </div>
                                            </Col>
                                            <Col xs="12" md="10" lg="11">
                                                <div className="content-block">
                                                    <Container>
                                                        <Row>
                                                            <Col xs="12">
                                                                <p className="content-title mb-1">{item.title}</p>
                                                                <p className="content-subtitle">{item.subTitle}</p>
                                                                <p className="content-text mb-5">{item.description}</p>
                                                            </Col>
                                                            <Col xs="12" lg="3" className="d-flex">
                                                                <Button variant="link" className="d-flex align-items-center gap-1 px-0" onClick={() => navigate('/dashboard', { state: { area: `area${item.question}` } })}>
                                                                    <p className="">review the framework</p>
                                                                    <div className="carret">
                                                                        <Image
                                                                            src={
                                                                                require("../../assets/images/carret-green.svg")
                                                                                    .default
                                                                            }
                                                                            alt="carret-green"
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                            <Col xs="12" lg="3">
                                                                <Button 
                                                                    className="d-flex align-items-center gap-1" 
                                                                    disabled={qa < item.qa[0]}
                                                                    variant="secondary"
                                                                    onClick={() => navigate('/assessyourskills', { state: { qa: item.qa[0] } })}
                                                                >
                                                                    <p className="">assess your skills</p>
                                                                    <div className="carret">
                                                                        <Image
                                                                            src={
                                                                                require("../../assets/images/carret-grey.svg")
                                                                                    .default
                                                                            }
                                                                            alt="carret-grey"
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Card>
                        ))}
                    </div>
                </Container>
                {showModal && (
                    <RestartSurveyModal
                        showModal={showModal}
                        closeModal={closeModal}
                        handleRestart={handleRestart}
                        isLoading={isLoading}
                    />
                )}
            </section>
            <Footer />
        </>
    )
}

export default CATPage