import React from "react";
import { Container, Col, Row, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HeroBannerCAT = (props) => {

    const navigate = useNavigate();
    const surveyID = props.surveyID;

    return (
        <>
            <section className="hero-banner-block bg-img">
                <Container>
                    <Row className="h-100 justify-content-center content-wrapper">
                        <Col xs="12" className="d-flex flex-column justify-content-center">
                            <div className="content-block">
                                <p className="banner-title">Discover the Competency Assessment Tool</p>
                                <p className="banner-subtitle">The Competency Assessment Tool (CAT) allows Occupational Health and Safety (OHS) professionals to discover and document their knowledge and skills to help employers build a safer and more resilient workplace.</p>
                                <div>
                                    <Button className="btn-primary" onClick={() => navigate('/catsurvey', { state: { surveyID } })}>Take the Competency Assessment</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HeroBannerCAT;