import React from "react";
import phone from '../../assets/images/phone-dark.png';
import email from '../../assets/images/mail-dark.png';
import user from '../../assets/images/user-dark.png';
import logo from "../../assets/images/msabc_logo.png";

const ReportHeader = () => {
    return (
        <div style={{ margin: "0 40px" }}>
            <div style={{ padding: "10px" }}>
                <img src={logo} alt="logo" />
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                <div style={{ backgroundColor: "#eee", padding: "10px", width: "100%", display: "flex", alignItems: "center" }}>
                    <div>
                        <p style={{ fontSize: "32px", color: "#494f54" }}>Competency Profile</p>
                    </div>
                </div>
                <div style={{ backgroundColor: "#eee", padding: "10px", width: "100%" }}>

                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ padding: "0 3px", width: "20px" }}>
                                <img src={user} alt="user" />
                            </div>
                            <p style={{
                                fontSize: "16px",
                                color: "#494f54",
                                marginBottom: "0",
                                marginLeft: "10px"
                            }}>
                                {localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')}
                            </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ padding: "0 3px", width: "20px" }}>
                                <img src={email} alt="email" />
                            </div>
                            <p style={{
                                fontSize: "16px",
                                color: "#494f54",
                                marginBottom: "0",
                                marginLeft: "10px"
                            }}>
                                {localStorage.getItem('email')}
                            </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20px" }}>
                                <img src={phone} alt="phone" />
                            </div>
                            <p style={{
                                fontSize: "16px",
                                color: "#494f54",
                                marginBottom: "0",
                                marginLeft: "10px"
                            }}>
                                {localStorage.getItem('phone')}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportHeader;