import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import RadialSeparators from "./RadialSeparatorsNAT";
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getNatSurvey } from "../../services/userServices";

const OHSSectionNAT = () => {

    const [savedJobs, setSavedJobs] = useState(0);
    const navigate = useNavigate();
    
    useEffect(() => {
        getsavedJob();
        window.scrollTo(0, 0);
    }, []);

    const getsavedJob = () => {
        let uID = JSON.parse(JSON.stringify(localStorage.getItem('user_id')));
        getNatSurvey()
          .then((res) => {
            const length = res.data.filter(item => { return item.user_id === uID }).length || 0;
            setSavedJobs(length);
          })
          .catch((err) => {
            toast("Unable to retrieve saved jobs.");
            console.log(err);
          });
      };
    

    return (
        <>
            <section className="ohs-section">
                <Container>
                    <Row className="steps-block">
                        <Col xs="12">
                            <div className="px-2" style={{ margin: "38px 0" }}>
                                <p className="big-title">Using the OHS Needs Assessment Tool</p>
                                <p className="body-text">Employers can use the Needs Assessment Tool to build a job profile for a qualified Occupational Health and Safety (OHS) professional. Generate a detailed
                                    list of OHS skills and competencies that meet the organization’s specific needs, using the consistent straightforward language of the Framework.
                                </p>
                            </div>
                        </Col>
                        <Col xs="12">
                            <p className="cards-title">get started</p>
                        </Col>
                        <Col xs="12" lg="4" className="mb-3 mb-lg-0 px-0">
                            <div className="step-block">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/globe.svg")
                                                .default
                                        }
                                        alt="explore"
                                    />
                                </div>

                                <p className="img-title mb-2">Explore</p>
                                <p className="img-content mb-0">Get to know the key competencies within the Framework</p>
                                {/* <div className="d-flex align-items-center gap-2 my-4">
                                    <div className="d-flex align-items-center" style={{ width: "42px", height: "42px" }}>
                                        <CircularProgressbarWithChildren
                                            value={80}
                                            text={`${80}%`}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        >
                                            <RadialSeparators
                                                count={12}
                                                style={{
                                                    background: "#fff",
                                                    width: "2px",
                                                    height: `${10}%`
                                                }}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <p className="progress-status-text mb-0">Complete</p>
                                </div> */}

                                <Button variant="primary" className="text-decoration-none" onClick={() => navigate('/dashboard')}>
                                    find out more
                                </Button>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className="mb-3 mb-lg-0 px-0">
                            <div className="step-block">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/assess.svg")
                                                .default
                                        }
                                        alt="assess"
                                    />
                                </div>

                                <p className="img-title mb-2">Assess</p>
                                <p className="img-content mb-4">Use the NAT to select job descriptors that best describe your organization’s OHS needs</p>
                                {/* <div className="d-flex align-items-center gap-2 my-4">
                                    <div className="d-flex align-items-center" style={{ width: "42px", height: "42px" }}>
                                        <CircularProgressbarWithChildren
                                            value={80}
                                            text={`${80}%`}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        >
                                            <RadialSeparators
                                                count={12}
                                                style={{
                                                    background: "#fff",
                                                    width: "2px",
                                                    height: `${10}%`
                                                }}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <p className="progress-status-text mb-0">Complete</p>
                                </div> */}
                                <Button variant="primary" className="text-decoration-none" onClick={() => navigate('/natsurvey?explore')}>
                                    find out more
                                </Button>
                            </div>
                        </Col>
                        <Col xs="12" lg="4" className="mb-3 mb-lg-0 px-0">
                            <div className="step-block">
                                <div className="img-circle-wrapper position-relative">
                                    <Image
                                        src={
                                            require("../../assets/images/report.svg")
                                                .default
                                        }
                                        alt="report"
                                    />
                                </div>

                                <p className="img-title mb-2">Saved Job Details</p>
                                <p className="img-content mb-4">Review your saved job details to use for job postings, performance reviews, and more.</p>
                                <div className="d-flex align-items-center gap-2 my-4">
                                    <div>
                                        <Image src={
                                            require("../../assets/images/availability.svg")
                                                .default
                                        }
                                            alt="availability"
                                        />
                                    </div>
                                    <p className="progress-status-text mb-0">{`${savedJobs} available`}</p>
                                </div>
                                <Button variant="primary" className="text-decoration-none" onClick={() => navigate('/savedjobs')}>
                                    find out more
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default OHSSectionNAT;