import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import './sidebar.css';
import lightCategoryIcon from '../../assets/images/category.svg';
import darkCategoryIcon from '../../assets/images/category-dark.svg';
import lightUserIcon from '../../assets/images/profile.svg';
import darkUserIcon from '../../assets/images/profile-dark.svg';
// import lightFeedbackIcon from '../../assets/images/feedback-icon.svg';
// import darkFeedbackIcon from '../../assets/images/feedback-icon-dark.svg';
// import lightSettingsIcon from '../../assets/images/setting.svg';
// import darkSettingsIcon from '../../assets/images/setting-dark.svg';
import logoutIcon from '../../assets/images/logout.svg';
import logo from '../../assets/images/msabc-logo.svg'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
const AdminSidebar = (props) => {
    const activemodel = {
        dashboard:["/admindashboard"],
        catlist:["/catlistuser"],
        natlist:["/natlistuser"],
        feedback:["/adminfeedback"],
        catform:["/catedit"],
        natform:["/natedit"],
        cateyeuser:['/viewcatuser'],
        nateyeuser:['/viewnatuser'],
        dashboardcatfrom:["/admindashboard/catedit"],
        dashboardnatform:["/admindashboard/natedit"]

    }
    const navigate = useNavigate();
    const handleSignout = event => {
        try {
            event.preventDefault();
            localStorage.clear();
            setTimeout(() => {
                navigate("/adminlogin");
                toast.success("Logout successfull.");
            }, 1000);
        } catch (err) {
            console.log("error at handleSignout in userProfile");
        }
    };

    return (
        <>
        <Navbar expand="lg" className="flex-column admin-sidebar mt-3">
            <Navbar.Brand href="/admindashboard">
                <img src={logo} alt="logo" className='img-fluid'></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="sidebar-nav" />
            <Navbar.Collapse id="sidebar-nav" className='w-100'>
                <Nav className="flex-column w-100">
                    <Nav.Link onClick={()=>{navigate("/admindashboard")}} className={activemodel.dashboard.includes(window.location.pathname) || activemodel.dashboardcatfrom.includes(window.location.pathname)|| activemodel.dashboardnatform.includes(window.location.pathname)  ? 'active' : ''}>
                        <img src={activemodel.dashboard.includes(window.location.pathname) || activemodel.dashboardcatfrom.includes(window.location.pathname)|| activemodel.dashboardnatform.includes(window.location.pathname) ? lightCategoryIcon : darkCategoryIcon} alt="Category Icon" />
                        Dashboard
                    </Nav.Link>
                    <Nav.Link onClick={()=>{navigate("/natlistuser")}} className={activemodel.natlist.includes(window.location.pathname) || activemodel.natform.includes(window.location.pathname) || activemodel.nateyeuser.includes(window.location.pathname)? 'active' : ''}>
                        <img src={activemodel.natlist.includes(window.location.pathname) || activemodel.natform.includes(window.location.pathname) || activemodel.nateyeuser.includes(window.location.pathname) ? lightUserIcon : darkUserIcon} alt="User Icon" />
                        NAT
                    </Nav.Link>
                    <Nav.Link onClick={()=>{navigate("/catlistuser");}}  className={activemodel.catlist.includes(window.location.pathname) || activemodel.catform.includes(window.location.pathname) || activemodel.cateyeuser.includes(window.location.pathname) ? 'active' : ''}>
                        <img src={activemodel.catlist.includes(window.location.pathname) || activemodel.catform.includes(window.location.pathname) || activemodel.cateyeuser.includes(window.location.pathname) ? lightUserIcon : darkUserIcon} alt="User Icon" />
                        CAT
                    </Nav.Link>
                    {/* <Nav.Link  onClick={()=>{navigate("/adminfeedback");}} className={activemodel.feedback.includes(window.location.pathname) ? 'active' : ''}>
                        <img src={activemodel.feedback.includes(window.location.pathname) ? lightFeedbackIcon : darkFeedbackIcon} alt="Feedback Icon" />
                        Feedbacks
                    </Nav.Link> */}
                    <div className='mt-50'>
                        {/* <Nav.Link className={`settings-menu ${!isLightMode ? 'active' : ''}`}>
                            <img src={isLightMode ? darkSettingsIcon : lightSettingsIcon} alt="Settings Icon" />
                            Settings
                        </Nav.Link> */}

                        <Nav.Link className='logout-menu' onClick={handleSignout}>
                            <img src={logoutIcon} alt="Settings Icon" />
                            Logout
                        </Nav.Link>
                    </div>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
        <ToastContainer
            position="top-center"
            hideProgressBar
        />
        </>
        
    );
};

export default AdminSidebar;
